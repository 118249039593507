<template>
    <teleport to="#modal-root">
        <div class="lit-popup" data-lit-popup-preset="slide" v-bind="$attrs" :data-lit-popup="name">
            <div class="lit-popup-overlay" :data-lit-popup-close="activeOverlay ? name : null"></div>
            <div class="lit-popup-container">
                <div v-if="title" class="popup-title">{{ title }}</div>
                <slot></slot>
            </div>
        </div>
    </teleport>
</template>

<script lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import LitPopup from 'lit-popup';

const NO_SCROLL_CLASS = 'no-scroll';

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        opened: {
            type: Boolean,
            default: false,
        },
        activeOverlay: {
            type: Boolean,
            default: true,
        },
    },

    setup(props) {
        const wasBodyLocked = ref(false);
        const leaveTimeout = ref();
        const litPopup = ref();

        function _lockBodyScroll() {
            wasBodyLocked.value = document.body.classList.contains(NO_SCROLL_CLASS);

            if (!wasBodyLocked.value) {
                document.body.classList.add(NO_SCROLL_CLASS);
            }
        }

        function _unlockBodyScroll() {
            // if (!wasBodyLocked.value) {
            document.body.classList.remove(NO_SCROLL_CLASS);
            // }
        }

        onMounted(() => {
            litPopup.value = new LitPopup(props.name, {
                onOpen: () => {
                    clearTimeout(leaveTimeout.value);
                    _lockBodyScroll();
                },
                // onOpenComplete: () => {
                //     const focusableOnOpenElement = this.renderRoot.querySelector<HTMLElement>('[data-focus-on-popup-open]');
                //     setTimeout(() => focusableOnOpenElement?.focus({ preventScroll: true }), 50);
                // },
                onClose: () => {
                    leaveTimeout.value = setTimeout(() => {
                        _unlockBodyScroll();
                    }, 300);
                },
            });
        });

        watch(
            () => props.opened,
            (value) => {
                if (value) {
                    litPopup?.value.open();
                } else {
                    litPopup?.value.close();
                }
            },
        );

        onBeforeUnmount(() => {
            clearTimeout(leaveTimeout.value);

            if (litPopup.value) {
                _unlockBodyScroll();
                litPopup.value.destroy();
            }
        });

        return {};
    },
};
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.lit-popup {
    --transition-duration: 0.3s;
    --transition-leave-duration: 0.25s;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    contain: strict;

    &:not(.lit-popup--opened) {
        --transition-duration: var(--transition-leave-duration);
        visibility: hidden;
    }

    &[data-lit-popup-preset='default'] {
        --transition-duration: 0.3s;
        transition: opacity var(--transition-duration) ease, visibility var(--transition-duration) ease;

        &:not(.lit-popup--opened) {
            --transition-leave-duration: 0.25s;
            opacity: 0;
        }
    }

    &[data-lit-popup-preset='slide'] {
        transition: opacity var(--transition-duration) ease, visibility var(--transition-duration) ease;

        .lit-popup-container {
            transition: transform var(--transition-duration) ease;
            width: calc(100% - 40px);
            max-width: 588px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 16px;
            padding: 40px 32px;
            height: auto;
            max-height: calc(100% - 60px);

            @media screen and (max-width: 1024px) {
                width: 75%;
            }

            @media screen and (max-width: 767px) {
                width: calc(100% - var(--grid-padding-x) * 2);
            }
        }

        &:not(.lit-popup--opened) {
            opacity: 0;

            .lit-popup-container {
                transform: translate(-50%, -50%) translate3d(0, 40px, 0);
            }
        }
    }
}

.lit-popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.2;
}

.lit-popup-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    // @include scrollbar();
    background-color: #fff;
}

.popup-close-btn {
    @include clickable();
    position: absolute;
    top: 17px;
    right: 16px;
    margin-left: 20px;
    border-radius: 10px;
    background-color: $input-bg;
    width: 32px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 12px;
    transition: background-color 0.2s ease;

    @mixin hover() {
        background-color: #fedacd;
    }

    &:focus-visible,
    &:active {
        @include hover();
    }

    @media (pointer: fine) {
        &:not(:disabled):hover {
            @include hover();
        }
    }

    svg {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
    }

    path {
        stroke: #2f2f33;
    }
}

.popup-title {
    margin-bottom: 24px;
    font-size: responsive 16px 28px;
    font-range: 1025px 1920px;
    font-weight: 600;
}
</style>
