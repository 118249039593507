<template>
    <div class="wrapper survey">
        <SurveyTop
            v-if="router.currentRoute.value.params.projectSlug && briefId && afterBriefStore.lastQuestionId"
            :back-url="`/${router.currentRoute.value.params.projectSlug}/briefs/${briefId}/${afterBriefStore.lastQuestionId}/`"
        />

        <h1 class="survey-title" :class="{ 'survey-title--hidden': briefQuery.isLoading.value }">
            {{ currentAction?.title || 'Ответы проанализированы системой' }}
        </h1>

        <BriefCompleteText>
            <SurveyCompleteBottom
                sticky
                class="brief-complete-bottom"
                :next-action-type="actionsStore.pendingActions[1]?.type"
                @action-complete="completeAction"
            />
        </BriefCompleteText>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import SurveyTop from '../components/survey/SurveyTop/SurveyTop.vue';
import SurveyCompleteBottom from '../components/survey/SurveyCompleteBottom/SurveyCompleteBottom.vue';
import BriefCompleteText from '../components/BriefCompleteText/BriefCompleteText.vue';
import { useAfterBriefStore } from '../stores/after-brief';
import { useAfterBriefActionsStore } from '../stores/after-brief-actions';
import { useRouter } from 'vue-router';
import { usePageClass } from '../hooks/usePageClass';
import { useQuery } from 'vue-query';
import { getProjectPropBy } from '../../../utils/projects';
import { useProjectsStore } from '../stores/projects';
import { axiosAPI } from '../../../axios';
import { useBriefComplete } from '../hooks/useBriefComplete';

export default defineComponent({
    components: { SurveyTop, SurveyCompleteBottom, BriefCompleteText },

    setup() {
        usePageClass({ 'hidden-header': true, 'brief-complete-page': true });
        const router = useRouter();
        const projectsStore = useProjectsStore();
        const afterBrief = useAfterBriefStore();
        const afterBriefActions = useAfterBriefActionsStore();
        const currentAction = afterBriefActions.pendingActions[0];
        const projectId = getProjectPropBy(
            'slug',
            projectsStore.projects,
            router.currentRoute.value.params.projectSlug,
            'id',
        ) as string;
        const briefId = (router.currentRoute.value.query.briefId || afterBrief.briefId || '') as number | string;

        useBriefComplete({ briefId, projectId });

        function completeAction() {
            if (currentAction) {
                currentAction.completed = true;
            }
        }

        const briefQuery = useQuery(
            ['brief', projectId, briefId],
            () => axiosAPI.get(`/projects/${projectId}/briefs/${briefId}`),
            {
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                retry: 0,
                enabled: !!briefId && !!projectId,
            },
        );

        return {
            briefId,
            afterBriefStore: afterBrief,
            actionsStore: afterBriefActions,
            completeAction,
            currentAction,
            router,
            briefQuery,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

html.brief-complete-page {
    .survey {
        padding-bottom: 60px;
    }

    .brief-complete-text {
        margin-bottom: 59px + 68px;

        @media screen and (min-width: 1025px) {
            margin-top: -68px;
        }
    }
}

.brief-complete-bottom {
    @media screen and (max-width: 1024px) {
        order: 1;
    }
}
</style>
