<template>
    <div>
        <div v-if="loading" class="brief-complete-loader-block">
            <v-loader class="brief-loader"></v-loader>
        </div>
        <div v-else class="brief-complete-container">
            <slot></slot>
            <div class="brief-complete-text">
                <div class="text-wrapper brief-complete-text__content wysiwyg" v-html="content"></div>
                <Button v-if="showDownloadButton" variant="primary" class="brief-complete-text__download-btn" disabled
                    >Скачать заявление</Button
                >
                <div v-if="description" class="brief-complete-text__description wysiwyg" v-html="description"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent, watch, computed, onMounted, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import Loader from '../../components/Loader/Loader.vue';
import SurveyTop from '../../components/survey/SurveyTop/SurveyTop.vue';
import SurveyCompleteBottom from '../../components/survey/SurveyCompleteBottom/SurveyCompleteBottom.vue';
import Button from '../../components/Button/Button.vue';
import ArrIcon from '../../svg/Arr.vue';
import { axiosAPI } from '../../../../axios';
import { useAfterBriefStore } from '../../stores/after-brief';
import { useAfterBriefActionsStore } from '../../stores/after-brief-actions';
import { getProjectPropBy } from '../../../../utils/projects';
import { useProjectsStore } from '../../stores/projects';

export default defineComponent({
    components: { ArrIcon, 'v-loader': Loader, Button, SurveyTop, SurveyCompleteBottom },

    setup() {
        const router = useRouter();
        const content = ref('');
        const loading = ref(false);
        const hasBeenFetched = ref(false);
        const afterBrief = useAfterBriefStore();
        const projectsStore = useProjectsStore();
        const afterBriefActions = useAfterBriefActionsStore();
        // afterBriefActions.resetActionsFromType('text');
        const currentAction = computed(() => afterBriefActions.pendingActions[0]);

        async function fetchText(id: number | string) {
            loading.value = true;
            hasBeenFetched.value = true;

            try {
                const { data } = await axiosAPI.get<any>(
                    `/projects/${getProjectPropBy(
                        'slug',
                        projectsStore.projects,
                        router.currentRoute.value.params.projectSlug,
                        'id',
                    )}/texts/${id}`,
                );
                content.value = data.data.text.body;
            } finally {
                loading.value = false;
            }
        }

        onBeforeMount(() => {
            console.log('1', afterBriefActions.actions);
            afterBriefActions.actions = [];
        });

        watch(
            afterBriefActions,
            (val) => {
                console.log('2', afterBriefActions);
                const pendingActions = val.actions.filter((action) => !action.completed);
                const pendingAction = pendingActions[0];

                if (!hasBeenFetched.value && pendingAction) {
                    if (pendingAction.data.id) {
                        fetchText(pendingAction.data.id);
                        // localStorage.setItem('CURRENT_ACTION_ID_2', pendingAction.data.id);
                    } /* else {
                        const currentActionId = localStorage.getItem('CURRENT_ACTION_ID_2');
                        console.log('localstorage', { currentActionId });

                        if (currentActionId) {
                            fetchText(currentActionId);
                        }
                    } */
                }
            },
            // { immediate: true },
        );

        return {
            content,
            description: currentAction?.description || '',
            loading,
            afterBriefStore: afterBrief,
            router,
            showDownloadButton: currentAction?.show_text_button || '',
        };
    },
});
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.brief-complete-loader-block {
    display: flex;
}

.brief-complete-container {
    display: flex;
    flex-direction: column;
}

.brief-complete-text__download-btn {
    width: 100%;
    margin-top: 30px;
}

.brief-complete-text__description {
    margin-top: 24px;
}
</style>
