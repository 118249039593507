<template>
    <div v-if="!subscriptionsQuery.isLoading.value" class="personal-form-fieldset">
        <template v-if="activeSubscription">
            <div class="personal-subscription-block__top">
                <div class="personal-subscription-block__title">Подписка активна</div>
                <div class="personal-subscription-block__check">
                    <CheckIcon />
                </div>
            </div>
            <template v-if="activeSubscription.renew">
                <p class="personal-subscription-block__text">
                    {{ toCurrency(parseFloat(activeSubscription.tariff.price)) }} спишутся
                    {{ dateToString(new Date(activeSubscription.active_until)) }}
                </p>
                <Button
                    class="personal-subscription-block__btn personal-subscription-block__cancel-btn"
                    data-lit-popup-open="cancel-subscription"
                    >Отменить подписку</Button
                >
            </template>
            <template v-else>
                <p class="personal-subscription-block__text">
                    До {{ dateToString(new Date(activeSubscription.active_until)) }}
                </p>
                <!-- TODO: здесь ссылку на выбор тарифа -->
                <Button v-if="false" class="personal-subscription-block__btn" data-lit-popup-open="cancel-subscription"
                    >Продлить подписку</Button
                >
            </template>

            <Modal
                v-if="activeSubscription.renew"
                name="cancel-subscription"
                title="Вы точно хотите отменить подписку?"
            >
                <div>Для отмены подписки нажмите нужную вам кнопку</div>
                <div class="incorrect-question-popup-bottom">
                    <LoadingButton
                        :disabled="cancelSubscriptionLoading || subscriptionsQuery.isFetching.value"
                        :is-loading="cancelSubscriptionLoading || subscriptionsQuery.isFetching.value"
                        variant="info"
                        class="incorrect-question-popup-bottom__item"
                        @click="() => cancelSubscription()"
                        >Отменить подписку</LoadingButton
                    >
                    <LoadingButton
                        :disabled="cancelSubscriptionLoading || subscriptionsQuery.isFetching.value"
                        :is-loading="cancelSubscriptionLoading || subscriptionsQuery.isFetching.value"
                        variant="primary"
                        class="incorrect-question-popup-bottom__item"
                        data-lit-popup-close="cancel-subscription"
                        >Оставить подписку</LoadingButton
                    >
                </div>
            </Modal>
        </template>
        <template v-else>
            <div class="personal-subscription-block__top">
                <div class="personal-subscription-block__title">Подписка неактивна</div>
            </div>
            <p class="personal-subscription-block__text">Оплатите подписку для использования сервиса</p>
            <router-link
                :to="`/${projectsStore.projects[0].slug}/choose-tariff`"
                class="btn btn-primary personal-subscription-block__btn"
                >{{
                    transactionsQuery?.data.value?.data.data.transactions.length > 0 ? 'Продлить подписку' : 'Оплатить'
                }}</router-link
            >
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { axiosAPI } from '../../../../axios';
import Button from '../../components/Button/Button.vue';
import LoadingButton from '../../components/LoadingButton/LoadingButton.vue';
import Modal from '../../components/Modal/Modal.vue';
import CheckIcon from '../../svg/Check.vue';
import { toCurrency } from '../../../../utils/to-currency';
import { dateToString } from '../../../../utils/dates';
import { useQuery } from 'vue-query';
import { useRouter } from 'vue-router';
import { useProjectsStore } from '../../stores/projects';

const props = defineProps({
    transactionsQuery: {
        type: Object,
    },
});

const router = useRouter();

const projectsStore = useProjectsStore();

const cancelSubscriptionError = ref('');
const cancelSubscriptionLoading = ref(false);

const subscriptionsQuery = useQuery(
    'subscriptions',
    () => axiosAPI.get(`/projects/${projectsStore.projects[0].id}/subscriptions`),
    {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    },
);

const activeSubscription = computed(() => {
    if (subscriptionsQuery.data.value?.data.data.subscriptions.length > 0) {
        return subscriptionsQuery.data.value.data.data.subscriptions.find((subscription) => subscription.active);
    }
});

async function cancelSubscription() {
    cancelSubscriptionError.value = '';

    if (activeSubscription.value) {
        cancelSubscriptionLoading.value = true;

        try {
            const { data } = axiosAPI.get(
                `/projects/${projectsStore.projects[0].id}/subscriptions/${activeSubscription.value.uuid}/cancel`,
            );
            // subscriptionsQuery.refetch.value();
            // todo: emit
        } catch (err: any) {
            cancelSubscriptionError.value = err.response?.message || err.message;
        } finally {
            cancelSubscriptionLoading.value = false;
        }
    }
}
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.personal-subscription-block__top {
    margin-bottom: 9px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
        margin-bottom: 6px;
    }
}

.personal-subscription-block__title {
    @include responsive-font-size(18px, 24px, 1280px, 1920px);
    font-weight: 600;
}

.personal-subscription-block__check {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $primary;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    @media screen and (max-width: 1024px) {
        margin-left: 8px;
        width: 20px;
        height: 20px;
    }

    svg {
        width: 10px;
        height: auto;

        @media screen and (max-width: 1024px) {
            width: 8px;
        }

        path {
            stroke: #fff;
        }
    }
}

.personal-subscription-block__btn {
    width: 100%;
    margin-top: 35px;
}

.personal-subscription-block__cancel-btn {
    background-color: #fff;

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: $primary;
            color: #fff;
        }
    }

    @media screen and (max-width: 1024px) {
        margin-top: 42px;
        background-color: #f6f7f9;
    }
}
</style>
