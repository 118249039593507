<template>
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.286 1H2.714C1.768 1 1 1.768 1 2.714v20.572C1 24.233 1.768 25 2.714 25h20.572c.947 0 1.714-.767 1.714-1.714V2.714C25 1.768 24.233 1 23.286 1z"
            stroke="#fff"
            stroke-width="1.5"
        />
        <path d="M5.242 8.135h-.999l3.798 10.379h1.02l3.797-10.379h-1l-3.262 9.147h-.091L5.242 8.135z" fill="#fff" />
        <path
            d="M14.36 18.514h.953v-3.37l1.392-1.551 3.869 4.92h1.162l-4.44-5.579 4.46-4.799h-1.253l-5.098 5.59h-.092v-5.59h-.953v10.379z"
            fill="#fff"
        />
    </svg>
</template>
