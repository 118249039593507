<template>
    <div class="task-container" :class="{ 'is-visible': active, 'is-entering': isEntering, 'is-leaving': isLeaving }">
        <div class="task-overlay" @click="hideTask"></div>
        <div ref="task" class="task">
            <div class="taskbar" @touchstart="onTouchstart" @touchmove="onTouchmove" @touchend="onTouchend"></div>
            <div class="task__top">
                <h2 class="task__title">{{ title }}</h2>
                <v-button
                    variant="info"
                    class="btn-with-icon task__help-btn"
                    :data-lit-popup-open="userSubscribed ? `task-help` : null"
                    :disabled="!userSubscribed"
                >
                    <span class="btn-text">Помощь</span>
                    <span class="btn-icon" aria-hidden="true">
                        <icon-question-circle></icon-question-circle>
                    </span>
                </v-button>
            </div>
            <div v-if="description" class="task__description">
                <div class="task-label">Описание</div>
                <div class="task__description-text wysiwyg" v-html="description"></div>
            </div>
            <form ref="form" novalidate class="task-form" @submit.prevent="onSubmit">
                <div class="task-form-inputs">
                    <Upload v-if="type === 'upload'" name="files[]" v-model="vv.files.$model" @upload="onInputChange" />
                    <v-datepicker
                        v-else-if="type === 'date-picker'"
                        name="result"
                        v-model="vv.result.$model"
                        @select="onInputChange"
                        :is-error="!!vv.result.$errors[0]"
                    ></v-datepicker>
                    <!-- <v-select v-else-if="type === 'select'" v-bind="part.attrs" placeholder="Выберите банк">
                        <template v-if="options">
                            <option v-for="option in options" :key="option.value" :value="option.value">
                                {{ option.text }}
                            </option>
                        </template>
                    </v-select> -->
                    <form-input
                        v-else-if="type === 'input'"
                        :show-helping-message="false"
                        :is-error="!!vv.result.$errors[0]"
                    >
                        <input class="form-control" name="result" v-model="vv.result.$model" @input="onInputChange" />
                    </form-input>
                </div>
                <!-- <div v-if="tip" class="tip task__tip">{{ tip }}</div> -->
                <div v-if="actions.length > 0" class="task-actions">
                    <TaskPaymentButton
                        v-if="payment && !payment.status"
                        class="task-action-btn"
                        :project-id="projectId"
                        :payment-type="payment.type"
                        :tariff-id="payment.tariff"
                    />
                    <template v-for="action in actions">
                        <a
                            v-if="action.code === 'link'"
                            :href="action.button_value"
                            :key="action.id"
                            class="btn btn-info task-action-btn"
                            :class="{
                                'btn-disabled': !userSubscribed || isFetchingTasks || (payment && !payment.status),
                            }"
                            target="_blank"
                            rel="noopener noreferrer"
                            @click="() => onFinishingLinkClick(action)"
                            >{{ action.button_title || action.name }}</a
                        >
                        <loading-button
                            v-else-if="action.code === 'text'"
                            :key="action.id"
                            type="button"
                            variant="primary"
                            class="task-action-btn task-submit-btn"
                            :disabled="
                                disabled ||
                                !userSubscribed ||
                                isSubmitting ||
                                textLoadingId === action.button_value ||
                                isFetchingTasks ||
                                (payment && !payment.status)
                            "
                            :is-loading="isSubmitting || textLoadingId === action.button_value || isFetchingTasks"
                            @click="() => downloadTextById(action)"
                            >{{ action.button_title || action.name }}</loading-button
                        >
                        <loading-button
                            v-else-if="action.code === 'get-service'"
                            :key="action.id"
                            type="button"
                            variant="primary"
                            class="task-action-btn task-submit-btn"
                            :disabled="
                                disabled ||
                                !userSubscribed ||
                                isSubmitting ||
                                paymentLinkLoadingId === action.button_value ||
                                isFetchingTasks ||
                                (payment && !payment.status)
                            "
                            :is-loading="
                                isSubmitting || paymentLinkLoadingId === action.button_value || isFetchingTasks
                            "
                            @click="() => getPaymentLink(action)"
                            >{{ action.button_title || action.name }}</loading-button
                        >
                        <loading-button
                            v-else
                            :key="action.id"
                            type="submit"
                            variant="primary"
                            class="task-action-btn task-submit-btn"
                            :disabled="
                                disabled ||
                                !userSubscribed ||
                                isSubmitting ||
                                isFetchingTasks ||
                                (payment && !payment.status)
                            "
                            :is-loading="isSubmitting || isFetchingTasks"
                            @click="() => onActionButtonClick(action.id)"
                            >{{ action.button_title || action.name }}</loading-button
                        >
                    </template>
                </div>
            </form>
            <div v-if="successMessage" class="task-response-message task-response-message--success">
                {{ successMessage }}
            </div>
            <div v-if="errorMessage" class="task-response-message task-response-message--error">{{ errorMessage }}</div>
            <div v-if="userSubscribed && changeable" class="task-bottom">
                <button class="link link-underline survey-incorrect-question-btn" data-lit-popup-open="incorrect-task">
                    Задача неактуальна (некорректна) в моей ситуации
                </button>

                <v-modal name="incorrect-task" title="Неактуальная задача" :active-overlay="false">
                    <div>
                        Данный вопрос отобразился на основании Ваших ответов на некоторые предыдущие вопросы. Если этот
                        вопрос не относится к Вашей ситуации, то необходимо нажать на кнопку ниже и исправить ответы на
                        один — два связанных вопроса.
                    </div>
                    <div class="incorrect-question-popup-bottom">
                        <a
                            v-if="supportEmail"
                            :href="`mailto:${supportEmail}`"
                            class="btn btn-info incorrect-question-popup-bottom__item"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Обратиться в техподдержку
                        </a>
                        <router-link
                            v-else-if="briefQuestionRedirectUrl"
                            :to="briefQuestionRedirectUrl"
                            class="btn btn-info incorrect-question-popup-bottom__item"
                            >Исправить предыдущие ответы</router-link
                        >
                        <v-button
                            variant="primary"
                            class="incorrect-question-popup-bottom__item"
                            data-lit-popup-close="incorrect-task"
                            >Данный вопрос корректен</v-button
                        >
                    </div>
                </v-modal>
            </div>
        </div>
        <v-modal class="inline-task-popup" title="Помощь" name="task-help">
            <button type="button" class="popup-close-btn" data-lit-popup-close="task-help" aria-label="Закрыть">
                <icon-cross></icon-cross>
            </button>
            <div>
                Данный вопрос отобразился на основании Ваших ответов на некоторые предыдущие вопросы. Если этот вопрос
                не относится к Вашей ситуации, то необходимо нажать на кнопку ниже и исправить ответы на один — два
                связанных вопроса.
            </div>
            <router-link to="/help" class="btn btn-info inline-task-popup__btn">Страница Помощь</router-link>
        </v-modal>
    </div>
</template>

<script lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, toRef, reactive, watch, defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { validationMessage } from '../../../utils/forms';
import Button from '../Button/Button.vue';
import LoadingButton from '../LoadingButton/LoadingButton.vue';
import Datepicker from '../Datepicker/Datepicker.vue';
import FormInput from '../FormInput/FormInput.vue';
import Select from '../Select/Select.vue';
import Upload from '../Upload/Upload.vue';
import TaskPaymentButton from './TaskPaymentButton.vue';
import Modal from '../Modal/Modal.vue';
import CrossIcon from '../../svg/Cross.vue';
import QuestionCircle from '../../svg/QuestionCircle.vue';
import { axiosAPI } from '../../../../axios';
import { useRouter } from 'vue-router';
import { getProjectPropBy } from '../../../../utils/projects';
import { useProjectsStore } from '../../stores/projects';

const rules = {
    result: { required },
    files: {},
};

const supportRequestPromise = axiosAPI.get('/support').then((response) => response.data.data.email);

export default defineComponent({
    components: {
        'v-button': Button,
        'loading-button': LoadingButton,
        'v-datepicker': Datepicker,
        'v-modal': Modal,
        FormInput,
        'v-select': Select,
        Upload,
        'icon-question-circle': QuestionCircle,
        'icon-cross': CrossIcon,
        TaskPaymentButton,
    },
    props: {
        actions: {
            type: Array,
            default: () => [],
        },
        taskId: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        isFetchingTasks: {
            type: Boolean,
            default: false,
        },
        lastEntity: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tip: {
            type: String,
        },
        type: {
            type: String,
        },
        userSubscribed: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        payment: {
            type: Object,
        },
    },

    setup(props, { emit }) {
        const router = useRouter();
        const task = ref<HTMLElement>();
        const form = ref<HTMLFormElement | null>(null);
        const formData = reactive<{ result: string; actionId: string | number; 'files[]': File[] }>({
            result: '',
            actionId: '',
            'files[]': [],
        });
        const isEntering = ref(false);
        const isLeaving = ref(false);
        const changeable = ref(false);
        const touchStartY = ref(0);
        const touchY = ref(0);
        const currentY = ref(0);
        const offsetTop = ref(0);
        const successMessage = ref('');
        const errorMessage = ref('');
        const supportEmail = ref('');
        const changeabilityChecked = ref(false);
        const briefQuestionRedirectUrl = ref('');
        const diff = computed(() => touchStartY.value - offsetTop.value);
        const isSubmitting = ref<boolean>(false);
        const hideTimeout = ref<NodeJS.Timeout>();
        const projectsStore = useProjectsStore();
        const textLoadingId = ref();
        const paymentLinkLoadingId = ref();

        const projectId = getProjectPropBy(
            'slug',
            projectsStore.projects,
            router.currentRoute.value.params.projectSlug,
            'id',
        );

        const vv = useVuelidate(rules, {
            result: toRef(formData, 'result'),
            files: toRef(formData, 'files[]'),
            actionId: toRef(formData, 'actionId'),
        });

        function hideTask() {
            emit('hide');
        }

        function onTouchstart(event: TouchEvent) {
            touchStartY.value = event.touches[0].clientY - currentY.value;
        }

        function onTouchmove(event: TouchEvent) {
            touchY.value = event.touches[0].clientY;
        }

        function onTouchend(event: TouchEvent) {
            if (touchY.value - offsetTop.value > 100) {
                hideTask();
            }
        }

        function onResize() {
            offsetTop.value = Math.max(0, window.innerHeight - (task.value?.getBoundingClientRect().height || 0));
        }

        function removeResponseMessage() {
            successMessage.value = '';
            errorMessage.value = '';
        }

        function onInputChange() {
            removeResponseMessage();
        }

        function onActionButtonClick(id: number | string) {
            formData.actionId = id;
        }

        async function onSubmit() {
            removeResponseMessage();
            vv.value.$touch();

            if (!props.userSubscribed || (props.type !== 'default' && props.type !== 'upload' && vv.value.$invalid))
                return;

            isSubmitting.value = true;

            try {
                const _formData = new FormData();
                Object.keys(formData).forEach((key) => {
                    if (Array.isArray(formData[key])) {
                        formData[key].forEach((item) => {
                            _formData.append(key, item);
                        });
                    } else {
                        _formData.append(key, formData[key]);
                    }
                });

                _formData.append('_method', 'put');

                const { data } = await axiosAPI.post(`/projects/${projectId}/tasks/${props.taskId}`, _formData);

                successMessage.value = data.message;
                emit('completed', { id: props.taskId });
                formData.result = '';
                formData.actionId = '';
                formData['files[]'] = [];
            } catch (err: any) {
                if (err.response) {
                    if (err.response.data.errors) {
                        errorMessage.value = Object.keys(err.response.data.errors)
                            .map((fieldName) => err.response.data.errors[fieldName].join('\n'))
                            .join('\n');
                        // errorMessage.value = err.response.data.errors.result.join('\n');
                    } else if (err.response.data.message) {
                        errorMessage.value = err.response.data.message;
                    }
                } else {
                    errorMessage.value = err.message;
                }
            } finally {
                isSubmitting.value = false;
            }
        }

        function onFinishingLinkClick(action: any) {
            if (action.finishing_task) {
                onActionButtonClick(action.id);
                onSubmit();
            }
        }

        async function downloadTextById(action: any) {
            const id = action.button_value;
            const name = action.text_name || 'text';

            try {
                textLoadingId.value = id;
                // const response = await fetch(`/api/documents/${id}/download`);
                const { data } = await axiosAPI.get(`/projects/${projectId}/texts/${id}/download`, {
                    responseType: 'blob',
                });
                // const file = new Blob([data], { type: 'application/pdf' });
                const a = document.createElement('a');
                const url = URL.createObjectURL(data);
                // a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = name;
                document.body.appendChild(a);
                a.click();
                a.remove();
                setTimeout(() => window.URL.revokeObjectURL(url), 5000);
                onFinishingLinkClick(action);
            } catch (err: any) {
                // documentsErrorMessage.value = err.response ? err.response.data.message : err.message;
                throw err;
            } finally {
                textLoadingId.value = null;
            }
        }

        async function getPaymentLink(action: any) {
            paymentLinkLoadingId.value = action.button_value;

            try {
                const { data } = await axiosAPI.get(
                    `/projects/${getProjectPropBy(
                        'slug',
                        projectsStore.projects,
                        router.currentRoute.value.params.projectSlug,
                        'id',
                    )}/payment`,
                    {
                        params: {
                            type: action.payment.type,
                            uuid: action.payment.tariff,
                        },
                    },
                );

                if (data.data.payment_link) {
                    window.open(data.data.payment_link, '_blank');
                }
            } finally {
                paymentLinkLoadingId.value = null;
            }
        }

        onMounted(() => {
            onResize();
            window.addEventListener('resize', onResize);
        });

        function checkChangeability() {
            if (!changeabilityChecked.value) {
                axiosAPI.post('/check-changeability', { id: props.taskId, type: 'task' }).then((response: any) => {
                    changeable.value = response.data.data.changeable;

                    if (response.data.data.changeable) {
                        if (props.lastEntity?.task) {
                            axiosAPI.delete(`/projects/${projectId}/tasks/${props.taskId}`).then((res: any) => {
                                if (props.lastEntity.type === 'task') {
                                    // redirect to the task
                                    // router.push(`/tasks/${props.lastEntity.id}`);
                                    emit('refresh');
                                } else if (props.lastEntity.type === 'question') {
                                    // redirect to the question
                                    briefQuestionRedirectUrl.value = `/${router.currentRoute.value.params.projectSlug}/briefs/1/${props.lastEntity.id}`;
                                }
                            });
                        } else {
                            supportRequestPromise.then((email: string) => {
                                supportEmail.value = `${email}?subject=Задача "${props.title}" неактуальна (некорректна) в моей ситуации"`;
                            });
                        }
                    }

                    changeabilityChecked.value = true;
                });
            }
        }

        watch(
            () => props.active,
            (val) => {
                if (val) {
                    checkChangeability();
                }
            },
        );

        onMounted(() => {
            if (props.active) {
                checkChangeability();
            }
        });

        onBeforeUnmount(() => {
            if (hideTimeout.value) {
                clearTimeout(hideTimeout.value);
            }
            window.removeEventListener('resize', onResize);
        });

        watch(touchY, (newVal) => {
            // Не скроллим выше высоты элемента
            if (offsetTop.value + diff.value < newVal) {
                const y = newVal - touchStartY.value;
                task.value?.style.setProperty('--y', `${y}px`);
                currentY.value = y;
            }
        });

        watch(
            () => props.active,
            (newVal) => {
                if (newVal) {
                    touchStartY.value = 0;
                    touchY.value = 0;
                    isEntering.value = true;
                    task.value?.addEventListener(
                        'transitionend',
                        () => {
                            isEntering.value = false;
                        },
                        { once: true },
                    );
                } else {
                    isLeaving.value = true;
                    task.value?.addEventListener(
                        'transitionend',
                        () => {
                            isLeaving.value = false;
                            task.value?.style.setProperty('--y', '0px');
                            touchStartY.value = 0;
                            touchY.value = 0;
                        },
                        { once: true },
                    );
                }
            },
        );

        return {
            task,
            form,
            hideTask,
            onTouchstart,
            onTouchmove,
            onTouchend,
            isEntering,
            isLeaving,
            isSubmitting,
            onSubmit,
            onActionButtonClick,
            onFinishingLinkClick,
            successMessage,
            errorMessage,
            onInputChange,
            vv,
            supportEmail,
            changeable,
            briefQuestionRedirectUrl,
            downloadTextById,
            textLoadingId,
            paymentLinkLoadingId,
            getPaymentLink,
            projectId,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.task-container {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transition: visibility 0.6s ease;

    @media screen and (min-width: 1025px) {
        transition: none;
        display: none;
        visibility: visible;
    }

    &.is-visible {
        visibility: visible;

        @media screen and (min-width: 1025px) {
            display: block;
        }

        .task-overlay {
            opacity: 0.4;
        }

        .task {
            @media screen and (max-width: 1024px) {
                transform: translate3d(0, var(--y), 0);
            }
        }

        &:not(.is-entering):not(.is-leaving) {
            .task {
                transition: none;
            }
        }
    }
}

.task-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.3s easeOutCubic;

    @media screen and (min-width: 1025px) {
        display: none;
    }
}

.task {
    --y: 0px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    padding: 16px;
    padding-bottom: 16px + 70px;
    padding-top: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.5s easeOutCubic;

    @media screen and (min-width: 1025px) {
        position: static;
        overflow-y: visible;
        max-height: none;
        padding: 0;
        transform: none;
        background-color: var(--elements-bg);
        padding: 40px 35px;
        border-radius: 16px;
    }

    .form-control {
        @media screen and (min-width: 1025px) {
            background-color: #fff;
        }

        @media screen and (max-width: 1024px) {
            background-color: #f6f7f9;
        }
    }

    // .btn-info {
    //     @media screen and (min-width: 1025px) {
    //         background-color: #fff;
    //     }

    //     @media screen and (max-width: 1024px) {
    //         background-color: #f6f7f9;
    //     }
    // }
}

.taskbar {
    width: 100%;
    height: 56px;
    position: relative;

    @media screen and (min-width: 1025px) {
        display: none;
    }

    &::before {
        content: '';
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 38px;
        height: 4px;
        border-radius: 10px;
        background-color: $color-gray;
    }
}

.task__top {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;

    @media screen and (min-width: 1025px) {
        margin-bottom: 40px;
    }

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
}

.task__title {
    font-size: responsive 20px 30px;
    font-range: 1025px 1920px;
    font-weight: 600;
    padding-right: 20px;
}

.task__help-btn {
    margin-left: auto;

    @media screen and (max-width: 480px) {
        margin-left: 0;
        margin-bottom: 30px;
        order: -1;
    }
}

.task__description {
    margin-bottom: 40px;

    @media screen and (min-width: 1025px) {
        margin-bottom: 4.64vw;
    }
}

.task__description-text {
    a {
        color: $primary;
        display: inline-block;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            transform-origin: 0% 50%;
        }
    }
}

.task-form-inputs {
    margin-bottom: 16px;

    + .task-submit-btn {
        margin-left: auto;

        @media screen and (max-width: 1024px) {
            margin-left: 0;
        }
    }

    .file-upload {
        @media screen and (min-width: 1025px) {
            background-color: #fff;
        }

        @media screen and (max-width: 1024px) {
            background-color: #f6f7f9;
        }
    }

    &:empty {
        display: none;
    }
}

.task-label {
    font-size: 10px;
    text-transform: uppercase;
    color: $color-gray;
    margin-bottom: 2px;

    @media screen and (min-width: 1025px) {
        font-size: 12px;
        margin-bottom: 4px;
    }
}

.tip {
    color: $color-gray;
    text-align: center;
    font-size: 10px;
}

.task__tip {
    margin-bottom: 8px;

    @media screen and (min-width: 1025px) {
        display: none;
    }
}

.task-submit-btn {
    @media screen and (max-width: 1024px) {
        width: calc(100% - 16px);
        margin-top: 15px;
    }
}

.task-actions {
    margin: -8px;
}

.task-action-btn {
    margin: 8px;
}

.task-response-message {
    margin-top: 30px;
}

.task-response-message--error {
    color: $danger;
}

.inline-task-popup__btn {
    width: 100%;
    margin-top: 48px;
}

.task-bottom {
    margin-top: 20px;
}
</style>
