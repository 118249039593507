<template>
    <div class="spinner" role="status">
        <span class="visually-hidden">Загрузка...</span>
    </div>
</template>

<style lang="scss">
@import '../../../../../css/base/variables';

.spinner {
    --size: 50px;
    border: calc(0.16 * var(--size)) solid rgba($primary, 0.1);
    border-left-color: $primary;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    animation: spin 1.2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
