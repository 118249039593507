<template>
    <div>
        <div class="consolidated-project-info__title">{{ projectName }}</div>

        <PersonalFormFieldset
            v-if="completedTasksQuery.data.value?.data.data.tasks.length > 0"
            collapsed
            title="Выполненные задачи"
        >
            <ul class="list-unstyled">
                <li
                    v-for="task in completedTasksQuery.data.value?.data.data.tasks"
                    :key="task.id"
                    class="personal-form__tasks-list__item"
                >
                    <TaskInline
                        class="task-container--desktop-modal"
                        :task-id="task.id"
                        :title="task.title"
                        :description="task.description"
                        :type="task.type"
                        :actions="task.actions"
                        :user-subscribed="isUserSubscribedToCurrentProject"
                        @show="() => showTask(completedTasks, task.id)"
                        @hide="() => hideTask(completedTasks, task.id)"
                    />
                </li>
            </ul>
        </PersonalFormFieldset>

        <!-- <PersonalFormFieldset collapsed title="Брифы">
            <x-collapse
                v-if="completedBriefsQuery.data.value?.data.data.briefs.length > 0"
                collapsed
                class="personal-form-brief-collapse"
            >
                <div class="personal-form-fieldset-top">
                    <button data-collapse-toggler class="task-inline-btn task-inline-btn--brief">
                        <span class="task-inline__title">Бриф №1</span>
                        <span class="task-inline__icon--brief">
                            <icon-arr></icon-arr>
                        </span>
                    </button>
                </div>
                <div class="collapse__content" data-collapse-content>
                    <div class="personal-form-fieldset__content--brief">
                        <ul class="list-unstyled">
                            <li class="personal-form__tasks-list__item">
                                <button class="task-inline-btn">
                                    <span class="task-inline__title">Перейти к выполнению брифа</span>
                                    <span class="task-inline__icon">
                                        <icon-arr></icon-arr>
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </x-collapse>
        </PersonalFormFieldset> -->

        <div class="personal-page-block">
            <div class="h2 personal-page-block__title">Документы по проекту «{{ projectName }}»</div>

            <template v-if="textsQuery.data.value?.data.data.texts.length > 0 || documents.length > 0">
                <PersonalFormFieldset v-if="textsQuery.data.value?.data.data.texts.length > 0" collapsed title="Тексты">
                    <ul class="list-unstyled">
                        <li
                            v-for="text in textsQuery.data.value.data.data.texts"
                            :key="text.id"
                            class="personal-form__tasks-list__item"
                        >
                            <div class="task-inline-btn">
                                <span class="task-inline__title">{{ text.name }}</span>
                                <div class="task-inline-right">
                                    <button
                                        class="square-btn square-btn-light task-inline-right__item"
                                        @click="() => downloadTextById(text.id, text.name)"
                                        :disabled="textLoadingId === text.id"
                                    >
                                        <Loader v-if="textLoadingId === text.id" />
                                        <DownloadIcon v-else />
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </PersonalFormFieldset>

                <PersonalFormFieldset v-if="documents.length > 0" collapsed title="Документы">
                    <ul class="list-unstyled">
                        <li v-for="doc in documents" :key="doc.uuid" class="personal-form__tasks-list__item">
                            <div class="task-inline-btn">
                                <span class="task-inline__title">{{ doc.name || '[no_name]' }}</span>
                                <div class="task-inline-right">
                                    <button
                                        class="square-btn square-btn-light task-inline-right__item"
                                        @click="() => downloadDocumentById(doc.uuid, doc.name || '[no_name]')"
                                        :disabled="documentLoadingId === doc.uuid"
                                    >
                                        <Loader v-if="documentLoadingId === doc.uuid" />
                                        <DownloadIcon v-else />
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </PersonalFormFieldset>
            </template>
            <div v-else>Документы отсутствуют</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useQuery } from 'vue-query';
import { axiosAPI } from '../../../../axios';
import { getProjectPropBy } from '../../../../utils/projects';
import { useUserStore } from '../../../../components/vue/stores/user';
import { useProjectsStore } from '../../../../components/vue/stores/projects';
import PersonalFormFieldset from '../../components/personal/PersonalFormFieldset.vue';
import TaskInline from '../../components/Task/TaskInline.vue';
import Loader from '../../components/Loader/Loader.vue';
import DownloadIcon from '../../svg/Download.vue';
import { Collapse } from '../../../../custom-elements/Collapse/Collapse';

const props = defineProps({
    projectId: {
        type: Number,
        required: true,
    },
});

function lockScroll() {
    document.body.classList.add('no-scroll');
}

function unlockScroll() {
    document.body.classList.remove('no-scroll');
}

function fixCollapses() {
    Array.from(document.querySelectorAll<Collapse>('x-collapse')).forEach((el) => {
        el.setUIState();
    });
}

function timeoutedFixCollapses() {
    setTimeout(fixCollapses, 50);
}

const userStore = useUserStore();
const projectsStore = useProjectsStore();

const projectName = computed(() => getProjectPropBy('id', projectsStore.projects, props.projectId, 'name'));
const isUserSubscribedToCurrentProject = computed(() =>
    userStore.user ? userStore.user.subscribed_projects.includes(props.projectId) : false,
);

const completedTasks = ref([]);

const documents = ref<any[]>([]);
const documentLoadingId = ref<number | null>(null);
const textLoadingId = ref<number | null>(null);

const completedTasksQuery = useQuery(
    ['completed-tasks', props.projectId],
    () =>
        axiosAPI.get(`/projects/${props.projectId}/tasks`, {
            params: { completed: true },
        }),
    {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            completedTasks.value = data.data.data.tasks.map((task) => ({
                ...JSON.parse(JSON.stringify(task)),
                active: false,
            }));
            timeoutedFixCollapses();
        },
    },
);

// const completedBriefsQuery = useQuery(
//     ['completed-briefs', props.projectId],
//     () => axiosAPI.get<any>(`/projects/${props.projectId}/briefs`, { params: { completed: true } }),
//     {
//         refetchOnMount: false,
//         refetchOnWindowFocus: false,
//         onSuccess: () => {
//             timeoutedFixCollapses();
//         },
//     },
// );

const textsQuery = useQuery(['texts', props.projectId], () => axiosAPI.get<any>(`/projects/${props.projectId}/texts`), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
        timeoutedFixCollapses();
    },
});

const documentsQuery = useQuery(
    ['documents', props.projectId],
    () => axiosAPI.get<any>(`/projects/${props.projectId}/documents`),
    {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            documents.value = data.data.tasks.reduce((docs, task) => {
                docs.push(...task.documents);
                return docs;
            }, []);
            timeoutedFixCollapses();
        },
    },
);

// async function fetchTexts() {
//     try {
//         isTextsLoading.value = true;
//         const { data } = await axiosAPI.get<any>(`/projects/${projectId}/texts`);
//         texts.value = data.data.texts;
//     } catch (err: any) {
//         textsErrorMessage.value = err.response ? err.response.data.message : err.message;
//         throw err;
//     } finally {
//         isTextsLoading.value = false;
//     }
// }

// async function fetchDocuments() {
//     try {
//         isDocumentsLoading.value = true;
//         const { data } = await axiosAPI.get<any>('/documents');
//         documents.value = data.data.tasks.reduce((docs, task) => {
//             docs.push(...task.documents);
//             return docs;
//         }, []);
//     } catch (err: any) {
//         documentsErrorMessage.value = err.response ? err.response.data.message : err.message;
//         throw err;
//     } finally {
//         isDocumentsLoading.value = false;
//     }
// }

async function downloadTextById(id: number, name: string) {
    try {
        textLoadingId.value = id;
        // const response = await fetch(`/api/documents/${id}/download`);
        const { data } = await axiosAPI.get(`/projects/${props.projectId}/texts/${id}/download`, {
            responseType: 'blob',
        });
        // const file = new Blob([data], { type: 'application/pdf' });
        const a = document.createElement('a');
        const url = URL.createObjectURL(data);
        // a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), 5000);
    } catch (err: any) {
        // documentsErrorMessage.value = err.response ? err.response.data.message : err.message;
        throw err;
    } finally {
        textLoadingId.value = null;
    }
}

async function downloadDocumentById(uuid: number, name: string) {
    try {
        documentLoadingId.value = uuid;
        const { data: blob } = await axiosAPI.get(`/documents/${uuid}/download`, { responseType: 'blob' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), 7000);
    } catch (err: any) {
        // documentsErrorMessage.value = err.response ? err.response.data.message : err.message;
        throw err;
    } finally {
        documentLoadingId.value = null;
    }
}

function hideTask(tasks: any[], id: number) {
    const task = tasks.find((obj) => obj.id === id);
    if (task) {
        task.active = false;
        if (window.matchMedia('(max-width: 1024px)').matches) {
            unlockScroll();
        }
    }
}

function showTask(tasks: any[], id: number) {
    const task = tasks.find((obj) => obj.id === id);
    tasks.forEach((task) => hideTask(tasks, task.id));
    if (task) {
        task.active = true;

        if (window.matchMedia('(max-width: 1024px)').matches) {
            lockScroll();
        }
    }
}
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.consolidated-project-info__title {
    @include responsive-font-size(24px, 32px, 1280px, 1920px);
    margin-bottom: 32px;
    line-height: 1.1;
}
</style>
