<template>
    <form class="survey-form" @submit.prevent="onSubmit">
        <div class="round-block survey-form-content">
            <div class="survey-question" v-html="questionData.text"></div>
            <div
                v-if="
                    (questionData.answer_type === 'radio' || questionData.answer_type === 'checkbox') &&
                    questionData.answers
                "
            >
                <div v-for="(answer, i) in questionData.answers" :key="answer.id" class="survey-option-item">
                    <input
                        :id="`${questionData.id}-${i}`"
                        :type="questionData.answer_type"
                        class="visually-hidden survey-option__input"
                        name="answer"
                        :value="answer.text"
                        :disabled="formDisabled"
                        @change="
                            (event) =>
                                event.target.checked
                                    ? questionData.answer_type === 'checkbox'
                                        ? addCheckboxAnswer(event.target.value)
                                        : setAnswer(event.target.value)
                                    : questionData.answer_type === 'checkbox'
                                    ? removeCheckboxAnswer(event.target.value)
                                    : () => {}
                        "
                    />
                    <label :for="`${questionData.id}-${i}`" class="survey-option">
                        <span class="survey-option__text">{{ answer.text }}</span>
                        <span class="survey-option__element">
                            <icon-check></icon-check>
                        </span>
                    </label>
                </div>
            </div>
            <div v-else-if="questionData.answer_type === 'text'">
                <FormInput id="input" class="survey-option-item">
                    <input
                        type="text"
                        id="input"
                        class="form-control"
                        :disabled="formDisabled"
                        @input="(event) => setAnswer(event.target.value)"
                    />
                </FormInput>
            </div>
            <div v-else-if="questionData.answer_type === 'number'">
                <form-input id="input" class="survey-option-item">
                    <input
                        type="text"
                        id="input"
                        class="form-control"
                        inputmode="numeric"
                        autocomplete="off"
                        :disabled="formDisabled"
                        @input="(event) => setAnswer(event.target.value)"
                        v-maska="{ mask: 'Z*', tokens: { Z: { pattern: /[0-9]/ } } }"
                    />
                </form-input>
            </div>
            <div v-else-if="questionData.answer_type === 'autocomplete'">
                <form-input id="input" class="survey-option-item">
                    <x-autocomplete-input
                        :endpoint="`/projects/${projectId}${isAuthorized ? '' : '/first'}/briefs/${briefId}/questions/${
                            questionData.id
                        }/list`"
                        method="post"
                        @choice="(event) => setAnswer(event.detail.value)"
                        :data="JSON.stringify(userObj)"
                        class="brief-autocomplete-input"
                    >
                        <input
                            id="input"
                            type="text"
                            class="form-control"
                            placeholder="Введите запрос"
                            :disabled="formDisabled"
                            autocomplete="off"
                        />
                    </x-autocomplete-input>
                </form-input>
            </div>
            <div v-else-if="questionData.answer_type === 'date'">
                <v-datepicker
                    id="input"
                    name="date"
                    :modelValue="answer"
                    @select="([date]) => setAnswer(date.dateInstance)"
                    placeholder="Выберите дату"
                ></v-datepicker>
            </div>
            <div v-else-if="questionData.answer_type === 'date-interval'">
                <v-datepicker
                    :modelValue="answer"
                    :options="{ singleMode: false }"
                    @select="(dates) => setAnswer([dates[0].dateInstance, dates[1].dateInstance])"
                ></v-datepicker>
            </div>
        </div>
        <div v-if="errorMessage" class="form-error-message">{{ errorMessage }}</div>
        <div class="survey-bottom">
            <div class="survey-bottom-left">
                <div v-if="prevQuestionId" class="survey-bottom-el">
                    <button class="survey-incorrect-question-btn" data-lit-popup-open="incorrect-question">
                        Вопрос не актуален (не корректен) в моей ситуации
                    </button>

                    <v-modal name="incorrect-question" title="Не актуальный вопрос" :active-overlay="false">
                        <div>
                            Данный вопрос отобразился на основании Ваших ответов на некоторые предыдущие вопросы. Если
                            этот вопрос не относится к Вашей ситуации, то необходимо нажать на кнопку ниже и исправить
                            ответы на один — два связанных вопроса.
                        </div>
                        <div class="incorrect-question-popup-bottom">
                            <!-- <v-button variant="info" class="incorrect-question-popup-bottom__item"
                                    >Исправить предыдущие ответы</v-button
                                > -->
                            <router-link
                                :to="`/${router.currentRoute.value.params.projectSlug}/briefs/${briefId}/${prevQuestionId}/`"
                                class="btn btn-info incorrect-question-popup-bottom__item"
                                >Исправить предыдущие ответы</router-link
                            >
                            <v-button
                                variant="primary"
                                class="incorrect-question-popup-bottom__item"
                                data-lit-popup-close="incorrect-question"
                                >Данный вопрос корректен</v-button
                            >
                        </div>
                    </v-modal>
                </div>

                <div v-if="questionData.show_privacy_postscript" class="survey-bottom-el">
                    <SensitiveMessage />
                </div>

                <div class="survey-bottom-el">
                    <Hint v-if="questionData.hint" :title="questionData.hint.title" class="brief-form__hint">
                        <div
                            v-if="questionData.hint.type === 'video' && questionData.hint.video_link"
                            class="responsive"
                        >
                            <iframe
                                :src="questionData.hint.video_link"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                class="responsive__item"
                            ></iframe>
                        </div>
                        <div v-else-if="questionData.hint.type === 'text' && questionData.hint.description">
                            <div class="brief-form__hint-description" v-html="questionData.hint.description"></div>
                        </div>
                        <div
                            v-else-if="questionData.hint.type === 'image_quote' && questionData.hint.description"
                            class="brief-form__hint-quote"
                        >
                            <div class="brief-form__hint-quote__left">
                                <div v-if="questionData.hint.image?.src" class="responsive brief-form__hint-quote__img">
                                    <img
                                        :src="questionData.hint.image.src"
                                        :width="questionData.hint.image.width"
                                        :height="questionData.hint.image.height"
                                        alt=""
                                        class="responsive__item"
                                    />
                                </div>
                                <div v-if="questionData.hint.image?.title" class="brief-form__hint-quote__img-name">
                                    {{ questionData.hint.image.title }}
                                </div>
                                <div
                                    v-if="questionData.hint.image?.description"
                                    class="brief-form__hint-quote__img-secondary"
                                >
                                    {{ questionData.hint.image.description }}
                                </div>
                            </div>
                            <div
                                class="brief-form__hint-quote__right brief-form__hint-description"
                                v-html="questionData.hint.description"
                            ></div>
                        </div>
                    </Hint>
                </div>
            </div>

            <v-button
                v-if="!formDisabled"
                ref="submitBtn"
                type="submit"
                variant="primary"
                class="survey-submit-btn"
                :disabled="formDisabled || isSubmitting || (answer instanceof Set ? answer.size === 0 : !answer)"
                data-testid="submit-brief"
            >
                <Loader v-if="isSubmitting" />
                <span v-else class="survey-submit-btn-text">Далее</span>
            </v-button>
        </div>
    </form>
</template>

<script lang="ts">
/* global PUBLIC_PATH */
import { defineComponent, ref, watch, watchEffect } from 'vue';
import { router } from '../../router';
import IconCheck from '../../svg/Check.vue';
import Button from '../../components/Button/Button.vue';
import FormInput from '../../components/FormInput/FormInput.vue';
import Datepicker from '../../components/Datepicker/Datepicker.vue';
import Modal from '../../components/Modal/Modal.vue';
import SensitiveMessage from '../../components/SensitiveMessage/SensitiveMessage.vue';
import Hint from '../Hint/Hint.vue';
import Loader from '../../components/Loader/Loader.vue';
import { dateToString } from '../../../../utils/dates';
import { useRouter } from 'vue-router';
import { store } from '../../store';

export default defineComponent({
    components: {
        IconCheck,
        'v-button': Button,
        FormInput,
        'v-datepicker': Datepicker,
        'v-modal': Modal,
        Loader,
        SensitiveMessage,
        Hint,
    },

    props: {
        formDisabled: {
            type: Boolean,
            default: false,
        },
        isSubmitting: {
            type: Boolean,
            default: false,
        },
        questionData: {
            type: Object,
            required: true,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        projectId: {
            type: Number,
            required: true,
        },
        userQaChain: {
            type: Object,
            default: () => ({}),
        },
    },

    setup(props, { emit }) {
        const router = useRouter();
        const submitBtn = ref<{ el: HTMLButtonElement }>();
        const answer = ref<string | Set<string> | Date | [Date, Date]>(
            props.questionData.answer_type === 'checkbox' ? new Set() : '',
        );
        const prevQuestionId = ref('');
        const errorMessage = ref(props.errorMessage);
        const datepicker = ref('');
        const isAuthorized = ref(!!store.state.token);

        const userObj = ref({
            user: {
                brief: {
                    id: router.currentRoute.value.params.briefId,
                    questions: [],
                },
            },
        });

        watch(
            () => props.userQaChain,
            (val) => {
                if (val) {
                    userObj.value.user.brief.questions = val;
                }
            },
            { immediate: true },
        );

        function setAnswer(value: string | Date | [Date, Date]) {
            if (value instanceof Date) {
                answer.value = dateToString(value);
            } else if (Array.isArray(value) && value[0] instanceof Date && value[1] instanceof Date) {
                answer.value = `${dateToString(value[0])}-${dateToString(value[1])}`;
            } else {
                answer.value = value;
            }
        }

        function addCheckboxAnswer(value: string) {
            (answer.value as Set<string>).add(value);
        }

        function removeCheckboxAnswer(value: string) {
            (answer.value as Set<string>).delete(value);
        }

        function onSubmit() {
            if (answer.value instanceof Set ? answer.value.size === 0 : !answer.value) {
                errorMessage.value = 'Выберите один из вариантов';
                return;
            }

            emit('form-submit', { question: props.questionData, answer: answer.value });
        }

        /**
         * Убираем сообщение об ошибке, если выбран какой-то ответ
         */
        watch(answer, (newVal) => {
            if (newVal) {
                errorMessage.value = '';
            }
        });

        watch(
            () => props.errorMessage,
            (newVal) => {
                errorMessage.value = newVal;
            },
        );

        watch(datepicker, (newVal) => {
            setAnswer(newVal);
        });

        watchEffect(() => {
            if (submitBtn.value) {
                if (props.isSubmitting) {
                    const rect = submitBtn.value.el.getBoundingClientRect();
                    submitBtn.value.el.style.width = `${rect.width}px`;
                    submitBtn.value.el.style.height = `${rect.height}px`;
                } else {
                    submitBtn.value.el.style.width = '';
                    submitBtn.value.el.style.height = '';
                }
            }
        });

        return {
            PUBLIC_PATH: PUBLIC_PATH,
            answer,
            setAnswer,
            onSubmit,
            briefId: router.currentRoute.value.params.briefId,
            prevQuestionId,
            datepicker,
            addCheckboxAnswer,
            removeCheckboxAnswer,
            submitBtn,
            router,
            isAuthorized,
            userObj,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.survey-form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.round-block {
    @media screen and (max-width: 1024px) {
        background-color: #fff;
        padding: 16px;
        border-radius: 16px;
    }
}

.survey-form-content {
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 50px;
    }
}

.survey-question {
    font-weight: 600;
    margin-bottom: 16px;

    > p {
        font-size: responsive 16px 28px;
        font-range: 1025px 1920px;

        &:not(:last-child) {
            margin-bottom: 0.5em;
        }
    }
}

.survey-option-item {
    &:not(:last-child) {
        margin-bottom: 8px;

        @media screen and (min-width: 768px) {
            margin-bottom: 12px;
        }

        @media screen and (min-width: 1441px) {
            margin-bottom: 16px;
        }
    }
}

.survey-option {
    background-color: $info;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    padding: 10px 16px;

    @mixin hover() {
        color: $primary;
    }

    .survey-option__input:not(:disabled) ~ & {
        cursor: pointer;

        &:focus-visible,
        &:active {
            @include hover();
        }

        @media (pointer: fine) {
            &:not(:disabled):hover {
                @include hover();
            }
        }
    }
}

.survey-option__input {
    &:checked {
        ~ .survey-option__text,
        ~ .survey-option__element {
            color: $primary;
        }

        ~ .survey-option__element {
            background-color: $primary;

            svg {
                display: block;
            }
        }

        ~ .survey-option {
            .survey-option__text,
            .survey-option__element {
                color: $primary;
            }

            .survey-option__element {
                background-color: $primary;

                svg {
                    display: block;
                }
            }
        }
    }

    &[type='radio'] {
        ~ .survey-option__element {
            svg {
                display: none;
            }
        }

        ~ .survey-option {
            .survey-option__element {
                svg {
                    display: none;
                }
            }
        }

        &:checked {
            ~ .survey-option__element {
                border-width: 6px;
                border-color: $primary;
                background-color: transparent;
            }

            ~ .survey-option {
                .survey-option__element {
                    border-width: 6px;
                    border-color: $primary;
                    background-color: transparent;
                }
            }
        }
    }

    &:disabled {
        ~ .survey-option {
            opacity: $btn-disabled-opacity;
        }
    }
}

.survey-option__text {
    margin-right: 30px;
}

.survey-option__element {
    flex-shrink: 0;
    margin-left: auto;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid currentColor;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-self: start;
    padding: 4px;

    svg {
        width: 100%;
        height: auto;
        max-height: 100%;
        display: none;

        path {
            stroke: #fff;
        }
    }

    &::before {
        content: '';
        border-radius: inherit;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-color: $primary;
        transform: scale(0);
    }
}

.survey-bottom {
    display: flex;
    align-items: flex-start;
}

.survey-bottom-left {
    flex: 1 1 auto;
}

.survey-bottom-el {
    padding-right: 20px;

    @media screen and (max-width: 1024px) {
        padding-right: 0;
    }

    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.survey-submit-btn {
    position: fixed;
    z-index: 3;
    bottom: 16px;
    left: 16px;
    width: calc(100% - 32px);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1025px) {
        position: relative;
        z-index: auto;
        left: auto;
        bottom: auto;
        margin-left: auto;
        width: auto;
        z-index: auto;
        justify-content: flex-start;
    }

    .loader {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: calc(var(--size) / -2);
        margin-left: calc(var(--size) / -2);
        --size: 20px;
        --color: #fff;
    }
}

.survey-submit-btn-text {
    &::after {
        @media screen and (min-width: 1025px) {
            content: '';
            display: inline-block;
            vertical-align: bottom;
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.999999L8 8L1 15' stroke='white' stroke-linecap='square'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            flex-shrink: 0;
            margin-left: 18px;
            width: 7px;
            height: 14px;
        }
    }
}

.survey-incorrect-question-btn {
    opacity: 0.5;
    font-size: 12px;
    text-transform: uppercase;
}

.incorrect-question-popup-bottom {
    margin-top: 48px;
}

.incorrect-question-popup-bottom__item {
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.brief-autocomplete-input {
    --max-height: 30vh;
}

.brief-form__hint {
    max-width: 470px;
}

.brief-form__hint-description {
    color: $body-color;
    line-height: 1.42;
}

.brief-form__hint-quote {
    display: flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
}

.brief-form__hint-quote__left {
    width: max-content;
    min-width: 100px;
    max-width: 100%;

    @media screen and (max-width: 767px) {
        order: 1;
        margin-top: 20px;
    }
}

.brief-form__hint-quote__right {
    flex: 2 1 auto;
    padding-left: 25px;

    @media screen and (max-width: 767px) {
        padding-left: 0;
    }
}

.brief-form__hint-quote__img {
    --aspect-ratio: 1 / 1;
    border-radius: 50%;

    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.brief-form__hint-quote__img-name {
    font-size: 14px;
    line-height: 20px;
    color: $body-color;
}

.brief-form__hint-quote__img-secondary {
    font-size: 12px;
    color: $body-color;
}
</style>
