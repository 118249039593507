<template>
    <div class="brief-complete-bottom" :class="{ 'is-sticky': sticky }">
        <router-link
            v-if="nextActionType && map[nextActionType]"
            :to="`/${router.currentRoute.value.params.projectSlug + map[nextActionType].url}`"
            class="btn btn-primary btn-with-icon brief-complete-next-link js-brief-complete-next-link"
            @click="completeAction"
        >
            <span class="btn-text">{{ map[nextActionType].text }}</span>
            <span class="btn-icon" aria-hidden="true">
                <ArrIcon />
            </span>
        </router-link>
    </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import ArrIcon from '../../../svg/Arr.vue';

const props = defineProps({
    backUrl: String,
    nextActionType: String,
    sticky: {
        type: Boolean,
        default: false,
    },
});

const router = useRouter();

const emit = defineEmits(['action-complete']);

const map: Record<string, Record<'text' | 'url', string>> = {
    tariff: {
        text: 'Перейти к тарифам',
        url: '/choose-tariff',
    },
    payment: {
        text: 'Перейти к оплате',
        url: '/payment',
    },
    text: {
        text: 'Перейти к тексту',
        url: '/brief-complete',
    },
    text_tariff_faq: {
        text: 'Перейти к тексту',
        url: '/brief-complete-combined',
    },
    tasks: {
        text: 'Перейти к задачам',
        url: '/tasks',
    },
};

function completeAction() {
    emit('action-complete');
}
</script>

<style lang="scss">
@import '../../../../../../css/base/variables';
@import '../../../../../../css/utils/mixins';

.brief-complete-bottom {
    margin-top: 24px;
    display: flex;

    &.is-sticky {
        @media screen and (min-width: 1025px) {
            padding: 10px;
            position: sticky;
            z-index: 1;
            top: calc(100vh - 68px);
            background-color: #fff;
        }
    }
}

.brief-complete-next-link {
    margin-left: auto;
}
</style>
