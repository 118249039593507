import { Project } from '../types';

// type ValueOf<T> = T[keyof T];

export const getProjectPropBy = <T = any>(
    baseProp: keyof Project,
    projects: Project[],
    value: unknown,
    prop: keyof Project,
) => projects.find((project) => project[baseProp] === value)?.[prop];
