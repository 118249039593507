<template>
    <button ref="button" :type="type" class="btn" :class="{ [`btn-${variant}`]: !!variant, [`btn-${size}`]: !!size }">
        <slot></slot>
    </button>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
    props: {
        type: {
            type: String,
            validator(value: string) {
                return ['button', 'reset', 'submit'].includes(value);
            },
            default: 'button',
        },
        variant: {
            type: String,
            validator(value: string) {
                return ['default', 'primary', 'light', 'info', 'danger'].includes(value);
            },
            default: 'default',
        },
        size: {
            type: String,
            validator(value: string) {
                return ['lg', 'sm'].includes(value);
            },
        },
        icon: {
            type: String,
        },
    },
});
</script>

<script setup lang="ts">
const emit = defineEmits(['mounted']);

const button = ref<HTMLElement>();

defineExpose({ el: button });

onMounted(() => {
    emit('mounted', { ref: button });
});
</script>

<style lang="scss">
@import '../../../../../css/base/variables';

.btn {
    display: inline-flex;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 18px 12px;
    line-height: 1.2;

    &:focus:not(:focus-visible) {
        box-shadow: none;
    }

    @media screen and (min-width: 1025px) {
        font-size: 12px;
        padding: 12px 16px;
    }

    @media screen and (min-width: 1441px) {
        font-size: 14px;
        padding: 15px 25px;
        border-radius: 12px;
    }

    &:disabled {
        cursor: not-allowed;
        background-color: $color-gray;
        border-color: $color-gray;
        color: #fff;

        &,
        &:hover {
            background-color: $color-gray;
            border-color: $color-gray;
            color: #fff;
        }
    }

    &.btn-disabled {
        pointer-events: none;
    }
}

.btn-with-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.btn-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.2em;
    transform: rotate(180deg);
    flex-shrink: 0;

    svg {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;

        [stroke] {
            stroke: currentColor;
        }
    }

    + .btn-text {
        margin-left: 1em;
    }
}

.btn-text {
    + .btn-icon {
        margin-left: 1em;
        transform: none;
    }
}

.btn-primary {
    &:hover {
        background-color: lighten($primary, 8%);
        border-color: lighten($primary, 8%);
    }
}

.btn-light {
    color: $primary;

    &:hover {
        color: $primary;
        background-color: #fedacd;
        border-color: #fedacd;
    }

    &:active {
        color: $primary;
        background-color: darken(#fedacd, 8%);
        border-color: darken(#fedacd, 8%);
    }
}

.btn-info {
    background-color: var(--elements-bg);

    &:hover {
        background-color: #fedacd;
        border-color: #fedacd;
    }

    &:active {
        color: $primary;
        background-color: darken(#fedacd, 8%);
        border-color: darken(#fedacd, 8%);
    }
}
</style>
