<template>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18M9.5 5.5V9.5H7.02083H4.5"
            stroke="#2F2F33"
            stroke-width="2"
        />
        <path
            d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z"
            stroke="#2F2F33"
            stroke-width="2"
            stroke-dasharray="2 2"
        />
    </svg>
</template>
