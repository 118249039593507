<template>
    <NotFoundPage v-if="notFound" />
    <div v-else class="wrapper survey">
        <div class="survey-top">
            <Button v-if="prevQuestionId" variant="light" @click="goBack">Назад</Button>
        </div>
        <h1 class="survey-title" data-testid="brief-title">{{ questions?.[0]?.section_title || 'Внесите данные' }}</h1>
        <v-loader v-if="isFetchingQuestion || isRegistering" class="brief-loader"></v-loader>
        <div v-else-if="questions.length > 0">
            <BriefForm
                v-for="(question, i) in questions"
                class="brief-form js-brief-form"
                :key="question.id"
                :form-disabled="i < questions.length - 1"
                :is-submitting="loading"
                :questionData="question"
                :project-id="projectId"
                :error-message="errorMessage"
                :user-qa-chain="qaChain"
                @form-submit="onSubmit"
            />
        </div>
        <div v-if="fetchingQuestionErrorMessage" class="form-error-message">{{ fetchingQuestionErrorMessage }}</div>
    </div>
</template>

<script lang="ts">
/* global PUBLIC_PATH */
import { onBeforeMount, ref, watch, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { axiosAPI } from '../../../axios';
import type { Question, QuestionResponse } from '../../../types';
import { useAfterBriefStore } from '../stores/after-brief';
import { useAfterBriefActionsStore } from '../stores/after-brief-actions';
import NotFoundPage from '../pages/NotFoundPage.vue';
import Loader from '../components/Loader/Loader.vue';
import Button from '../components/Button/Button.vue';
import BriefForm from '../components/BriefForm/BriefForm.vue';
import { store } from '../store';
import { goBack } from '../../../utils/go-back';
import { useProjectsStore } from '../stores/projects';
import { getProjectPropBy } from '../../../utils/projects';
import { usePageClass } from '../hooks/usePageClass';
import { getUrlByActionType } from '../../../utils/get-url-by-action-type';

type Answer = string | string[] | Date | [Date, Date];

export const QA_CHAIN_LS_NAME = 'QA_CHAIN_2';

export default defineComponent({
    components: {
        NotFoundPage,
        BriefForm,
        'v-loader': Loader,
        Button,
    },

    setup() {
        usePageClass({ 'hidden-header': true, 'brief-page': true });
        const router = useRouter();
        const notFound = ref(false);
        const prevQuestionId = ref<number>();
        const questions = ref<Question[]>([]);
        const loading = ref<boolean>(false);
        const isFetchingQuestion = ref<boolean>(false);
        const errorMessage = ref<string>('');
        const fetchingQuestionErrorMessage = ref<string>('');
        const afterBrief = useAfterBriefStore();
        const afterBriefActions = useAfterBriefActionsStore();
        const projectsStore = useProjectsStore();
        const isRegistering = ref(false);
        const firstQuestionId = ref<number | null>(null);
        const projectId = getProjectPropBy(
            'slug',
            projectsStore.projects,
            router.currentRoute.value.params.projectSlug,
            'id',
        );

        const qaChain = ref<
            {
                id: number;
                answer: Answer;
            }[]
        >([]);

        const savedQaChain = sessionStorage.getItem(QA_CHAIN_LS_NAME);

        if (typeof savedQaChain === 'string') {
            qaChain.value = JSON.parse(savedQaChain);
        }

        if (!projectId) {
            notFound.value = true;
        }

        /**
         * Запрос вопроса
         */
        async function fetchQuestionData(briefId: number, questionId: number, isRegistered = false) {
            fetchingQuestionErrorMessage.value = '';
            isFetchingQuestion.value = true;

            if (!projectId) {
                return;
            }

            try {
                const { data } = await axiosAPI.get<QuestionResponse>(
                    `/projects/${projectId}${isRegistered ? '' : '/first'}/briefs/${briefId}/questions/${questionId}`,
                );
                questions.value = [];
                questions.value.push(data.data.question);
                prevQuestionId.value = data.data.question.prev_question_id;
            } catch (err) {
                if (err.response) {
                    if (err.response.status === 404) {
                        notFound.value = true;
                    } else if (err.response.status === 403) {
                        router.push('/403');
                    } else {
                        fetchingQuestionErrorMessage.value = err.response?.data.message || err.message;
                    }
                } else {
                    fetchingQuestionErrorMessage.value = err.message;
                }
                throw err;
            } finally {
                isFetchingQuestion.value = false;
            }
        }

        async function onSubmit({
            question,
            answer,
        }: {
            question: Question;
            answer: string | Set<string> | Date | [Date, Date];
        }) {
            if (loading.value) return;

            const isAuthorized = !!store.state.token;

            if (question) {
                errorMessage.value = '';
                loading.value = true;

                const currentAnswer = {
                    id: question.id,
                    answer: answer instanceof Set ? Array.from(answer) : answer,
                };

                try {
                    const qa = qaChain.value.find((obj) => obj.id === currentAnswer.id);

                    if (qa) {
                        qa.answer = currentAnswer.answer;
                    } else {
                        qaChain.value.push(currentAnswer);
                    }

                    const { data } = await axiosAPI.put<QuestionResponse>(
                        `/projects/${getProjectPropBy(
                            'slug',
                            projectsStore.projects,
                            router.currentRoute.value.params.projectSlug,
                            'id',
                        )}${isAuthorized ? '' : '/first'}/briefs/${
                            router.currentRoute.value.params.briefId
                        }/questions/${question.id}`,
                        {
                            user: {
                                brief: {
                                    id: router.currentRoute.value.params.briefId,
                                    questions: qaChain.value,
                                },
                            },
                            answer: currentAnswer.answer,
                        },
                    );

                    const { actions, next_question } = data.data;

                    if (Array.isArray(actions)) {
                        actions.forEach((action) => {
                            (action as any).completed = false;
                        });

                        afterBrief.briefId = question.brief_id;
                        afterBrief.lastQuestionId = question.id;
                        afterBriefActions.actions = actions;

                        // Переводим пользователя с брифа на другую страницу
                        if (actions[0]) {
                            const url = getUrlByActionType(actions[0].type);

                            if (url) {
                                router.push(url);
                            }
                        }
                    } else if (next_question.brief_id && next_question.id) {
                        // Переходим на следующий вопрос

                        if (next_question.chain) {
                            const response = await axiosAPI.get<QuestionResponse>(
                                `/projects/${getProjectPropBy(
                                    'slug',
                                    projectsStore.projects,
                                    router.currentRoute.value.params.projectSlug,
                                    'id',
                                )}${isAuthorized ? '' : '/first'}/briefs/${
                                    router.currentRoute.value.params.briefId
                                }/questions/${next_question.id}`,
                            );
                            questions.value.push(response.data.data.question);
                            setTimeout(() => {
                                const forms = Array.from(document.querySelectorAll('.js-brief-form'));

                                forms[forms.length - 1].scrollIntoView({
                                    behavior: 'smooth',
                                    inline: 'center',
                                    block: 'center',
                                });
                            }, 50);
                        } else {
                            router.push(
                                `/${router.currentRoute.value.params.projectSlug}/briefs/${next_question.brief_id}/${next_question.id}`,
                            );
                        }
                    }
                } catch (err) {
                    errorMessage.value = err.response?.data?.message || err.message;
                    throw err;
                } finally {
                    loading.value = false;
                }
            }
        }

        /**
         * Запрашиваем корректные данные вопроса при смене url
         */
        watch(
            () => router.currentRoute.value.params,
            (newVal) => {
                if (typeof newVal.briefId === 'string' && typeof newVal.questionId === 'string') {
                    fetchQuestionData(parseInt(newVal.briefId, 10), parseInt(newVal.questionId, 10));
                }
            },
        );

        /**
         * Записываем цепочку "вопрос-ответ" в sessionStorage
         */
        watch(
            qaChain,
            (val) => {
                sessionStorage.setItem(QA_CHAIN_LS_NAME, JSON.stringify(val));
            },
            { deep: true },
        );

        onBeforeMount(() => {
            if (
                typeof router.currentRoute.value.params.briefId === 'string' &&
                typeof router.currentRoute.value.params.questionId === 'string'
            ) {
                fetchQuestionData(
                    parseInt(router.currentRoute.value.params.briefId, 10),
                    parseInt(router.currentRoute.value.params.questionId, 10),
                    !!store.state.token,
                );
            }
        });

        watch(
            () => router.currentRoute.value.params.questionId,
            (val) => {
                const questionId = typeof val === 'string' ? parseInt(val, 10) : null;

                const isFirstQuestion = typeof questionId === 'number' ? questionId === firstQuestionId.value : false;

                if (isFirstQuestion) {
                    sessionStorage.removeItem(QA_CHAIN_LS_NAME);
                }

                /**
                 * Если вернулись назад, удаляем ответы на "будущие" вопросы
                 */
                const currentQuestionIndex = qaChain.value.findIndex((question) => question.id === questionId);

                if (currentQuestionIndex > -1) {
                    qaChain.value = qaChain.value.slice(0, currentQuestionIndex);
                }
            },
            { immediate: true },
        );

        onBeforeMount(() => {
            axiosAPI.get(`/projects/${projectId}/start`).then(({ data }) => {
                firstQuestionId.value = data.data.question_id;
            });
        });

        return {
            notFound,
            PUBLIC_PATH: PUBLIC_PATH,
            loading,
            isFetchingQuestion,
            onSubmit,
            errorMessage,
            briefId: router.currentRoute.value.params.briefId,
            prevQuestionId,
            fetchingQuestionErrorMessage,
            questions,
            goBack,
            isRegistering,
            projectId,
            qaChain,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

html.brief-page {
    jdiv[class^='button_'] {
        @media screen and (max-width: 767px) {
            bottom: 65px;
        }

        @media screen and (max-width: 900px) and (orientation: landscape) {
            bottom: 30px;
        }
    }
}

.survey {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    @media screen and (min-width: 1025px) {
        max-width: 880px;
    }

    @media screen and (min-width: 2100px) {
        max-width: 1000px;
    }
}

.survey-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.survey-title {
    margin-bottom: 16px;

    @media screen and (min-width: 1025px) {
        padding-bottom: 20px;
        border-bottom: 1px solid rgba($body-color, 0.2);
        margin-bottom: 55px;
    }

    &.survey-title--hidden {
        visibility: hidden;
    }
}

.brief-loader {
    --size: 100px;
    margin: auto;
}

.brief-form {
    &:not(:last-child) {
        margin-bottom: 60px;

        @media screen and (max-width: 1440px) {
            margin-bottom: 50px;
        }

        @media screen and (max-width: 1024px) {
            margin-bottom: 40px;
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 30px;
        }

        @media screen and (max-width: 576px) {
            margin-bottom: 20px;
        }
    }
}
</style>
