<template>
    <div class="input-group" :class="{ 'input-group--error': isError }">
        <label v-if="label" :for="id" class="form-label">{{ label }}</label>
        <slot></slot>
        <div v-if="showHelpingMessage" class="app-message" aria-live="polite" :aria-describedby="id">
            {{ helpingMessage }}
        </div>
        <div class="input-group-icon">
            <slot name="icon"></slot>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    inheritAttrs: false,

    props: {
        id: {
            type: String,
        },
        label: {
            type: String,
        },
        helpingMessage: {
            type: String,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        showHelpingMessage: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss">
@import '../../../../../css/base/variables';

.input-group {
    position: relative;

    &.input-group--error {
        .form-control {
            color: $danger;
            border-color: currentColor;
            background-color: rgba($danger, 0.2);
        }
    }
}

.input-group-icon {
    position: absolute;
    right: $input-padding-x;
    top: 50%;
    transform: translate(0, -50%);

    &:empty {
        display: none;
    }
}

.form-label {
    font-size: 10px;
    margin-bottom: 4px;
    font-weight: 600;
    color: $color-gray;
    text-transform: uppercase;

    @media screen and (min-width: 1025px) {
        font-size: 11px;
    }
}

.app-message {
    margin-top: 0.3em;
    font-size: 10px;
    display: block;
    min-height: $line-height-base * 1em;

    @media screen and (min-width: 1025px) {
        font-size: 12px;
    }

    .input-group--error & {
        color: $danger;
    }
}
</style>
