import { createStore } from 'vuex';

export const store = createStore({
    state: {
        token: '',
    },
    // mutations: {
    //     setToken(state) {
    //         state.count++;
    //     },
    // },
});
