<template>
    <form ref="formRef" novalidate class="phone-sms-validation-form" @submit.prevent="onSubmit">
        <div class="h2 phone-sms-validation-form__title">{{ title }}</div>
        <div v-if="message" class="phone-sms-validation-form__message">{{ message }}</div>
        <div class="input-code">
            <input
                v-for="(digit, i) in digits"
                :key="i"
                :ref="
                    (el) => {
                        inputs[i] = el;
                    }
                "
                type="tel"
                inputmode="numeric"
                class="form-control input-code__input"
                autocomplete="one-time-code"
                :value="digits[i]"
                @input="onChange"
                @focus="onCodeInputFocus"
                v-maska="{ mask: 'Z', tokens: { Z: { pattern: /[0-9]/ } } }"
            />
        </div>

        <div class="phone-sms-validation-tip-container">
            <div v-if="timeoutLeftSec > 0" class="phone-sms-validation-tip">
                Получить новый код можно через {{ timeoutLeftSecStringified }}
            </div>
            <span v-else class="phone-sms-validation-tip">
                <button
                    type="button"
                    class="phone-sms-validation-tip-btn"
                    @click="getCode"
                    :disabled="sendCodeData.loading"
                >
                    Получить код повторно
                </button>
                <v-loader v-if="sendCodeData.loading" class="phone-sms-validation-tip__loader"></v-loader>
            </span>
        </div>

        <div v-if="validateCodeData.errorMessage" class="phone-sms-validation-form__error">
            {{ validateCodeData.errorMessage }}
        </div>

        <div class="phone-sms-validation__actions">
            <loading-button
                type="submit"
                variant="primary"
                :disabled="code.length !== digitsProp || validateCodeData.loading"
                :is-loading="validateCodeData.loading"
                class="btn-with-icon phone-sms-validation__action"
            >
                <span class="btn-text">Далее</span>
                <span class="btn-icon" aria-hidden="true">
                    <icon-arr></icon-arr>
                </span>
            </loading-button>
        </div>
    </form>
</template>

<script lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { withLeadingZero } from '../../../../utils/string';
import Loader from '../Loader/Loader.vue';
import LoadingButton from '../LoadingButton/LoadingButton.vue';
import ArrIcon from '../../svg/Arr.vue';
import { axiosAPI } from '../../../../axios';
import { YM_ID } from '../../router';

export default {
    components: { LoadingButton, 'v-loader': Loader, 'icon-arr': ArrIcon },
    props: {
        title: {
            type: String,
            default: '',
        },
        phone: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        digits: {
            type: Number,
            default: 4,
        },
        endpoint: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        sendCodeEndpoint: {
            type: String,
            required: true,
        },
        sendCodeData: {
            type: Object,
            default: () => ({}),
        },
        smsTimeoutSec: {
            type: Number,
            default: 0,
        },
        sendSms: {
            type: Function,
            required: true,
        },
    },

    setup(props, { emit }) {
        const formRef = ref<HTMLFormElement>();
        const inputs = ref<HTMLInputElement[]>([]);
        const digits = ref<string[]>(Array(props.digits).fill(''));
        const timeoutLeftSec = ref<number>(props.smsTimeoutSec);
        const sendCodeData = reactive({
            loading: false,
            errorMessage: '',
        });
        const validateCodeData = reactive({
            loading: false,
            errorMessage: '',
        });

        watch(
            () => props.smsTimeoutSec,
            (newVal) => {
                timeoutLeftSec.value = newVal;
            },
        );

        const timeoutLeftSecStringified = computed(() => `00:${withLeadingZero(timeoutLeftSec.value)}`);
        const code = computed(() => digits.value.reduce((result, digit) => result + digit, ''));

        function onChange(event) {
            const index = inputs.value.findIndex((input) => input === event.target);
            const value = event.target.value.trim();

            if (index > -1) {
                digits.value[index] = value;

                for (let i = 0; i < value.length; i++) {
                    if (typeof digits.value[index + i] === 'undefined') break;
                    if (/^[0-9]$/.test(value[i])) {
                        digits.value[index + i] = value[i];
                        inputs.value[index + i + 1]?.focus();
                    }
                }
            }
        }

        async function onSubmit() {
            if (code.value.length !== props.digits) {
                return;
            }

            validateCodeData.errorMessage = '';
            validateCodeData.loading = true;

            (window as any).ym?.(YM_ID, 'reachGoal', 'verificate');

            try {
                const response = await axiosAPI.post(props.endpoint, { ...props.data, code: code.value });

                if (response.data.success) {
                    emit('code-validation-form-success');
                } else {
                    throw new Error(response.data.message);
                }
            } catch (err) {
                if (err.response) {
                    validateCodeData.errorMessage = err.response.data.message;
                }
                emit('code-validation-form-error', err);
                throw err;
            } finally {
                validateCodeData.loading = false;
            }
        }

        async function getCode() {
            sendCodeData.errorMessage = '';
            sendCodeData.loading = true;
            try {
                await props.sendSms(props.sendCodeEndpoint, { ...props.sendCodeData, phone: props.phone });
            } catch (err) {
                if (err.response) {
                    sendCodeData.errorMessage = err.response.data.message;
                }
                throw err;
            } finally {
                sendCodeData.loading = false;
            }
        }

        function onCodeInputFocus() {
            validateCodeData.errorMessage = '';
        }

        // onMounted(() => {
        //     setTimeout(() => {
        //         formRef.value.scrollIntoView({ behavior: 'smooth' });
        //     }, 1);
        // });

        return {
            onSubmit,
            onChange,
            code,
            getCode,
            timeoutLeftSec,
            timeoutLeftSecStringified,
            inputs,
            formRef,
            digits,
            sendCodeData,
            validateCodeData,
            onCodeInputFocus,
            digitsProp: props.digits,
        };
    },
};
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.phone-sms-validation-form {
    padding-top: 48px;
    border-top: 1px solid rgba($body-color, 0.1);
}

.input-code {
    display: flex;
    justify-content: space-between;
    margin: -11px;
}

.input-code__input {
    margin: 11px;
    flex: 1 1 auto;
    text-align: center;
}

.phone-sms-validation-tip {
    position: relative;
}

.phone-sms-validation-tip-btn {
    &:disabled {
        opacity: 0.2;
        cursor: auto;
    }
}

.phone-sms-validation-tip__loader {
    --size: 20px;
    position: absolute;
    left: calc(100% + 30px);
    top: 50%;
    transform: translate(-50%, -50%);
}

.phone-sms-validation-form__message {
    margin-bottom: 40px;
}

.phone-sms-validation__action {
    @media screen and (max-width: 420px) {
        width: 100%;
        margin-top: 10px;

        .btn-icon {
            display: none;
        }
    }
}
</style>
