<template>
    <div ref="containerRef" class="wrapper personal-page-wrapper">
        <div class="personal-page-block">
            <h1 class="h1 personal-page-title">Ваши данные</h1>

            <!-- <SubscriptionBlock :transactions-query="transactionsQuery" /> -->

            <TransactionsBlock />

            <form novalidate class="personal-page-form" @submit.prevent="onSubmit">
                <!-- <PersonalFormFieldset title="Информация о долгах">
                    <form-input
                        id="personal-form-debt"
                        label="Ваши долги (в руб.)"
                        :is-error="!!vv.debt.$errors[0]"
                        :helping-message="validationMessage(vv.debt)"
                    >
                        <input
                            type="tel"
                            id="personal-form-debt"
                            name="debt"
                            class="form-control personal-form-field"
                            v-model="vv.debt.$model"
                            placeholder="Введите ваши долги в рублях"
                            v-maska="{ mask: 'Z*', tokens: { Z: { pattern: /[0-9]/ } } }"
                        />
                    </form-input>
                </PersonalFormFieldset> -->

                <PersonalFormFieldset title="Личная информация">
                    <form-input
                        id="personal-form-name"
                        label="Имя"
                        :is-error="!!vv.name.$errors[0]"
                        :helping-message="validationMessage(vv.name)"
                    >
                        <input
                            id="personal-form-name"
                            name="name"
                            class="form-control personal-form-field"
                            v-model="vv.name.$model"
                            placeholder="Введите имя"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-last-name"
                        label="Фамилия"
                        :is-error="!!vv.lastName.$errors[0]"
                        :helping-message="validationMessage(vv.lastName)"
                    >
                        <input
                            id="personal-form-last-name"
                            name="lastName"
                            class="form-control personal-form-field"
                            v-model="vv.lastName.$model"
                            placeholder="Введите фамилию"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-patronymic"
                        label="Отчество"
                        :is-error="!!vv.patronymic.$errors[0]"
                        :helping-message="validationMessage(vv.patronymic)"
                    >
                        <input
                            id="personal-form-patronymic"
                            name="patronymic"
                            class="form-control personal-form-field"
                            v-model="vv.patronymic.$model"
                            placeholder="Введите отчество"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-email"
                        label="Адрес электронной почты"
                        :is-error="!!vv.email.$errors[0]"
                        :helping-message="validationMessage(vv.email)"
                    >
                        <input
                            id="personal-form-email"
                            type="email"
                            name="email"
                            class="form-control personal-form-field"
                            v-model="vv.email.$model"
                            placeholder="Введите адрес электронной почты"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-phone"
                        label="Телефон"
                        :is-error="!!vv.phone.$errors[0]"
                        :helping-message="validationMessage(vv.phone)"
                    >
                        <input
                            id="personal-form-phone"
                            type="tel"
                            name="phone"
                            class="form-control personal-form-field"
                            v-model="vv.phone.$model"
                            placeholder="Введите телефон"
                        />
                    </form-input>
                </PersonalFormFieldset>

                <PersonalFormFieldset title="Основная информация">
                    <form-input
                        id="personal-form-dateOfBirth"
                        label="Дата рождения"
                        :is-error="!!vv.dateOfBirth.$errors[0]"
                        :helping-message="validationMessage(vv.dateOfBirth)"
                    >
                        <input
                            id="personal-form-dateOfBirth"
                            name="dateOfBirth"
                            class="form-control personal-form-field"
                            v-model="vv.dateOfBirth.$model"
                            placeholder="Введите дату рождения"
                            v-maska="{ mask: 'ZZ.ZZ.ZZZZ', tokens: { Z: { pattern: /[0-9]/ } } }"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-passportNo"
                        label="Серия и номер паспорта"
                        :is-error="!!vv.passportNo.$errors[0]"
                        :helping-message="validationMessage(vv.passportNo)"
                    >
                        <input
                            id="personal-form-passportNo"
                            name="passportNo"
                            class="form-control personal-form-field"
                            v-model="vv.passportNo.$model"
                            placeholder="Введите серию и номер паспорта"
                            v-maska="{ mask: 'ZZ ZZ ZZZZZZ', tokens: { Z: { pattern: /[0-9]/ } } }"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-issuedBy"
                        label="Кем и когда выдан"
                        :is-error="!!vv.issuedBy.$errors[0]"
                        :helping-message="validationMessage(vv.issuedBy)"
                    >
                        <input
                            id="personal-form-issuedBy"
                            name="issuedBy"
                            class="form-control personal-form-field"
                            v-model="vv.issuedBy.$model"
                            placeholder="Кем и когда выдан"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-passportIssuerCode"
                        label="Код подразделения"
                        :is-error="!!vv.passportIssuerCode.$errors[0]"
                        :helping-message="validationMessage(vv.passportIssuerCode)"
                    >
                        <input
                            id="personal-form-passportIssuerCode"
                            name="passportIssuerCode"
                            class="form-control personal-form-field"
                            v-model="vv.passportIssuerCode.$model"
                            placeholder="Введите код подразделения"
                            v-maska="{ mask: 'ZZZ-ZZZ', tokens: { Z: { pattern: /[0-9]/ } } }"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-placeOfBirth"
                        label="Место рождения"
                        :is-error="!!vv.placeOfBirth.$errors[0]"
                        :helping-message="validationMessage(vv.placeOfBirth)"
                    >
                        <input
                            id="personal-form-placeOfBirth"
                            name="placeOfBirth"
                            class="form-control personal-form-field"
                            v-model="vv.placeOfBirth.$model"
                            placeholder="Введите место рождения"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-address"
                        label="Место жительства (Адрес регистрации)"
                        :is-error="!!vv.address.$errors[0]"
                        :helping-message="validationMessage(vv.address)"
                    >
                        <input
                            id="personal-form-address"
                            name="address"
                            class="form-control personal-form-field"
                            v-model="vv.address.$model"
                            placeholder="Введите место жительства"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-inn"
                        label="ИНН"
                        :is-error="!!vv.inn.$errors[0]"
                        :helping-message="validationMessage(vv.inn)"
                    >
                        <input
                            type="tel"
                            id="personal-form-inn"
                            name="inn"
                            class="form-control personal-form-field"
                            v-model="vv.inn.$model"
                            placeholder="Введите ваш ИНН"
                            v-maska="{ mask: 'Z'.repeat(12), tokens: { Z: { pattern: /[0-9]/ } } }"
                        />
                    </form-input>
                    <form-input
                        id="personal-form-snils"
                        label="СНИЛС"
                        :is-error="!!vv.snils.$errors[0]"
                        :helping-message="validationMessage(vv.snils)"
                    >
                        <input
                            id="personal-form-snils"
                            name="snils"
                            class="form-control personal-form-field"
                            v-model="vv.snils.$model"
                            placeholder="Введите ваш СНИЛС"
                            v-maska="{ mask: 'ZZZ-ZZZ-ZZZ ZZ', tokens: { Z: { pattern: /[0-9]/ } } }"
                        />
                    </form-input>
                </PersonalFormFieldset>

                <div
                    v-if="personalDataMutationMessage"
                    class="personal-form-response"
                    :class="{ 'is-error': personalDataMutation.isError }"
                >
                    {{ personalDataMutationMessage }}
                </div>

                <Button
                    type="submit"
                    variant="primary"
                    class="personal-form-save-btn"
                    :disabled="personalDataMutation.isLoading.value"
                >
                    <Loader v-if="personalDataMutation.isLoading.value" />
                    <span v-else>Сохранить</span>
                </Button>
            </form>

            <div class="personal-projects">
                <ConsolidatedProjectInfo
                    v-for="project in paidProjects"
                    :key="project.id"
                    :project-id="project.id"
                    class="personal-projects-item"
                />
            </div>

            <!-- <PersonalFormFieldset
                v-if="completedTasksQuery.data.value?.data.data.tasks.length > 0"
                collapsed
                title="Выполненные задачи"
            >
                <ul class="list-unstyled">
                    <li
                        v-for="task in completedTasksQuery.data.value?.data.data.tasks"
                        :key="task.id"
                        class="personal-form__tasks-list__item"
                    >
                        <task-inline
                            class="task-container--desktop-modal"
                            :task-id="task.id"
                            :title="task.title"
                            :description="task.description"
                            :type="task.type"
                            :actions="task.actions"
                            :user-subscribed="userStore.user ? !!userStore.user.subscribed : false"
                            @show="() => showTask(completedTasks, task.id)"
                            @hide="() => hideTask(completedTasks, task.id)"
                        ></task-inline>
                    </li>
                </ul>
            </PersonalFormFieldset> -->

            <!-- <PersonalFormFieldset collapsed title="Брифы">
                <x-collapse
                    v-if="completedBriefsQuery.data.value?.data.data.briefs.length > 0"
                    collapsed
                    class="personal-form-brief-collapse"
                >
                    <div class="personal-form-fieldset-top">
                        <button data-collapse-toggler class="task-inline-btn task-inline-btn--brief">
                            <span class="task-inline__title">Бриф №1</span>
                            <span class="task-inline__icon--brief">
                                <icon-arr></icon-arr>
                            </span>
                        </button>
                    </div>
                    <div class="collapse__content" data-collapse-content>
                        <div class="personal-form-fieldset__content--brief">
                            <ul class="list-unstyled">
                                <li class="personal-form__tasks-list__item">
                                    <button class="task-inline-btn">
                                        <span class="task-inline__title">Перейти к выполнению брифа</span>
                                        <span class="task-inline__icon">
                                            <icon-arr></icon-arr>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </x-collapse>
            </PersonalFormFieldset> -->
        </div>

        <!-- <div class="personal-page-block">
            <h2 class="personal-page-block__title">Документы по проекту «Банкротство»</h2>

            <template v-if="texts.length > 0 || documents.length > 0">
                <PersonalFormFieldset v-if="texts.length > 0" collapsed title="Тексты">
                    <ul class="list-unstyled">
                        <li v-for="text in texts" :key="text.id" class="personal-form__tasks-list__item">
                            <div class="task-inline-btn">
                                <span class="task-inline__title">{{ text.name }}</span>
                                <div class="task-inline-right">
                                    <button
                                        class="square-btn square-btn-light task-inline-right__item"
                                        @click="() => downloadTextById(text.id, text.name)"
                                        :disabled="textLoadingId === text.id"
                                    >
                                        <Loader v-if="textLoadingId === text.id" />
                                        <icon-download v-else></icon-download>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </PersonalFormFieldset>

                <PersonalFormFieldset v-if="documents.length > 0" collapsed title="Документы">
                    <ul class="list-unstyled">
                        <li v-for="doc in documents" :key="doc.uuid" class="personal-form__tasks-list__item">
                            <div class="task-inline-btn">
                                <span class="task-inline__title">{{ doc.name || '[no_name]' }}</span>
                                <div class="task-inline-right">
                                    <button
                                        class="square-btn square-btn-light task-inline-right__item"
                                        @click="() => downloadDocumentById(doc.uuid, doc.name || '[no_name]')"
                                        :disabled="documentLoadingId === doc.uuid"
                                    >
                                        <Loader v-if="documentLoadingId === doc.uuid" />
                                        <icon-download v-else></icon-download>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </PersonalFormFieldset>
            </template>
            <div v-else>Документы отсутствуют</div>
        </div> -->

        <div class="personal-page-block">
            <h2 class="personal-page-block__title">Проекты</h2>

            <PersonalFormFieldset title="Оплаченные проекты">
                <x-collapse v-for="project in paidProjects" collapsed class="personal-form-brief-collapse">
                    <div class="personal-form-fieldset-top">
                        <button data-collapse-toggler class="task-inline-btn task-inline-btn--brief">
                            <span class="task-inline__title">{{ project.name }}</span>
                            <span class="task-inline__icon--brief">
                                <icon-arr></icon-arr>
                            </span>
                        </button>
                    </div>
                    <div class="collapse__content" data-collapse-content>
                        <div class="personal-form-fieldset__content--brief">
                            <ul class="list-unstyled">
                                <li class="personal-form__tasks-list__item">
                                    <router-link :to="`/${project.slug}/tasks`" class="task-inline-btn">
                                        <span class="task-inline__title">Перейти к выполнению задач</span>
                                        <span class="task-inline__icon">
                                            <icon-arr></icon-arr>
                                        </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </x-collapse>
            </PersonalFormFieldset>

            <PersonalFormFieldset title="Выбрать новый проект">
                <x-collapse collapsed class="personal-form-brief-collapse">
                    <div class="personal-form-fieldset-top">
                        <button data-collapse-toggler class="task-inline-btn task-inline-btn--brief">
                            <span class="task-inline__title">Товары</span>
                            <span class="task-inline__icon--brief">
                                <icon-arr></icon-arr>
                            </span>
                        </button>
                    </div>
                    <div class="collapse__content" data-collapse-content>
                        <div class="personal-form-fieldset__content--brief">
                            <ul class="list-unstyled">
                                <li class="personal-form__tasks-list__item">
                                    <button class="task-inline-btn">
                                        <span class="task-inline__title">Возврат некачественного товара</span>
                                        <span class="task-inline__icon">
                                            <icon-arr></icon-arr>
                                        </span>
                                    </button>
                                </li>
                                <li class="personal-form__tasks-list__item">
                                    <button class="task-inline-btn">
                                        <span class="task-inline__title">Возврат товара из интернет магазина</span>
                                        <span class="task-inline__icon">
                                            <icon-arr></icon-arr>
                                        </span>
                                    </button>
                                </li>
                                <li class="personal-form__tasks-list__item">
                                    <button class="task-inline-btn">
                                        <span class="task-inline__title">Доставили не тот товар</span>
                                        <span class="task-inline__icon">
                                            <icon-arr></icon-arr>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </x-collapse>
                <x-collapse collapsed class="personal-form-brief-collapse">
                    <div class="personal-form-fieldset-top">
                        <button data-collapse-toggler class="task-inline-btn task-inline-btn--brief">
                            <span class="task-inline__title">Путешествия</span>
                            <span class="task-inline__icon--brief">
                                <icon-arr></icon-arr>
                            </span>
                        </button>
                    </div>
                    <div class="collapse__content" data-collapse-content>
                        <div class="personal-form-fieldset__content--brief">
                            <ul class="list-unstyled">
                                <li class="personal-form__tasks-list__item">
                                    <router-link
                                        :to="`/${projectsStore.projects[0].slug}/tasks`"
                                        class="task-inline-btn"
                                    >
                                        <span class="task-inline__title">Перейти к выполнению задач</span>
                                        <span class="task-inline__icon">
                                            <icon-arr></icon-arr>
                                        </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </x-collapse>
                <x-collapse collapsed class="personal-form-brief-collapse">
                    <div class="personal-form-fieldset-top">
                        <button data-collapse-toggler class="task-inline-btn task-inline-btn--brief">
                            <span class="task-inline__title">Финансы</span>
                            <span class="task-inline__icon--brief">
                                <icon-arr></icon-arr>
                            </span>
                        </button>
                    </div>
                    <div class="collapse__content" data-collapse-content>
                        <div class="personal-form-fieldset__content--brief">
                            <ul class="list-unstyled">
                                <li class="personal-form__tasks-list__item">
                                    <router-link
                                        :to="`/${projectsStore.projects[0].slug}/tasks`"
                                        class="task-inline-btn"
                                    >
                                        <span class="task-inline__title">Перейти к выполнению задач</span>
                                        <span class="task-inline__icon">
                                            <icon-arr></icon-arr>
                                        </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </x-collapse>
            </PersonalFormFieldset>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, reactive, ref, defineComponent, watch, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { useMutation, useQuery } from 'vue-query';
import TaskInline from '../components/Task/TaskInline.vue';
import FormInput from '../components/FormInput/FormInput.vue';
import { validationMessage } from '../../../utils/forms';
import CheckIcon from '../svg/Check.vue';
import ArrIcon from '../svg/Arr.vue';
import DownloadIcon from '../svg/Download.vue';
import EnvelopeIcon from '../svg/Envelope.vue';
import { axiosAPI } from '../../../axios';
import { Collapse } from '../../../custom-elements/Collapse/Collapse';
import PersonalFormFieldset from '../components/personal/PersonalFormFieldset.vue';
import Loader from '../components/Loader/Loader.vue';
import Button from '../components/Button/Button.vue';
import LoadingButton from '../components/LoadingButton/LoadingButton.vue';
import Modal from '../components/Modal/Modal.vue';
import SubscriptionBlock from '../components/personal/SubscriptionBlock.vue';
import ConsolidatedProjectInfo from '../components/ConsolidatedProjectInfo/ConsolidatedProjectInfo.vue';
import { useUserStore } from '../stores/user';
import TransactionsBlock from '../components/personal/TransactionsBlock.vue';
import { useRouter } from 'vue-router';
import { useProjectsStore } from '../stores/projects';

export default defineComponent({
    components: {
        'icon-check': CheckIcon,
        'icon-arr': ArrIcon,
        'icon-download': DownloadIcon,
        'icon-envelope': EnvelopeIcon,
        'task-inline': TaskInline,
        'form-input': FormInput,
        PersonalFormFieldset,
        Loader,
        Button,
        LoadingButton,
        Modal,
        SubscriptionBlock,
        TransactionsBlock,
        ConsolidatedProjectInfo,
    },

    setup() {
        const router = useRouter();
        const containerRef = ref<HTMLDivElement>();
        const userStore = useUserStore();
        const projectsStore = useProjectsStore();
        // const projectId = getProjectPropBy(
        //     'slug',
        //     projectsStore.projects,
        //     projectsStore.projects[0].slug,
        //     'id',
        // );
        const paidProjects = computed(() =>
            projectsStore.projects.filter((project) => userStore.user.subscribed_projects.includes(project.id)),
        );

        const formData = reactive({
            debt: '',
            name: userStore.user ? userStore.user.name : '',
            lastName: userStore.user ? userStore.user.last_name : '',
            patronymic: userStore.user ? userStore.user.middle_name : '',
            email: userStore.user ? userStore.user.email : '',
            phone: userStore.user ? userStore.user.phone : '',
            dateOfBirth: userStore.user ? userStore.user.birthdate : '',
            passportNo: userStore.user ? userStore.user.passport_number : '',
            issuedBy: userStore.user ? userStore.user.registration_address : '',
            passportIssuerCode: userStore.user ? userStore.user.division_code : '',
            placeOfBirth: userStore.user ? userStore.user.birthplace : '',
            address: userStore.user ? userStore.user.debt : '',
            inn: userStore.user ? userStore.user.itn : '',
            snils: userStore.user ? userStore.user.snils : '',
        });

        const rules = computed(() => ({
            debt: {},
            name: {},
            lastName: {},
            patronymic: {},
            email: { email },
            phone: {},
            dateOfBirth: {},
            passportNo: {},
            issuedBy: {},
            passportIssuerCode: {},
            placeOfBirth: {},
            address: {},
            inn: {},
            snils: {},
        }));

        const _tasks = ref<any[]>([]);
        const isTasksLoading = ref<boolean>(false);
        const tasksErrorMessage = ref('');
        const completedTasks = ref(
            _tasks.value.map((task) => ({ ...JSON.parse(JSON.stringify(task)), active: false })),
        );

        const personalDataMutationMessage = ref('');
        const personalDataMutationMessageTimer = ref<NodeJS.Timeout>();

        // const completedTasksQuery = useQuery(
        //     'completed-tasks',
        //     () =>
        //         axiosAPI.get(`/projects/${projectId}/tasks`, {
        //             params: { completed: true },
        //         }),
        //     {
        //         refetchOnMount: false,
        //         refetchOnWindowFocus: false,
        //     },
        // );

        // const completedBriefsQuery = useQuery(
        //     'completed-briefs',
        //     () => axiosAPI.get<any>(`/projects/${projectId}/briefs`, { params: { completed: true } }),
        //     {
        //         refetchOnMount: false,
        //         refetchOnWindowFocus: false,
        //     },
        // );

        // const transactionsQuery = useQuery('transactions', () => axiosAPI.get(`/projects/${projectId}/transactions`), {
        //     refetchOnMount: false,
        //     refetchOnWindowFocus: false,
        // });

        const personalDataMutation = useMutation(
            'personal-data',
            () =>
                axiosAPI.put('/user', {
                    name: formData.name,
                    middle_name: formData.patronymic,
                    last_name: formData.lastName,
                    email: formData.email,
                    phone: formData.phone,
                    birthdate: formData.dateOfBirth,
                    passport_number: formData.passportNo,
                    division_code: formData.passportIssuerCode,
                    birthplace: formData.placeOfBirth,
                    registration_address: formData.issuedBy,
                    snils: formData.snils,
                    itn: formData.inn,
                }),
            {
                onMutate: () => {
                    if (personalDataMutationMessageTimer.value) {
                        clearTimeout(personalDataMutationMessageTimer.value);
                    }
                },
                onError: (error) => {
                    personalDataMutationMessage.value = error.message;
                },
                onSuccess: (data) => {
                    userStore.fetchUser();
                    personalDataMutationMessage.value = data.message;
                    personalDataMutationMessageTimer.value = setTimeout(() => {
                        personalDataMutationMessage.value = '';
                    }, 5000);
                },
            },
        );

        // const brief1Tasks = ref(_tasks.value.map((task) => ({ ...JSON.parse(JSON.stringify(task)), active: false })));

        // const texts = ref<any[]>([]);
        // const isTextsLoading = ref<boolean>(false);
        // const textsErrorMessage = ref('');

        // const documents = ref<any[]>([]);
        // const isDocumentsLoading = ref<boolean>(false);
        // const documentsErrorMessage = ref('');
        // const documentLoadingId = ref<number | null>(null);
        // const textLoadingId = ref<number | null>(null);

        const vv = useVuelidate(rules, formData);

        async function submit() {
            if (personalDataMutation.isLoading.value) return;
            personalDataMutation.mutate();
        }

        async function onSubmit() {
            await submit();
        }

        async function onBlur() {
            // await submit();
        }

        async function sendTextByEmail() {
            //
        }

        watch(
            () => userStore.user,
            (val) => {
                if (val) {
                    formData.name = val.name || '';
                    formData.lastName = val.last_name || '';
                    formData.patronymic = val.middle_name || '';
                    formData.email = val.email || '';
                    formData.phone = val.phone || '';
                    formData.dateOfBirth = val.birthdate || '';
                    formData.passportNo = val.passport_number || '';
                    formData.passportIssuerCode = val.division_code || '';
                    formData.placeOfBirth = val.birthplace || '';
                    formData.issuedBy = val.registration_address || '';
                    formData.snils = val.snils || '';
                    formData.inn = val.itn || '';
                }
            },
        );

        // async function fetchTexts() {
        //     try {
        //         isTextsLoading.value = true;
        //         const { data } = await axiosAPI.get<any>(`/projects/${projectId}/texts`);
        //         texts.value = data.data.texts;
        //     } catch (err: any) {
        //         textsErrorMessage.value = err.response ? err.response.data.message : err.message;
        //         throw err;
        //     } finally {
        //         isTextsLoading.value = false;
        //     }
        // }

        // async function fetchDocuments() {
        //     try {
        //         isDocumentsLoading.value = true;
        //         const { data } = await axiosAPI.get<any>('/documents');
        //         documents.value = data.data.tasks.reduce((docs, task) => {
        //             docs.push(...task.documents);
        //             return docs;
        //         }, []);
        //     } catch (err: any) {
        //         documentsErrorMessage.value = err.response ? err.response.data.message : err.message;
        //         throw err;
        //     } finally {
        //         isDocumentsLoading.value = false;
        //     }
        // }

        // async function downloadTextById(id: number, name: string) {
        //     try {
        //         textLoadingId.value = id;
        //         // const response = await fetch(`/api/documents/${id}/download`);
        //         const { data } = await axiosAPI.get(`/projects/${projectId}/texts/${id}/download`, {
        //             responseType: 'blob',
        //         });
        //         // const file = new Blob([data], { type: 'application/pdf' });
        //         const a = document.createElement('a');
        //         const url = URL.createObjectURL(data);
        //         // a.style.display = 'none';
        //         a.href = url;
        //         // the filename you want
        //         a.download = name;
        //         document.body.appendChild(a);
        //         a.click();
        //         a.remove();
        //         setTimeout(() => window.URL.revokeObjectURL(url), 5000);
        //     } catch (err: any) {
        //         // documentsErrorMessage.value = err.response ? err.response.data.message : err.message;
        //         throw err;
        //     } finally {
        //         textLoadingId.value = null;
        //     }
        // }

        // async function downloadDocumentById(uuid: number, name: string) {
        //     try {
        //         documentLoadingId.value = uuid;
        //         const { data: blob } = await axiosAPI.get(`/documents/${uuid}/download`, { responseType: 'blob' });
        //         const url = URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         a.style.display = 'none';
        //         a.href = url;
        //         // the filename you want
        //         a.download = name;
        //         document.body.appendChild(a);
        //         a.click();
        //         a.remove();
        //         setTimeout(() => window.URL.revokeObjectURL(url), 7000);
        //     } catch (err: any) {
        //         // documentsErrorMessage.value = err.response ? err.response.data.message : err.message;
        //         throw err;
        //     } finally {
        //         documentLoadingId.value = null;
        //     }
        // }

        // function hideTask(tasks: any[], id: number) {
        //     const task = tasks.find((obj) => obj.id === id);
        //     if (task) {
        //         task.active = false;
        //         if (window.matchMedia('(max-width: 1024px)').matches) {
        //             unlockScroll();
        //         }
        //     }
        // }

        // function showTask(tasks: any[], id: number) {
        //     const task = tasks.find((obj) => obj.id === id);
        //     tasks.forEach((task) => hideTask(tasks, task.id));
        //     if (task) {
        //         task.active = true;

        //         if (window.matchMedia('(max-width: 1024px)').matches) {
        //             lockScroll();
        //         }
        //     }
        // }

        watch(_tasks, (val) => {
            completedTasks.value = val.map((task) => ({ ...JSON.parse(JSON.stringify(task)), active: false }));
            // brief1Tasks.value = val.map((task) => ({ ...JSON.parse(JSON.stringify(task)), active: false }));
        });

        function fixCollapses() {
            if (containerRef.value) {
                Array.from(containerRef.value.querySelectorAll<Collapse>('x-collapse')).forEach((el) => {
                    el.setUIState();
                });
            }
        }

        function timeoutedFixCollapses() {
            setTimeout(fixCollapses, 50);
        }

        onMounted(() => {
            localStorage.removeItem('CURRENT_ACTION_ID_2');
        });

        // onMounted(() => {
        //     fetchTexts().then(timeoutedFixCollapses);
        //     fetchDocuments().then(timeoutedFixCollapses);
        // });

        // watch(completedTasksQuery.data, (val) => {
        //     if (val.data) {
        //         timeoutedFixCollapses();
        //     }
        // });

        // watch(completedBriefsQuery.data, (val) => {
        //     if (val.data) {
        //         timeoutedFixCollapses();
        //     }
        // });

        return {
            userStore,
            containerRef,
            vv,
            formData,
            rules,
            validationMessage,
            onSubmit,
            onBlur,
            sendTextByEmail,
            // completedTasks,
            // isTasksLoading,
            // tasksErrorMessage,
            // brief1Tasks,
            // completedTasksQuery,
            // completedBriefsQuery,
            // transactionsQuery,
            // showTask,
            // hideTask,
            // texts,
            // isTextsLoading,
            // textsErrorMessage,
            // documents,
            // isDocumentsLoading,
            // documentsErrorMessage,
            // downloadDocumentById,
            // documentLoadingId,
            personalDataMutation,
            personalDataMutationMessage,
            router,
            projectsStore,
            // downloadTextById,
            // textLoadingId,
            paidProjects,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

.personal-page-wrapper {
    max-width: 588px;

    @media screen and (max-width: 1024px) {
        padding-bottom: 120px;
    }
}

.personal-page-title {
    margin-bottom: 34px;
}

.personal-form-fieldset__content--brief {
    margin-top: 8px;
}

.personal-form-field {
    background-color: #fff;

    @media screen and (max-width: 1024px) {
        background-color: $input-bg;
    }

    &:focus {
        background-color: #fff;

        @media screen and (max-width: 1024px) {
            background-color: $input-bg;
        }
    }
}

.personal-form-row {
    display: flex;
    margin: -8px;
}

.personal-form-row__item {
    background-color: var(--body-bg);
    margin: 8px;
    width: calc(50% - 16px);

    @media screen and (max-width: 1024px) {
        background-color: #f6f7f9;
    }
}

.personal-form__tasks-list__item {
    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.task-inline-btn--brief {
    transition: background-color 0.2s ease, color 0.2s ease;

    .task-inline__icon {
        transition: transform 0.2s easeOutCubic;
    }
}

.personal-form-brief-collapse {
    &:not(:last-child) {
        margin-bottom: 24px;
    }

    &[collapsed] {
        @mixin hover() {
            .task-inline__icon--brief {
                path {
                    stroke: #fff;
                }
            }
        }

        &:focus-visible,
        &:active {
            @include hover();
        }

        @media (pointer: fine) {
            &:not(:disabled):hover {
                @include hover();
            }
        }
    }

    &:not([collapsed]) {
        .task-inline-btn--brief {
            background-color: $body-color;
            color: #fff;
        }

        .task-inline__icon--brief {
            transform: rotate(-90deg);

            path {
                stroke: #fff;
            }
        }
    }
}

.task-inline__icon--brief {
    display: inline-flex;
    align-items: center;
    height: 32px;

    @media screen and (max-width: 1024px) {
        height: auto;
    }

    path {
        stroke: $primary;
    }
}

.task-inline-right {
    display: flex;
    margin-left: auto;
}

.task-inline-right__item {
    flex-shrink: 0;
    padding: 9px;

    &:not(:last-child) {
        margin-right: 8px;
    }
}

.personal-page-block {
    &:not(:last-child) {
        margin-bottom: 32px;
    }
}

.personal-page-block__title {
    // @include responsive-font-size(24px, 32px, 1280px, 1920px);
    margin-bottom: 16px;
}

.personal-page-form {
    margin-bottom: 31px;
}

.personal-form-save-btn {
    width: 100%;

    .loader {
        --size: 20px;
    }
}

.personal-form-response {
    margin-bottom: 30px;
    color: $success;

    &.is-error {
        color: $danger;
    }
}

.personal-page-pay-btn {
    margin-top: 30px;
    width: 100%;
}

.personal-projects-item {
    &:not(:last-child) {
        margin-bottom: 70px;
    }
}
</style>
