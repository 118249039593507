import { defineStore } from 'pinia';

export const useAfterBriefStore = defineStore<
    'afterBrief',
    { briefId: number | null; lastQuestionId: number | null },
    {},
    {}
>('afterBrief', {
    state: () => ({ briefId: null, lastQuestionId: null }),
    persist: true,
});
