<template>
    <NotFoundPage v-if="notFound" />
    <div v-else class="wrapper survey">
        <SurveyTop :back-url="`/${router.currentRoute.value.params.projectSlug}/brief-complete`" />

        <h1 class="survey-title">Выберите тариф</h1>

        <TariffsList />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import NotFoundPage from '../pages/NotFoundPage.vue';
import SurveyTop from '../components/survey/SurveyTop/SurveyTop.vue';
import TariffsList from '../components/TariffsList/TariffsList.vue';
import { useRouter } from 'vue-router';
import { getProjectPropBy } from '../../../utils/projects';
import { useUserStore } from '../stores/user';
import { useProjectsStore } from '../stores/projects';
import { usePageClass } from '../hooks/usePageClass';

export default defineComponent({
    components: { TariffsList, NotFoundPage, SurveyTop },

    setup() {
        const notFound = ref(false);
        const router = useRouter();
        const userStore = useUserStore();
        const projectsStore = useProjectsStore();
        const projectId = getProjectPropBy<number>(
            'slug',
            projectsStore.projects,
            router.currentRoute.value.params.projectSlug,
            'id',
        );
        usePageClass({ 'choose-tariff-page': true, 'hidden-header': !userStore.user || !userStore.user.confirmed || !userStore.user.subscribed_projects.includes(projectId) });


        if (!projectId) {
            notFound.value = true;
        }

        return {
            router,
            notFound,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

html.choose-tariff-page {
    .survey {
        padding-bottom: 65px;
    }
}
</style>
