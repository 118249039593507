<template>
    <header class="header">
        <div class="wrapper header__row">
            <div class="header__left">
                <router-link to="/" class="header-logo">
                    <Logo />
                </router-link>
            </div>
            <div v-if="userStore.user" class="header__center">
                <div v-if="userStore.user.confirmed">
                    <nav class="header__nav">
                        <ul class="list-unstyled header-nav-list">
                            <li v-if="router.currentRoute.value.params.projectSlug" class="header-nav-list__item">
                                <router-link
                                    :to="`/${router.currentRoute.value.params.projectSlug}/tasks`"
                                    class="icon-link header-nav-link"
                                >
                                    <span aria-hidden="true" class="icon-link__icon">
                                        <icon-home></icon-home>
                                    </span>
                                    <span class="icon-link__text">Задачи</span>
                                </router-link>
                            </li>
                            <li class="header-nav-list__item">
                                <router-link
                                    :to="`${
                                        router.currentRoute.value.params.projectSlug
                                            ? `/${router.currentRoute.value.params.projectSlug}`
                                            : ''
                                    }/personal`"
                                    class="icon-link header-nav-link"
                                >
                                    <span aria-hidden="true" class="icon-link__icon">
                                        <icon-user></icon-user>
                                    </span>
                                    <span class="icon-link__text">Ваши данные</span>
                                </router-link>
                            </li>
                            <!-- <li v-if="isUserSubscribedToSomeProject" class="header-nav-list__item">
                            <router-link to="/help" class="icon-link header-nav-link">
                                <span aria-hidden="true" class="icon-link__icon">
                                    <icon-question-circle></icon-question-circle>
                                </span>
                                <span class="icon-link__text">Помощь</span>
                            </router-link>
                        </li> -->
                            <!-- <li v-if="isUserSubscribedToSomeProject" class="header-nav-list__item">
                            <router-link to="/search" class="icon-link header-nav-link">
                                <span aria-hidden="true" class="icon-link__icon">
                                    <SearchIcon />
                                </span>
                                <span class="icon-link__text">Поиск</span>
                            </router-link>
                        </li> -->
                            <li v-if="isAuthorized" class="header-nav-list__item">
                                <button
                                    v-if="userStore.user?.confirmed"
                                    class="icon-link header-nav-link"
                                    @click="() => logoutAndRefetchUser()"
                                    data-testid="logout"
                                >
                                    <span aria-hidden="true" class="icon-link__icon">
                                        <icon-logout></icon-logout>
                                    </span>
                                    <span class="icon-link__text">Выйти</span>
                                </button>
                                <button
                                    v-else
                                    class="icon-link header-nav-link"
                                    @click="() => logoutAndRefetchUser('/login')"
                                    data-testid="logout"
                                >
                                    <span aria-hidden="true" class="icon-link__icon">
                                        <icon-login></icon-login>
                                    </span>
                                    <span class="icon-link__text">Авторизация</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <nav v-else class="header__nav header__nav--mobile">
                    <ul class="list-unstyled header-nav-list">
                        <li v-if="isAuthorized" class="header-nav-list__item">
                            <button
                                v-if="userStore.user?.confirmed"
                                class="icon-link header-nav-link"
                                @click="() => logoutAndRefetchUser()"
                                data-testid="logout"
                            >
                                <span aria-hidden="true" class="icon-link__icon">
                                    <icon-logout></icon-logout>
                                </span>
                                <span class="icon-link__text">Выйти</span>
                            </button>
                            <button
                                v-else
                                class="icon-link header-nav-link"
                                @click="() => logoutAndRefetchUser('/login')"
                                data-testid="logout"
                            >
                                <span aria-hidden="true" class="icon-link__icon">
                                    <icon-login></icon-login>
                                </span>
                                <span class="icon-link__text">Авторизация</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <div v-if="isAuthorized" class="header__right">
                <Button
                    v-if="userStore.user?.confirmed"
                    variant="primary"
                    @click="() => logoutAndRefetchUser()"
                    data-testid="logout"
                >
                    Выйти
                </Button>
                <Button v-else variant="primary" class="header-logout-temp-user-btn" @click="() => logoutAndRefetchUser('/login')" data-testid="logout">
                    Авторизация
                </Button>
            </div>
        </div>
    </header>
</template>

<script lang="ts">
import { computed } from 'vue';
import { store } from '../store';
import HomeIcon from '../svg/Home.vue';
import UserIcon from '../svg/User.vue';
import Logo from '../svg/Logo.vue';
import LoginIcon from '../svg/Login.vue';
import LogoutIcon from '../svg/Logout.vue';
import QuestionCircleIcon from '../svg/QuestionCircle.vue';
import Button from '../components/Button/Button.vue';
import SearchIcon from '../svg/Search.vue';
import { useUserStore } from '../stores/user';
import { useRouter } from 'vue-router';

export default {
    components: {
        'icon-home': HomeIcon,
        'icon-login': LoginIcon,
        'icon-logout': LogoutIcon,
        'icon-user': UserIcon,
        'icon-question-circle': QuestionCircleIcon,
        Button,
        Logo,
        SearchIcon,
    },

    setup() {
        const router = useRouter();
        const userStore = useUserStore();
        const isAuthorized = computed(() => !!store.state.token);

        const isUserSubscribedToSomeProject = computed(() => userStore.user.subscribed_projects.length > 0);

        async function logoutAndRefetchUser(redirectTo = '/') {
            await userStore.logout(redirectTo);
        }

        function reditectToProject(projectId: string) {
            console.log({ projectId });
            //    router.push(`/projects/${projectId}/tasks`);
        }

        return {
            reditectToProject,
            logoutAndRefetchUser,
            userStore,
            isAuthorized,
            router,
            isUserSubscribedToSomeProject,
        };
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

.header {
    padding-top: 16px;
    padding-bottom: 17px;
    background-color: var(--elements-bg);

    @media screen and (max-width: 1024px) {
        // display: none;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.header__row {
    display: flex;
}

.header__left {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
}

.header-logo {
    flex-shrink: 0;
    margin-right: 108px;
    width: 136px;

    @media screen and (max-width: 1680px) {
        width: 110px;
    }

    @media screen and (max-width: 1280px) {
        width: 100px;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

.header__center {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 40px;
}

.header__nav {
    @media screen and (max-width: 1024px) {
        // display: none;
        position: fixed;
        padding-top: 16px;
        padding-bottom: 17px;
        padding-left: 16px;
        padding-right: 100px;
        background-color: #fff;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        border-top: 1px solid var(--body-bg);
    }

    &.header__nav--mobile {
        display: none;

        @media screen and (max-width: 1024px) {
            display: block;
        }
    }
}

.header-nav-list {
    display: flex;

    @media screen and (max-width: 1024px) {
        justify-content: space-between;
    }
}

.header-nav-list__item {
    &:not(:last-child) {
        margin-right: 70px;

        @media screen and (max-width: 1024px) {
            margin-right: 0;
        }
    }
}

.icon-link {
    display: inline-flex;
    align-items: center;
}

a.icon-link,
button.icon-link {
    transition: color 0.2s easeOutCubic;

    @mixin hover() {
        color: $primary;
    }

    &:focus-visible,
    &:active {
        @include hover();
    }

    @media (pointer: fine) {
        &:not(:disabled):hover {
            @include hover();
        }
    }
}

.icon-link__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
    width: 16px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 3px;
        background-color: $primary;
        border-radius: 16px;
        transition: transform 0.3s easeOutCubic;
        transform-origin: 100% 50%;
        transform: translate(-50%, -50%) scaleX(0);
    }

    svg {
        width: 100%;
        height: auto;
        max-height: 100%;
    }
}

.header-nav-link {
    @media screen and (max-width: 1024px) {
        width: 86px;
        height: 36px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 375px) {
        width: 45px;
    }

    &:active {
        @media screen and (max-width: 1024px) {
            color: #fff !important;
        }
    }

    &.router-link-active {
        color: $primary;

        @media screen and (max-width: 1024px) {
            background-color: $primary;
            color: #fff;
        }

        .icon-link__icon::after {
            @media screen and (min-width: 1025px) {
                transform-origin: 0% 50%;
                transform: translate(-50%, -50%) scaleX(1);
            }
        }

        svg {
            [fill]:not([fill='none']) {
                fill: currentColor;
            }

            [stroke] {
                stroke: currentColor;
            }
        }
    }

    .icon-link__icon {
        @media screen and (max-width: 1024px) {
            margin-right: 0;
        }
    }

    .icon-link__text {
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}

.header__right {
    margin-left: auto;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.header-logout-temp-user-btn {
    html.hidden-header & {
        display: none;
    }
}
</style>
