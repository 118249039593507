<template>
    <footer class="footer">
        <div class="wrapper footer__row">
            <div class="footer__left">
                <div class="footer-logo">
                    <Logo />
                </div>
                <div class="footer-address">
                    <div>ИП Прокопьев МВ, ИНН 245511454501</div>
                    <div><a href="mailto:support@osmolegal.ru" class="link">support@osmolegal.ru</a></div>
                </div>
            </div>
            <div class="footer__right">
                <div>
                    <!-- <a href="tel:+78991231212" class="footer-contact">8 (899) 123-12-12</a> -->
                    <div>
                        <router-link to="/license" class="footer-contact">Лицензионное соглашение</router-link>
                    </div>
                    <div>
                        <router-link to="/policy" class="footer-contact">Соглашение о конфиденциальности</router-link>
                    </div>
                    <div>
                        <router-link to="/tariffs-list" class="footer-contact">Тарифы</router-link>
                    </div>
                </div>
                <!-- <div class="footer-soc-list">
                    <ul class="list-unstyled soc-list">
                        <li class="soc-list__item">
                            <a
                                href="#"
                                class="soc-link"
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                aria-label="Перейти в Instagram"
                            >
                                <icon-instagram></icon-instagram>
                            </a>
                        </li>
                        <li class="soc-list__item">
                            <a
                                href="#"
                                class="soc-link"
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                aria-label="Перейти в Facebook"
                            >
                                <icon-facebook></icon-facebook>
                            </a>
                        </li>
                        <li class="soc-list__item">
                            <a
                                href="#"
                                class="soc-link"
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                aria-label="Перейти в Vk"
                            >
                                <icon-vk></icon-vk>
                            </a>
                        </li>
                    </ul>
                </div> -->
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import InstagramIcon from '../svg/Instagram.vue';
import FacebookIcon from '../svg/Facebook.vue';
import VkIcon from '../svg/Vk.vue';
import Logo from '../svg/Logo.vue';

export default {
    components: { 'icon-instagram': InstagramIcon, 'icon-facebook': FacebookIcon, 'icon-vk': VkIcon, Logo },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

.footer {
    background-color: $body-color;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 47px;
}

.footer-contact {
    transition: opacity 0.2s ease;

    @mixin hover() {
        opacity: 0.65;
    }

    &:focus-visible,
    &:active {
        @include hover();
    }

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        &:hover {
            @include hover();
        }
    }
}
</style>
