<template>
    <div class="wrapper">
        <div class="text-wrapper wysiwyg">
            <h1>Политика конфиденциальности Google</h1>

            <h2>
                Пользуясь сервисами Google, Вы доверяете нам свою личную информацию. Мы делаем все для обеспечения ее
                безопасности и в то же время предоставляем Вам возможность управлять своими данными.
            </h2>

            <p>
                Внимательно изучите нашу Политику конфиденциальности, чтобы знать, какие сведения мы собираем, в каких
                целях их используем и как Вы можете их изменять, экспортировать или удалять.
            </p>

            <p>
                Мы предлагаем множество сервисов, которые помогают миллионам людей ежедневно узнавать что-то новое,
                решать различные задачи и просто общаться друг с другом. Вот лишь несколько примеров наших продуктов:
            </p>

            <ul>
                <li>приложения, сайты и устройства, такие как Google Поиск, YouTube и Google Home;</li>
                <li>платформы, такие как браузер Chrome и операционная система Android;</li>
                <li>
                    решения, которые можно встраивать в приложения от других разработчикови на сторонние сайты, например
                    рекламные сервисы, встроенные Google Карты и т.д.
                </li>
            </ul>

            <p>
                В наших сервисах можно как размещать личные данные и материалы, так и различными способами управлять ими
                в целях обеспечения конфиденциальности. Создав аккаунт Google, Вы сможете переписываться с друзьями или
                коллегами в Gmail или публиковать фотографии в Google Фото, а результаты поиска Google станут точнее
                соответствовать запросам. В то же время многими сервисами можно пользоваться, не входя в аккаунт и даже
                не создавая его, например выполнять поиск в Интернете и смотреть видео на YouTube. Кроме того, в Chrome
                Вы можете посещать веб-страницы анонимно – в режиме инкогнито. Во всех наших сервисах Вы сами
                указываете, какую информацию о Вас мы можем собирать и как ее использовать.
            </p>

            <p>
                Чтобы наши правила были как можно более понятными, мы добавили в них примеры, видеоролики и определения
                <a href="#">основных терминов</a>. Если у Вас появятся вопросы в отношении Политики конфиденциальности
                Google, <a href="#">свяжитесь с нами</a>.
            </p>

            <h2>Типы информации</h2>

            <p>
                Google собирает пользовательские данные для того, чтобы наши сервисы были более удобными. Благодаря
                этому мы не только определяем, на каком языке Вы задали запрос, но решаем более сложные задачи, например
                автоматически подбираем полезные объявления или интересные видеоролики на YouTube, а также помогаем
                находить контакты друзей и знакомых в Интернете. Тип данных, которые мы собираем, зависит от того, как
                Вы используете наши продукты и какие настройки конфиденциальности заданы в Вашем аккаунте.
            </p>

            <p>
                Если Вы не выполнили вход в аккаунт Google, мы регистрируем информацию, которую собираем с помощью
                уникальных идентификаторов, связанных с браузерами, приложениями и устройствами. Это позволяет нам
                запоминать Ваши настройки и другие данные, чтобы Вам не приходилось указывать их заново при новом
                сеансе.
            </p>

            <p>
                Если Вы выполнили вход, мы собираем и сохраняем в аккаунте Google информацию, которые обрабатываем как
                личные данные.
            </p>

            <figure>
                <img :src="`${PUBLIC_PATH}img/test.png`" width="760" height="404" loading="lazy" alt="" />
            </figure>

            <p>
                Мы предлагаем множество сервисов, которые помогают миллионам людей ежедневно узнавать что-то новое,
                решать различные задачи и просто общаться друг с другом. Вот лишь несколько примеров наших продуктов:
            </p>

            <ol>
                <li>приложения, сайты и устройства, такие как Google Поиск, YouTube и Google Home;</li>
                <li>платформы, такие как браузер Chrome и операционная система Android;</li>
                <li>
                    решения, которые можно встраивать в приложения от других разработчикови на сторонние сайты, например
                    рекламные сервисы, встроенные Google Карты и т.д.
                </li>
            </ol>

            <h2>Таблица</h2>

            <table>
                <thead>
                    <tr>
                        <th>Таблица 1</th>
                        <th>Таблица 2</th>
                        <th>Таблица 3</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Значение</td>
                        <td>Значение</td>
                        <td>Значение</td>
                    </tr>
                    <tr>
                        <td>Значение</td>
                        <td>Значение</td>
                        <td>Значение</td>
                    </tr>
                    <tr>
                        <td>Значение</td>
                        <td>Значение</td>
                        <td>Значение</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    setup() {
        return { PUBLIC_PATH: PUBLIC_PATH };
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';
</style>
