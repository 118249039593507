<template>
    <div class="file-upload-container">
        <label ref="label" class="btn btn-info file-upload" :class="{ 'is-loading': isUploading }">
            <input
                ref="input"
                type="file"
                class="visually-hidden"
                :name="props.name"
                :multiple="props.multiple"
                @change="onInputChange"
            />
            <span class="file-upload__icon">
                <Loader v-if="isUploading" />
                <IconUpload v-else />
            </span>
            <span class="file-upload__text">
                <template v-if="isUploading">{{ dict.loading }}</template>
                <template v-else>{{ dict.chooseFiles }}</template>
            </span>
        </label>
        <div v-if="files.length > 0" class="file-upload__uploaded-list-container">
            <ul class="list-unstyled file-upload__uploaded-list">
                <li v-for="file in files" :key="file.name" class="file-upload__uploaded-list__item">
                    <div class="file-upload__uploaded-file">
                        <div class="file-upload__uploaded-file__name">
                            {{ file.name }}
                        </div>
                        <button
                            type="button"
                            class="file-upload__uploaded-file__close-btn"
                            aria-label="Закрыть"
                            @click="() => removeFileById(file.id)"
                        >
                            <CrossIcon />
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import IconUpload from '../../svg/Upload.vue';
import CrossIcon from '../../svg/Cross.vue';
import Loader from '../Loader/Loader.vue';

const props = defineProps({
    dict: {
        type: Object,
        default: () => ({
            chooseFiles: 'Загрузить файл',
            loading: 'Идет загрузка, подождите',
            loadingError: 'Ошибка загрузки файла',
        }),
    },
    multiple: {
        type: Boolean,
        default: true,
    },
    name: {
        type: String,
        default: 'files[]',
    },
    uppyProps: {
        type: Object,
        default: () => ({
            pretty: false,
            autoProceed: true,
            // maxNumberOfFiles: 40,
            // allowedFileTypes: [
            //     'image/*',
            //     '.doc',
            //     '.docx',
            //     '.rtf',
            //     '.xls',
            //     '.xlsx',
            //     '.pdf',
            //     '.xml,application/msword',
            //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            // ],
        }),
    },
});

const emit = defineEmits(['update:modelValue', 'upload', 'complete', 'upload-success']);

const tempFilesEndpoint = '/build/php/file_upload.php';
const input = ref<HTMLInputElement>();
const files = ref<any[]>([]);
const uppy = ref<Uppy>();
const isUploading = ref(false);

function onInputChange(event: any) {
    const inputFiles = Array.from(event.target.files) as File[];

    inputFiles.forEach((file) => {
        try {
            uppy.value?.addFile({
                source: 'file input',
                name: file.name,
                type: file.type,
                data: file,
            });
        } catch (err) {
            if (err.isRestriction) {
                console.error('Restriction error:', err);
            }

            throw err;
        }
    });
}

function removeFileById(id: string) {
    uppy.value?.removeFile(id);
    files.value = files.value.filter((file) => file.id !== id);
    emit(
        'update:modelValue',
        files.value.map((item) => item.data),
    );
}

onMounted(() => {
    uppy.value = new Uppy(props.uppyProps);

    uppy.value.use(XHRUpload, {
        endpoint: tempFilesEndpoint,
        formData: true,
        fieldName: props.name,
    });

    uppy.value.on('upload', (data) => {
        isUploading.value = true;
        emit('upload', data);
    });

    uppy.value.on('complete', (data) => {
        isUploading.value = false;
        input.value = null;
        emit('complete', data);
    });

    uppy.value.on('upload-success', (file, response) => {
        files.value.push(file);
        emit('upload-success', file);
        emit(
            'update:modelValue',
            files.value.map((item) => item.data),
        );
    });
});

onBeforeUnmount(() => {
    if (uppy.value) {
        uppy.value.cancelAll();
        uppy.value.close();
    }
});
</script>

<style lang="scss">
@import '~@uppy/core/dist/style.css';
@import '~@uppy/file-input/dist/style.css';
@import '~@uppy/progress-bar/dist/style.css';
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.file-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--elements-bg);
    text-transform: none;

    &.is-loading {
        color: $primary;
    }
}

.file-upload__icon {
    width: 13px;
    margin-right: 14px;

    svg {
        width: 100%;
        height: auto;
    }

    .loader {
        --size: 16px;
    }
}

.file-upload__uploaded-list-container {
    margin-top: 8px;
}

.file-upload__uploaded-list {
    display: flex;
    gap: 3px 8px;
}

.file-upload__uploaded-file {
    display: inline-flex;
    align-items: center;
    background-color: var(--elements-bg);
    border-radius: 6px;
    padding: 8px 9px;
}

.file-upload__uploaded-file__name {
    font-size: 12px;
    line-height: 1.05;
    font-weight: 500;
}

.file-upload__uploaded-file__close-btn {
    @include clickable();
    flex-shrink: 0;
    width: 8px;
    margin-left: 11px;

    svg {
        width: 100%;
        height: auto;
    }
}
</style>
