<template>
    <Button
        class="loading-btn"
        :style="{
            width: isLoading && rect ? `${rect.width}px` : '',
            height: isLoading && rect ? `${rect.height}px` : '',
        }"
        @mounted="onButtonMounted"
    >
        <v-spinner v-if="isLoading"></v-spinner>
        <slot v-else></slot>
    </Button>
</template>

<script lang="ts">
import { ref, watch } from 'vue';
import Button from '../Button/Button.vue';
import Spinner from '../Spinner/Spinner.vue';

export default {
    components: { 'v-spinner': Spinner, Button },

    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    setup(props) {
        const button = ref<HTMLElement>();
        const rect = ref<DOMRect>();

        function calculateRect() {
            rect.value = button.value.getBoundingClientRect();
        }

        function onButtonMounted(data) {
            button.value = data.ref.value;
        }

        watch(
            () => props.isLoading,
            (val) => {
                if (val) {
                    calculateRect();
                }
            },
        );

        return { onButtonMounted, rect };
    },
};
</script>

<style lang="scss">
@import '../../../../../css/base/variables';

.loading-btn {
    display: inline-flex;
    justify-content: center;

    &.btn-primary {
        .spinner {
            border-color: rgba(#fff, 0.1);
            border-left-color: #fff;
        }
    }

    .spinner {
        --size: #{$btn-line-height * 1em};
    }
}
</style>
