<template>
    <div class="wrapper authorize-page">
        <div class="authorize-page-content">
            <h1 class="authorize-page-title" data-testid="login-page-title">Войти в аккаунт</h1>
            <form novalidate @submit.prevent="onSubmit" class="form" data-testid="login-form">
                <form-input
                    id="login-form-phone"
                    :required="true"
                    :helping-message="validationMessage(vv.phone)"
                    :is-error="!!vv.phone.$errors[0]"
                >
                    <input
                        type="tel"
                        id="login-form-phone"
                        class="form-control"
                        v-model="vv.phone.$model"
                        v-maska="{ mask: '+7 (ZZZ) ZZZ-ZZ-ZZ', tokens: { Z: { pattern: /[0-9]/ } } }"
                        placeholder="Номер телефона"
                        required
                    />
                </form-input>
                <form-input
                    id="login-form-password"
                    :required="true"
                    :helping-message="validationMessage(vv.password)"
                    :is-error="!!vv.password.$errors[0]"
                >
                    <input
                        type="password"
                        id="login-form-password"
                        class="form-control"
                        v-model="vv.password.$model"
                        placeholder="Пароль"
                        required
                    />
                </form-input>
                <div class="authorize-form-forgot-password">
                    <router-link to="/forgot-password" class="underline authorize-form__help-link"
                        >Забыли пароль?</router-link
                    >
                </div>
                <div v-if="errorMessage" class="form-error-message" data-testid="login-form-error">
                    {{ errorMessage }}
                </div>
                <div class="login-page-bottom">
                    <v-checkbox
                        :checked="formData.remember"
                        @change="
                            (event) => {
                                formData.remember = !formData.remember;
                            }
                        "
                        >Запомнить меня</v-checkbox
                    >
                    <v-loading-button
                        type="submit"
                        variant="primary"
                        class="authorize-page-btn authorize-submit-btn"
                        data-testid="login"
                        :disabled="isLoggingIn || isFetchingFirstQuestion"
                        :is-loading="isLoggingIn || isFetchingFirstQuestion"
                        >Войти</v-loading-button
                    >
                </div>
            </form>

            <div v-if="projectSlug" class="login-no-account">
                <p class="login-no-account__text">Нет аккаунта?</p>
                <v-loading-button
                    variant="light"
                    class="authorize-page-btn"
                    data-testid="register"
                    @click="fetchBriefFirstQuestion"
                    :disabled="isFetchingFirstQuestion"
                    :is-loading="isFetchingFirstQuestion"
                    >Зарегистрироваться</v-loading-button
                >
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { onUnmounted, reactive, ref, toRef, defineComponent, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useRouter } from 'vue-router';
import Button from '../components/Button/Button.vue';
import LoadingButton from '../components/LoadingButton/LoadingButton.vue';
import Checkbox from '../components/Checkbox/Checkbox.vue';
import FormInput from '../components/FormInput/FormInput.vue';
import { validationMessage } from '../../../utils/forms';
import { isPhone } from '../../../../modules/validator';
import { store } from '../store';
import { useUserStore } from '../stores/user';
import { axiosAPI } from '../../../axios';
import { getProjectPropBy } from '../../../utils/projects';
import { useProjectsStore } from '../stores/projects';
import { QA_CHAIN_LS_NAME } from './BriefPage.vue';

const rules = {
    phone: { required, phone: isPhone },
    password: { required },
};

export default defineComponent({
    components: { FormInput, 'v-button': Button, 'v-loading-button': LoadingButton, 'v-checkbox': Checkbox },

    setup() {
        const formData = reactive({ phone: '', password: '', remember: false });
        const errorMessage = ref('');
        const errorMessageClearTimeout = ref<NodeJS.Timeout>();
        const userStore = useUserStore();
        const projectsStore = useProjectsStore();
        const router = useRouter();
        const projectSlug = computed(() => router.currentRoute.value.redirectedFrom?.params.projectSlug);
        const isLoggingIn = ref(false);
        const isFetchingFirstQuestion = ref(false);

        async function fetchBriefFirstQuestion() {
            if (projectSlug) {
                isFetchingFirstQuestion.value = true;

                try {
                    const projectId = getProjectPropBy('slug', projectsStore.projects, projectSlug.value, 'id');
                    const { data } = await axiosAPI.get(`/projects/${projectId}/start`);

                    if (typeof data.data.brief_id === 'number' && typeof data.data.question_id === 'number') {
                        router.push(`/${projectSlug.value}/briefs/${data.data.brief_id}/${data.data.question_id}`);
                    } else {
                        console.log('failed');
                    }
                } finally {
                    isFetchingFirstQuestion.value = false;
                }
            }
        }

        const vv = useVuelidate(rules, {
            phone: toRef(formData, 'phone'),
            password: toRef(formData, 'password'),
        });

        async function onSubmit() {
            if (errorMessageClearTimeout.value) {
                clearTimeout(errorMessageClearTimeout.value);
            }
            errorMessage.value = '';
            vv.value.$touch();
            if (vv.value.$invalid) return;

            if (!store.state.token) {
                isLoggingIn.value = true;
                let qaChainData = undefined;

                if (sessionStorage.getItem(QA_CHAIN_LS_NAME)) {
                    let briefId = undefined;

                    const projectId = getProjectPropBy(
                        'slug',
                        projectsStore.projects,
                        router.currentRoute.value.query.redirect &&
                            typeof router.currentRoute.value.query.redirect === 'string' &&
                            router.currentRoute.value.query.redirect.split('/')[1],
                        'id',
                    );

                    await axiosAPI.get(`/projects/${projectId}/start`).then(({ data }) => {
                        briefId = data.data.brief_id;
                    });

                    const savedQaChain = sessionStorage.getItem(QA_CHAIN_LS_NAME);
                    if (typeof savedQaChain === 'string') {
                        qaChainData = {
                            phone: formData.phone.replace(/[^a-zA-Z0-9]/g, '').replace(/s/, ''),
                            brief: {
                                id: briefId,
                                questions: JSON.parse(savedQaChain),
                            },
                        };
                    }
                }
                try {
                    await userStore.login(
                        formData.phone.replace(/[^a-zA-Z0-9]/g, '').replace(/s/, ''),
                        formData.password,
                        router.currentRoute.value.redirectedFrom?.fullPath ||
                            router.currentRoute.value.query.redirect ||
                            '/',
                        qaChainData,
                    );
                } catch (err: any) {
                    errorMessage.value = err.response.data.message;

                    errorMessageClearTimeout.value = setTimeout(() => {
                        errorMessage.value = '';
                    }, 4000);

                    throw err;
                } finally {
                    isLoggingIn.value = false;
                }
            }
        }

        onUnmounted(() => {
            if (errorMessageClearTimeout.value) {
                clearTimeout(errorMessageClearTimeout.value);
            }
        });

        return {
            vv,
            formData,
            rules,
            validationMessage,
            errorMessage,
            onSubmit,
            fetchBriefFirstQuestion,
            isFetchingFirstQuestion,
            projectSlug,
            isLoggingIn,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

.authorize-page {
    @media screen and (min-width: 1025px) {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.authorize-page-title {
    margin-bottom: 32px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 16px;
    }
}

.authorize-page-content {
    max-width: 545px;
    width: 100%;
}

.login-page-bottom {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.login-no-account {
    padding-top: 39px;
    margin-top: 24px;
    border-top: 1px solid rgba($body-color, 0.2);
}

.login-no-account__text {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 16px;

    @media screen and (min-width: 1025px) {
        margin-bottom: 16px;
    }
}

.authorize-page-btn {
    width: 100%;

    @media screen and (min-width: 481px) {
        max-width: 245px;
    }
}

.authorize-submit-btn {
    margin-top: 18px;

    @media screen and (min-width: 1025px) {
        margin-left: auto;
        margin-top: 0;
    }
}

.authorize-form__help-link {
    opacity: 0.5;
}

.authorize-form-forgot-password {
    margin-bottom: 40px;
    margin-top: 11px;

    @media screen and (min-width: 1025px) {
        margin-top: 15px;
    }
}
</style>
