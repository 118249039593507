import './webpack-imports';
import './sentry';
import './polyfills';
import { createApp } from 'vue';
import { VueQueryPlugin } from 'vue-query';
import Maska from 'maska';
import { registerCustomElements } from './custom-elements';
import { router } from './components/vue/router';
import App from './components/vue/App.vue';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

registerCustomElements();
calculateScrollbarWidth();

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
console.log('ci worksss')

createApp(App).use(VueQueryPlugin).use(pinia).use(router).use(Maska).mount('#root');
