import _styles from './AutocompleteInput.ce.scss';
import { LitElement, css, html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import debounce from 'lodash.debounce';
import { axiosAPI } from '../../axios';

// import KEYCODES from '../../utils/keycodes';

type Suggestion = {
    value: string;
    text: string;
    nonInteractive?: boolean;
};

export interface AutocompleteInput {
    method?: string;
    endpoint: string;
    loading: boolean;
    data: string;
    requestData: Record<string, any>;
    _input: HTMLInputElement;
    _isSuggestionsVisible: boolean;
    _suggestions: Suggestion[];
    _debouncedOnChange: (event: any) => void;
    _abortController: AbortController | undefined;
    _value: string;
}

export class AutocompleteInput extends LitElement {
    constructor() {
        super();
        this._onInput = this._onInput.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onChange = this._onChange.bind(this);
        this._closeOnOutsideClick = this._closeOnOutsideClick.bind(this);

        this._suggestions = [];
        this._value = '';
    }

    static get properties() {
        return {
            endpoint: {
                type: String,
                reflect: true,
            },
            method: {
                type: String,
                value: 'get',
            },
            data: {
                type: String,
                reflect: true,
            },
            loading: {
                type: Boolean,
                value: false,
            },
            _isSuggestionsVisible: {
                type: Boolean,
                value: false,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.method = this.method?.toLowerCase() === 'post' ? 'post' : 'get';

        this._input = this.querySelector('input') as HTMLInputElement;
        this._input.setAttribute('role', 'textbox');
        this._input.setAttribute('aria-autocomplete', 'list');
        this._input.setAttribute('aria-multiline', 'false');
        this._input.setAttribute('autocomplete', 'off');

        this._debouncedOnChange = debounce(this._onChange, 600);

        this._input.addEventListener('input', this._onInput);
        this._input.addEventListener('focus', this._onFocus);
        this._input.addEventListener('blur', this._onBlur);
        document.addEventListener('click', this._closeOnOutsideClick);
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        super.attributeChangedCallback(name, oldValue, newValue);

        if (name === 'data') {
            this.requestData = this.data ? JSON.parse(this.data) : {};
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        if (this._abortController) {
            this._abortController.abort();
            this._abortController = undefined;
        }

        this._input.removeEventListener('input', this._onInput);
        this._input.removeEventListener('focus', this._onFocus);
        this._input.removeEventListener('blur', this._onBlur);
        document.removeEventListener('click', this._closeOnOutsideClick);
    }

    protected async _onChange(event: any) {
        this._abortController = new AbortController();
        this.loading = true;

        try {
            let response;

            if (this.method === 'post') {
                response = await axiosAPI.post(
                    this.endpoint,
                    {
                        ...this.requestData,
                        search_query: this._input.value.trim(),
                    },
                    {
                        signal: this._abortController.signal,
                    },
                );
            } else {
                response = await axiosAPI.get(this.endpoint, {
                    params: {
                        ...this.requestData,
                        search_query: this._input.value.trim(),
                    },
                    signal: this._abortController.signal,
                });
            }

            if (response.data.success) {
                this._suggestions = response.data.data;
                this.dispatchEvent(new CustomEvent('fetch-success', { detail: response.data.data }));
                this._isSuggestionsVisible = true;
                this.setAttribute('opened', '');
            } else {
                throw new Error(response.message);
            }
        } catch (err) {
            if (err.name !== 'AbortError') {
                if (err.response) {
                    this._suggestions = [
                        {
                            value: err.response.data?.message || 'Что-то пошло не так',
                            text: err.response.data?.message || 'Что-то пошло не так',
                            nonInteractive: true,
                        },
                    ];
                    this._isSuggestionsVisible = true;
                } else {
                    throw err;
                }
            }
        } finally {
            this.loading = false;
        }
    }

    protected _onSuggestionClick(event: any) {
        const btn = event.target.closest('.suggestion-btn');

        if (btn?.dataset.value.trim()) {
            this._input.value = btn.textContent.trim();
            this._value = btn.dataset.value.trim();
            // this._input.dispatchEvent(new Event('input', { composed: true }));
        }

        this._isSuggestionsVisible = false;
        this.removeAttribute('opened');
        this.dispatchEvent(
            new CustomEvent('choice', {
                composed: true,
                detail: { value: this._value, triggerElement: btn },
            }),
        );
    }

    protected _onSuggestionKeydown(event: any) {
        // if (event.keyCode === KEYCODES.ARROW_UP) {
        //     event.preventDefault();
        //     if (event.target.parentElement.previousElementSibling) {
        //         event.target.parentElement.previousElementSibling.querySelector('.suggestion-btn')?.focus();
        //     } else {
        //         this._input?.focus();
        //     }
        // }
        // if (event.keyCode === KEYCODES.ARROW_DOWN) {
        //     event.preventDefault();
        //     event.target.parentElement.nextElementSibling?.querySelector('.suggestion-btn')?.focus();
        // }
    }

    protected _closeOnOutsideClick(event: any) {
        if (!event.target.closest('.autocomplete')) {
            this._isSuggestionsVisible = false;
            this.removeAttribute('opened');
        }
    }

    protected _onInput(event: any) {
        this._input.value = event.target.value.trim();
        this._abortController?.abort();
        this._debouncedOnChange(event);
    }

    protected _onFocus(event: any) {
        this._input.value = event.target.value.trim();
        this._abortController?.abort();
        this._onChange(event);
    }

    protected _onBlur() {
        this._abortController?.abort();
    }

    render() {
        return html`
            <div class="input-container">
                <div class="input-row">
                    <slot></slot>
                </div>
                <div
                    class="${classMap({
                        'loader-container': true,
                        'loader-container--visible': this.loading,
                    })}"
                >
                    <div class="loader" role="status">
                        <span class="visually-hidden">Загрузка...</span>
                    </div>
                </div>
            </div>
            <div
                class="${classMap({
                    dropdown: true,
                    'dropdown--opened': this._isSuggestionsVisible,
                })}"
            >
                <ul class="list">
                    ${this._suggestions.length > 0
                        ? this._suggestions.map(
                              (suggestion) => html`
                                  <li class="list__item">
                                      ${suggestion.nonInteractive
                                          ? html`<div class="suggestion-btn">
                                                ${html`${unsafeHTML(suggestion.text)}`}
                                            </div>`
                                          : html`<button
                                                type="button"
                                                class="suggestion-btn"
                                                tabindex="-1"
                                                @click="${this._onSuggestionClick}"
                                                @keydown="${this._onSuggestionKeydown}"
                                                data-value="${suggestion.value}"
                                            >
                                                ${html`${unsafeHTML(suggestion.text)}`}
                                            </button>`}
                                  </li>
                              `,
                          )
                        : html`<li class="list__item">
                              <div class="suggestion-btn">Ничего не найдено</div>
                          </li>`}
                </ul>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'autocomplete-input': AutocompleteInput;
    }
}
