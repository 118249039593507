import messages from '../../modules/validator/messages';

export function validationMessage(validatorObj: any) {
    const validator = validatorObj?.$errors[0]?.$validator;

    if (!validator) return '';

    switch (validator) {
        case 'required':
            return messages.ru.EMPTY_FIELD;
        case 'email':
            return messages.ru.INVALIDATED_EMAIL;
        case 'phone':
            return messages.ru.INVALIDATED_PHONE;
        default:
            return 'Некорректное значение';
    }
}
