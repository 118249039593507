<template>
    <svg width="137" height="16" viewBox="0 0 137 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.875 15.005a6.614 6.614 0 0 0 2.6-2.705 8.842 8.842 0 0 0 .934-3.338h-3.33a6.807 6.807 0 0 1-.452 1.764 3.767 3.767 0 0 1-1.361 1.768 3.644 3.644 0 0 1-2.07.584 3.732 3.732 0 0 1-2.069-.584 3.767 3.767 0 0 1-1.361-1.768 7.073 7.073 0 0 1-.436-1.764H.025c.107 1.153.408 2.281.893 3.338A6.685 6.685 0 0 0 3.5 15.005a7.41 7.41 0 0 0 3.696.938 7.285 7.285 0 0 0 3.679-.938Z"
            fill="#2F2F33"
        />
        <path
            d="M3.5 1.017A6.685 6.685 0 0 0 .918 3.723 10.275 10.275 0 0 0 0 7.379h3.296c.041-.743.2-1.475.47-2.17a3.537 3.537 0 0 1 3.43-2.282 3.626 3.626 0 0 1 2.07.566 3.713 3.713 0 0 1 1.361 1.768c.277.676.44 1.392.486 2.118h3.314a8.843 8.843 0 0 0-.952-3.656 6.614 6.614 0 0 0-2.6-2.706A7.286 7.286 0 0 0 7.196.08a7.41 7.41 0 0 0-3.696.937Z"
            fill="#2F2F33"
        />
        <path
            d="M60.017 15.005a6.614 6.614 0 0 0 2.6-2.705 8.842 8.842 0 0 0 .934-3.338h-3.33a6.811 6.811 0 0 1-.451 1.764 3.767 3.767 0 0 1-1.362 1.768 3.644 3.644 0 0 1-2.07.584 3.732 3.732 0 0 1-2.068-.584 3.767 3.767 0 0 1-1.362-1.768 7.07 7.07 0 0 1-.435-1.764h-3.306c.107 1.153.409 2.281.894 3.338a6.684 6.684 0 0 0 2.582 2.705 7.41 7.41 0 0 0 3.696.938 7.286 7.286 0 0 0 3.678-.938Z"
            fill="#2F2F33"
        />
        <path
            d="M52.643 1.017a6.684 6.684 0 0 0-2.582 2.706 10.275 10.275 0 0 0-.918 3.656h3.296c.04-.743.199-1.475.47-2.17a3.537 3.537 0 0 1 3.43-2.282 3.626 3.626 0 0 1 2.07.566 3.714 3.714 0 0 1 1.36 1.768c.277.676.44 1.392.486 2.118h3.315a8.843 8.843 0 0 0-.953-3.656 6.614 6.614 0 0 0-2.6-2.706A7.287 7.287 0 0 0 56.34.08a7.41 7.41 0 0 0-3.696.937Z"
            fill="#2F2F33"
        />
        <path
            d="M25.041 4.73a.16.16 0 0 1-.159-.14 1.876 1.876 0 0 0-.76-1.274 3.077 3.077 0 0 0-1.769-.495 3.696 3.696 0 0 0-1.344.212c-.334.125-.627.34-.849.619-.19.255-.289.566-.282.884-.018.254.044.508.176.725.13.219.312.401.53.53.241.165.503.296.779.39.312.11.631.198.955.265l1.38.336c.605.15 1.197.35 1.768.601.535.235 1.03.551 1.468.938.405.358.73.798.955 1.29.25.556.371 1.16.353 1.77a4.191 4.191 0 0 1-.725 2.44 4.511 4.511 0 0 1-2.051 1.609 8.01 8.01 0 0 1-3.219.566 8.363 8.363 0 0 1-3.254-.566 4.934 4.934 0 0 1-2.157-1.768 5.005 5.005 0 0 1-.796-2.795h3.148a2.229 2.229 0 0 0 1.52 2.07c.48.184.99.274 1.504.265.494.006.986-.078 1.45-.248.372-.137.7-.368.955-.672.218-.284.336-.632.336-.99a1.256 1.256 0 0 0-.318-.867 2.105 2.105 0 0 0-.885-.601 8.56 8.56 0 0 0-1.432-.46l-1.769-.424a7.075 7.075 0 0 1-3.077-1.486 3.36 3.36 0 0 1-1.114-2.706 3.926 3.926 0 0 1 .743-2.458A5.093 5.093 0 0 1 19.188.663a7.074 7.074 0 0 1 3.006-.601 6.915 6.915 0 0 1 3.007.601c.804.346 1.496.91 1.998 1.627a4.35 4.35 0 0 1 .743 2.423l-2.9.018Z"
            fill="#2F2F33"
        />
        <path
            d="M30.505.292h3.732a.177.177 0 0 1 .159.106l4.209 10.275h.177L42.99.398a.194.194 0 0 1 .159-.106h3.731a.177.177 0 0 1 .177.177v15.103a.177.177 0 0 1-.177.177h-2.812a.195.195 0 0 1-.177-.177V5.704h-.123l-3.944 9.868a.194.194 0 0 1-.177.123h-1.91a.177.177 0 0 1-.16-.123l-3.96-9.886h-.124v9.886a.177.177 0 0 1-.177.177h-2.812a.195.195 0 0 1-.177-.177V.487a.194.194 0 0 1 .177-.195Z"
            fill="#2F2F33"
        />
        <path
            d="M71.852 15.572V.486a.194.194 0 0 1 .176-.176h2.918a.195.195 0 0 1 .177.176v12.38a.177.177 0 0 0 .177.177h6.331a.177.177 0 0 1 .177.177v2.351a.194.194 0 0 1-.177.177H72.1a.196.196 0 0 1-.224-.093.195.195 0 0 1-.023-.083Z"
            fill="#2F2F33"
        />
        <path
            d="M83.79 15.572V.486a.194.194 0 0 1 .176-.176h10.045a.177.177 0 0 1 .177.176v2.335a.16.16 0 0 1-.177.177h-6.773a.177.177 0 0 0-.177.176V6.5a.16.16 0 0 0 .177.177h6.242a.177.177 0 0 1 .177.177v2.334a.16.16 0 0 1-.177.177h-6.242a.177.177 0 0 0-.177.177v3.342a.176.176 0 0 0 .177.177h6.773a.158.158 0 0 1 .169.106.16.16 0 0 1 .008.071v2.335a.177.177 0 0 1-.177.176H83.93a.195.195 0 0 1-.142-.176Z"
            fill="#2F2F33"
        />
        <path
            d="M109.681 7.472v1.945a6.682 6.682 0 0 1-.867 3.537 5.818 5.818 0 0 1-2.352 2.228 7.322 7.322 0 0 1-3.537.796 7.444 7.444 0 0 1-3.802-.955 6.738 6.738 0 0 1-2.565-2.741 9.18 9.18 0 0 1-.92-4.209 9.922 9.922 0 0 1 .55-3.36 7.41 7.41 0 0 1 1.538-2.494 6.384 6.384 0 0 1 2.299-1.556 7.495 7.495 0 0 1 2.847-.548c.829 0 1.653.13 2.441.389a6.603 6.603 0 0 1 1.998 1.079 5.799 5.799 0 0 1 1.432 1.644c.377.641.624 1.35.725 2.087h-3.254a3.522 3.522 0 0 0-.442-.973 3.366 3.366 0 0 0-.689-.725 3.276 3.276 0 0 0-.938-.442 4.046 4.046 0 0 0-1.167-.159 3.732 3.732 0 0 0-2.069.584 3.82 3.82 0 0 0-1.397 1.768 7.834 7.834 0 0 0 0 5.482 3.873 3.873 0 0 0 1.379 1.769c.63.412 1.37.621 2.123.601a4.103 4.103 0 0 0 1.892-.407 2.756 2.756 0 0 0 1.22-1.114 3.437 3.437 0 0 0 .407-1.609h-3.307V7.631l6.455-.16Z"
            fill="#2F2F33"
        />
        <path
            d="M119.248.416a.176.176 0 0 0-.061-.087.176.176 0 0 0-.099-.037h-3.961a.179.179 0 0 0-.159.124L109.663 15.5a.171.171 0 0 0-.006.084.182.182 0 0 0 .034.077c.017.023.04.04.066.052a.176.176 0 0 0 .082.017h3.113a.18.18 0 0 0 .177-.124l1.114-3.396h5.553l1.114 3.396a.18.18 0 0 0 .06.087.179.179 0 0 0 .099.037h3.13a.176.176 0 0 0 .136-.076.18.18 0 0 0 .024-.154L119.248.416Zm-4.015 9.249 1.91-5.836h.106l1.91 5.836h-3.926Z"
            fill="#2F2F33"
        />
        <path
            d="M126.586 15.571V.486a.196.196 0 0 1 .056-.12.196.196 0 0 1 .121-.057h2.918a.194.194 0 0 1 .12.057.192.192 0 0 1 .057.12v12.38a.177.177 0 0 0 .176.177h6.261a.176.176 0 0 1 .177.177v2.351a.196.196 0 0 1-.056.121.198.198 0 0 1-.121.056h-9.532a.196.196 0 0 1-.177-.177Z"
            fill="#2F2F33"
        />
    </svg>
</template>
