<template>
    <div class="wrapper survey">
        <SurveyTop
            :back-url="`/${router.currentRoute.value.params.projectSlug}/briefs/${afterBriefStore.briefId}/${afterBriefStore.lastQuestionId}/`"
        />

        <h1 class="survey-title">{{ currentAction?.title || 'Регистрация по СМС коду' }}</h1>

        <div v-if="loading" class="brief-complete-loader-block">
            <v-loader class="brief-loader"></v-loader>
        </div>
        <template v-else>
            <template v-if="!phoneValidated">
                <PhoneSmsValidationForm
                    :phone="phone"
                    @phone-change="onPhoneChange"
                    @sms-success="onSmsSent"
                    send-code-endpoint="/register"
                    :send-code-data="codeEndpointData"
                    validate-code-endpoint="/validate-phone"
                    :validate-code-data="{}"
                    :digits="5"
                    @success="onPhoneSmsValidationSuccess"
                    @redirect-to-login="completeAction"
                    :next-action="nextAction"
                />
                <div v-if="registerErrorMessage" class="register-after-brief-error-message">
                    {{ registerErrorMessage }}
                </div>
            </template>
            <SurveyCompleteBottom
                v-else
                :next-action-type="actionsStore.pendingActions[1]?.type"
                @action-complete="completeAction"
            />
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import PhoneSmsValidationForm from '../components/PhoneSmsValidation/PhoneSmsValidationForm.vue';
import Loader from '../components/Loader/Loader.vue';
import SurveyTop from '../components/survey/SurveyTop/SurveyTop.vue';
import SurveyCompleteBottom from '../components/survey/SurveyCompleteBottom/SurveyCompleteBottom.vue';
import ArrIcon from '../svg/Arr.vue';
import { useAfterBriefStore } from '../stores/after-brief';
import { useUserStore } from '../stores/user';
import { useAfterBriefActionsStore } from '../stores/after-brief-actions';
import { usePageClass } from '../hooks/usePageClass';

export default defineComponent({
    components: { SurveyTop, SurveyCompleteBottom, ArrIcon, PhoneSmsValidationForm, 'v-loader': Loader },

    setup() {
        usePageClass({ 'hidden-header': true, 'register-after-brief-page': true });
        const router = useRouter();
        const phone = ref('');
        const phoneValidated = ref(false);
        const loading = ref(false);
        const registerErrorMessage = ref('');
        const codeEndpointData = ref({});
        const userStore = useUserStore();
        const afterBrief = useAfterBriefStore();
        const afterBriefActions = useAfterBriefActionsStore();
        // afterBriefActions.resetActionsFromType('register');
        const currentAction = afterBriefActions.pendingActions[0];
        const nextAction = afterBriefActions?.pendingActions[1];

        function onPhoneChange(phoneNumber: string) {
            phone.value = phoneNumber;
        }

        function onSmsSent(data: any) {
            userStore._setUserState({ token: data.token, user: data.user });
        }

        function onPhoneSmsValidationSuccess() {
            userStore.user.confirmed = true;
            phoneValidated.value = true;
        }

        watch(phoneValidated, (val) => {
            if (val) {
                setTimeout(() => {
                    const nextLink = document.querySelector<HTMLElement>('.js-brief-complete-next-link');
                    nextLink?.click();
                }, 50);
            }
        });

        function completeAction() {
            currentAction.completed = true;
        }

        return {
            phoneValidated,
            onPhoneSmsValidationSuccess,
            codeEndpointData,
            loading,
            afterBriefStore: afterBrief,
            actionsStore: afterBriefActions,
            completeAction,
            router,
            currentAction,
            nextAction,
            registerErrorMessage,
            phone,
            onPhoneChange,
            onSmsSent,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

html.register-after-brief-page {
    .survey {
        padding-bottom: 60px;
    }
}

.brief-complete-loader-block {
    display: flex;
}

.register-after-brief-error-message {
    color: $danger;
    max-width: 700px;
    text-wrap: balance;
    margin-top: 40px;
}
</style>
