import { defineStore } from 'pinia';

export const useAfterBriefActionsStore = defineStore('afterBriefActions', {
    state: () => ({ actions: [] }),
    getters: {
        pendingActions: (state) => state.actions.filter((action) => !action.completed),
        completedActions: (state) => state.actions.filter((action) => action.completed),
    },
    persist: true,
    actions: {
        resetActionsFromType(type: string) {
            const action = this.actions.find((action) => action.type === type);

            if (action) {
                const newActions = this.actions.slice();
                const index = newActions.indexOf(action);

                for (let i = index; i < newActions.length; i += 1) {
                    newActions[i].completed = false;
                }

                this.actions = newActions;
            }
        },
    },
});
