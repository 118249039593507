import { defineStore } from 'pinia';
import { BaseResponse, Project } from 'types';
import { axiosAPI } from '../../../axios';
import { store } from '../store';

export const useProjectsStore = defineStore<
    'projects',
    {
        projects: Project[];
        fetchProjectsPromise: Promise<any> | null;
        isFetching: boolean;
    },
    {},
    {
        fetchProjects(): Promise<void>;
    }
>('projects', {
    state: () => ({ projects: [], fetchProjectsPromise: null, isFetching: false }),
    actions: {
        async fetchProjects() {
            this.isFetching = true;

            try {
                this.fetchProjectsPromise = axiosAPI.get('/projects');
                const { data } = await this.fetchProjectsPromise;
                this.projects = data.data.projects;
            } finally {
                this.isFetching = false;
                this.fetchProjectsPromise = null;
            }
        },
    },
});
