import * as Cookies from 'js-cookie';
import { AutocompleteInput } from './custom-elements/AutocompleteInput/AutocompleteInput';
import { Collapse } from './custom-elements/Collapse/Collapse';

export function registerCustomElements() {
    window.customElements.define('x-autocomplete-input', AutocompleteInput);
    window.customElements.define('x-collapse', Collapse);

    if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
        import(/* webpackChunkName: "CookiesAgreement" */ './custom-elements/CookiesAgreement/CookiesAgreement').then(
            ({ CookiesAgreement }) => {
                window.customElements.define('x-cookies-agreement', CookiesAgreement);
            },
        );
    }
}
