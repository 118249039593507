<template>
    <PersonalFormFieldset title="Платежи">
        <ul class="list-unstyled">
            <TransactionsBlockListItems
                v-for="project in projectsStore.projects"
                :key="project.id"
                :project-id="project.id"
            />
        </ul>
        <router-link
            v-if="userStore.user && !isUserSubscribedToCurrentProject && router.currentRoute.value.params.projectSlug"
            :to="`/${router.currentRoute.value.params.projectSlug}/choose-tariff`"
            class="btn btn-primary personal-page-pay-btn"
            >Оплатить</router-link
        >
    </PersonalFormFieldset>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import PersonalFormFieldset from './PersonalFormFieldset.vue';
import { useUserStore } from '../../stores/user';
import { useProjectsStore } from '../../stores/projects';
import TransactionsBlockListItems from './TransactionsBlockListItems.vue';
import { getProjectPropBy } from '../../../../utils/projects';

const userStore = useUserStore();
const projectsStore = useProjectsStore();
const router = useRouter();

const projectId = getProjectPropBy('slug', projectsStore.projects, router.currentRoute.value.params.projectSlug, 'id');

const isUserSubscribedToCurrentProject = computed(() =>
    userStore.user ? userStore.user.subscribed_projects.includes(projectId) : false,
);
</script>
