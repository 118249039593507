import Axios from 'axios';
// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const axiosAPI = Axios.create({
    baseURL: '/api',
    headers: { 'Cache-Control': 'no-cache' },
    // adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(Axios.defaults.adapter!)),
    withCredentials: true,
});
