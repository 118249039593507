<template>
    <div class="datepicker" :class="{ 'is-error': isError, 'is-opened': isOpened }">
        <div class="input-group">
            <input
                ref="input"
                type="text"
                class="form-control datepicker__form-control"
                v-bind="attrs"
                :value="modelValue"
                autocomplete="off"
            />
            <div class="input-icon">
                <icon-calendar></icon-calendar>
            </div>
        </div>
        <div class="datepicker__bg"></div>
    </div>
</template>

<script lang="ts">
import Litepicker from 'litepicker';
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { withLeadingZero } from '../../../../utils/string';
import CalendarIcon from '../../svg/Calendar.vue';

export default defineComponent({
    components: {
        'icon-calendar': CalendarIcon,
    },

    props: {
        modelValue: {
            type: String,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },

    emits: ['select', 'update', 'update:modelValue'],

    setup({ options }, { attrs, emit }) {
        const input = ref<HTMLInputElement>();
        const picker = ref<Litepicker>();
        const isOpened = ref(false);

        function onPickerShow() {
            isOpened.value = true;
        }

        function onPickerHide() {
            isOpened.value = false;
        }

        onMounted(() => {
            picker.value = new Litepicker({
                element: input.value,
                lang: 'ru-RU',
                format: 'DD.MM.YYYY',
                bookedDaysFormat: 'DD.MM.YYYY',
                numberOfMonths: 1,
                numberOfColumns: 1,
                autoRefresh: true,
                showTooltip: false,
                ...options,
                setup: (picker) => {
                    picker.on('show', onPickerShow);
                    picker.on('hide', onPickerHide);
                    picker.on('selected', (date1: Date, date2: Date) => {
                        emit('select', [date1, date2]);
                        emit(
                            'update',
                            `${withLeadingZero(date1.getDate())}.${withLeadingZero(
                                date1.getMonth() + 1,
                            )}.${date1.getFullYear()}`,
                        );
                        emit(
                            'update:modelValue',
                            `${withLeadingZero(date1.getDate())}.${withLeadingZero(
                                date1.getMonth() + 1,
                            )}.${date1.getFullYear()}`,
                        );
                    });
                },
            });
        });

        onUnmounted(() => {
            picker.value?.destroy();
        });

        return { attrs, input, isOpened };
    },
});
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

:root {
    --litepicker-margin: 1px;
    --litepicker-day-color-hover: #{$primary};
    --litepicker-day-width: 35px;
    --litepicker-month-width: calc((var(--litepicker-day-width) + var(--litepicker-margin) * 4) * 7);
    --litepicker-is-today-color: #{$primary};
    --litepicker-is-today-hover: #{$primary};
    --litepicker-is-in-range-color: #{rgba($body-color, 0.1)};
    --litepicker-is-start-color: $body-color;
    --litepicker-is-start-color-bg: #{rgba($body-color, 0.1)};
    --litepicker-is-end-color: $body-color;
    --litepicker-is-end-color-bg: #{rgba($body-color, 0.1)};
}

.litepicker {
    .container__main {
        @media screen and (max-width: 767px) {
            position: fixed;
            top: auto;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .button-prev-month,
    .button-next-month {
        cursor: pointer;
    }
}

.datepicker {
    &.is-opened {
        .datepicker__bg {
            opacity: 0.3;
            visibility: visible;
        }
    }
}

.datepicker__bg {
    display: none;

    @media screen and (max-width: 767px) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $body-color;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
    }
}

.datepicker__form-control {
    padding-right: 16px + 29px;
}

.litepicker .container__months {
    border-radius: 20px;
    box-shadow: 0 0 2px var(--litepicker-container-months-box-shadow-color);

    @media screen and (max-width: 767px) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.litepicker .container__months .month-item-header div > .month-item-name {
    font-size: 20px;
    font-weight: 600;
}

.litepicker .container__months .month-item-header div > .month-item-year {
    @include visually-hidden();
}

.litepicker .container__months .month-item-header {
    padding-bottom: 25px;
}

.litepicker .container__months .month-item-weekdays-row > div {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.02em;
    opacity: 0.4;
    margin: calc(var(--litepicker-margin) * 2);
    flex: 0 0 auto;
}

.litepicker .container__days > div {
    margin: calc(var(--litepicker-margin) * 2);
}

.litepicker .container__days .day-item {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.02em;
    border-radius: 6px;
}

.litepicker .container__days .day-item.is-in-range {
    // color: #fff;
    border-radius: 6px;
}

.litepicker .container__days .day-item.is-start-date,
.litepicker .container__days .day-item.is-end-date {
    border-radius: 6px;
}
</style>
