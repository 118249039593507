import { createWebHistory, createRouter } from 'vue-router';
import NotFoundPage from './pages/NotFoundPage.vue';
import ForbiddenPage from './pages/ForbiddenPage.vue';
import RegisterAfterBriefPage from './pages/RegisterAfterBriefPage.vue';
import BriefPage from './pages/BriefPage.vue';
import BriefCompletePage from './pages/BriefCompletePage.vue';
import BriefCompleteCombinedPage from './pages/BriefCompleteCombinedPage.vue';
import ChooseTariffPage from './pages/ChooseTariffPage.vue';
// import PaymentPage from './pages/PaymentPage.vue';
import PaymentCompletePage from './pages/PaymentCompletePage.vue';
import TasksPage from './pages/TasksPage.vue';
import LoginPage from './pages/LoginPage.vue';
import HelpPage from './pages/HelpPage.vue';
import ForgotPasswordPage from './pages/ForgotPasswordPage.vue';
import PersonalPage from './pages/PersonalPage.vue';
import TextPage from './pages/TextPage.vue';
import PolicyPage from './pages/PolicyPage.vue';
import LicensePage from './pages/LicensePage.vue';
import TariffsTextPage from './pages/TariffsTextPage.vue';
import SearchPage from './pages/SearchPage.vue';
import EntryPage from './pages/EntryPage.vue';
import { store } from './store';
import { useUserStore } from './stores/user';
import { useProjectsStore } from './stores/projects';

export const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 };
    },
    routes: [
        {
            name: 'index',
            path: '/',
            component: EntryPage,
        },

        {
            name: 'login',
            path: '/login',
            component: LoginPage,
            meta: {
                guest: true,
            },
        },
        {
            name: 'brief-question',
            path: '/:projectSlug/briefs/:briefId/:questionId',
            component: BriefPage,
        },
        {
            name: 'register-after-brief',
            path: '/:projectSlug/register-after-brief',
            component: RegisterAfterBriefPage,
        },
        {
            name: 'brief-complete',
            path: '/:projectSlug/brief-complete',
            component: BriefCompletePage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: 'brief-complete-combined',
            path: '/:projectSlug/brief-complete-combined',
            component: BriefCompleteCombinedPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: 'choose-tariff',
            path: '/:projectSlug/choose-tariff',
            component: ChooseTariffPage,
            meta: {
                requiresAuth: true,
            },
        },
        // {
        //     path: '/payment',
        //     component: PaymentPage,
        //     meta: {
        //         requiresAuth: true,
        //     },
        // },
        {
            name: 'help',
            path: '/help',
            component: HelpPage,
            meta: {
                requiresAuth: true,
            },
        },

        {
            name: 'forgot-password',
            path: '/forgot-password',
            component: ForgotPasswordPage,
        },
        {
            name: 'personal',
            path: '/personal',
            alias: '/:projectSlug/personal',
            component: PersonalPage,
            meta: {
                requiresAuth: true,
                requiresConfirmed: true,
            },
        },
        { name: 'text', path: '/text', component: TextPage },
        { name: 'policy', path: '/policy', component: PolicyPage },
        { name: 'license', path: '/license', component: LicensePage },
        { name: 'tariffs', path: '/tariffs-list', component: TariffsTextPage },
        { name: 'payment-complete', path: '/:projectSlug/payment/complete', component: PaymentCompletePage },
        {
            name: 'tasks',
            path: '/:projectSlug/tasks',
            component: TasksPage,
            meta: {
                requiresAuth: true,
                requiresConfirmed: true,
            },
        },
        {
            name: 'search',
            path: '/search',
            component: SearchPage,
            meta: {
                requiresAuth: true,
            },
        },
        {
            name: 'forbidden',
            path: '/403',
            component: ForbiddenPage,
        },
        { name: 'not-found', path: '/:catchAll(.*)', component: NotFoundPage },
    ],
});

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();
    const projectsStore = useProjectsStore();
    const projectSlug = router.currentRoute.value.params.projectSlug;

    await Promise.all([userStore.fetchUserPromise, projectsStore.fetchProjectsPromise]);

    if (typeof to.params.projectSlug === 'string') {
        const exactProject = projectsStore.projects.find((project) => project.slug === to.params.projectSlug);
        if (!exactProject) {
            return { name: 'not-found' };
        }

        if (!exactProject.active) {
            return { name: 'forbidden' };
        }
    }

    if (to.meta.requiresConfirmed) {
        // Роут для подтвержденных пользователей
        if (userStore.user) {
            if (userStore.user.confirmed) {
                // Пользователь подтвержден
                return next();
            } else {
                // return { name: 'forbidden', replace: true };
                return { name: 'login', query: { redirect: to.fullPath } };
            }
        } else {
            return { name: 'login', query: { redirect: to.fullPath } };
        }
    } else if (to.meta.requiresAuth) {
        //  Роут для авторизованного пользователя
        if (!store.state.token) {
            // Пользователь не авторизован - редиректим на страницу логина
            return { name: 'login', query: { redirect: to.fullPath } };
        } else {
            // Пользователь авторизован - оставляем на этой странице
            return next();
        }
    } else if (to.meta.guest) {
        // Гостевой роут
        if (userStore.user?.confirmed) {
            // Пользователь авторизован - редиректим
            return to.query.redirect
                ? { path: to.query.redirect }
                : projectSlug
                ? { name: 'tasks', params: { projectSlug } }
                : { name: 'index' };
        } else if (!!store.state.token) {
            await userStore.logout();
            return next();
        } else {
            return next();
        }
    } else {
        // Нет ограничений
        return to.query.redirect ? { path: to.query.redirect } : next();
    }
});

export const YM_ID = 91986237;

router.afterEach(() => {
    if (process.env.NODE_ENV === 'production') {
        (window as any).ym?.(YM_ID, 'hit', window.location.pathname);
        // (window as any).dataLayer?.push({ event: 'virtualPageView' });

        if ((window as any).gtag) {
            (window as any).gtag('set', 'page_path', window.location.pathname);
            (window as any).gtag('event', 'page_view');
        }
    }
});
