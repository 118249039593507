<template>
    <div class="wrapper">
        <div class="text-wrapper wysiwyg">
            <h1>Политика Портала «osmolegal.ru» в отношении обработки персональной информации (персональных данных)</h1>

            <h2>
                Что регулирует настоящая политика в отношении обработки персональной информации (персональных данных).
            </h2>

            <p>
                Настоящая политика в отношении обработки персональной информации (персональных данных) (далее —
                Политика) действует в отношении всей информации, включая персональные данные в понимании применимого
                законодательства (далее —«Персональная информация»), которую портал «osmolegal.ru» и/или его
                аффилированные лица, в том числе входящие в одну группу с порталом «osmolegal.ru» (далее — «Портал»),
                могут получить о Вас в процессе использования Вами любых сайтов, программ, продуктов и/или сервисов
                Портала (далее вместе — «Сервисы»), информацию о которых Вы можете найти на сайте https://osmolegal.ru и
                других принадлежащих Порталу сайтах (далее вместе — «Сайты»), а также в ходе исполнения Порталом / его
                аффилированными лицами любых соглашений и договоров, заключенных с Вами в связи с использованием Вами
                Сервисов. Портал может также получать Персональную информацию от своих партнеров (далее — «Партнеры»),
                сайты, программы, продукты или сервисы которых Вы используете (например, от рекламодателей Портала). В
                таких случаях передача Персональной информации возможна только в случаях, установленных применимым
                законодательством, и осуществляется на основании специальных договоров между Порталом и каждым из
                Партнеров.
            </p>

            <p>
                Пожалуйста, обратите внимание, что использование любого из Сайтов и/или Сервисов может регулироваться
                дополнительными условиями, которые могут вносить в настоящую Политику изменения и/или дополнения, и/или
                иметь специальные условия в отношении персональной информации, размещенные в соответствующих разделах
                документов для таких Сайтов /или Сервисов.
            </p>

            <p>
                Политика распространяется на отношения в области обработки Персональной информации, возникшие у Портала
                как до, так и после утверждения Политики.
            </p>

            <h2>Кто обрабатывает информацию.</h2>

            <p>
                Для обеспечения использования Вами Сайтов и Сервисов Ваша Персональная информация собирается и
                используется Порталом, и/или его аффилированными лицами, предоставляющим соответствующий Сервис в иных
                юрисдикциях. С информацией о том, какое лицо предоставляет тот или иной Сервис, Вы можете ознакомиться в
                условиях использования соответствующего Сервиса.
            </p>

            <h2>Какова цель данной Политики</h2>

            <p>
                Защита Вашей Персональной информации и Вашей конфиденциальности чрезвычайно важны для Портала. Поэтому
                при использовании Вами Сайтов и Сервисов Портал защищает и обрабатывает Вашу Персональную информацию в
                строгом соответствии с применимым законодательством.
            </p>

            <p>
                Следуя нашим обязанностям защищать Вашу Персональную информацию, в этой Политике мы хотели бы наиболее
                прозрачно проинформировать Вас о следующих моментах:
            </p>

            <ul>
                <li>
                    зачем и как Портал собирает и использует («обрабатывает») Вашу Персональную информацию, когда Вы
                    используете Сайты и/или Сервисы;
                </li>
                <li>
                    какова роль и обязанности Портала как юридического лица, принимающего решение о том, зачем и как
                    обрабатывать Вашу Персональную информацию;
                </li>
                <li>
                    какие инструменты Вы можете использовать для сокращения объема собираемой Порталом Персональной
                    информации о Вас;
                </li>
                <li>каковы Ваши права в рамках проводимой обработки Персональной информации.</li>
            </ul>

            <h2>Какую Персональную информацию о Вас собирает Портал</h2>

            <p>
                Персональная информация, собранная в процессе работы Сайтов и/или предоставления Сервисов, может
                различаться в зависимости от того, используете Вы для доступа к Сайтам и/или Сервисам Вашу учетную
                запись (аккаунт, персональную страницу) или нет. В тех случаях, когда Вы осуществляете вход в свою
                учетную запись (аккаунт, персональную страницу), Персональная информация, собранная о Вас Порталом во
                время использования Вами Сайтов и Сервисов, может быть сопоставлена и связана с другой Персональной
                информацией, собранной Порталом в рамках использования Вами Вашей учетной записи (аккаунта, персональной
                страницы) (например, данные о Вашей личности, контактные данные, возраст и пол, если они были
                предоставлены Порталу). Портал не проверяет предоставленную Вами Персональную информацию за исключением
                случаев, предусмотренных лицензионным соглашением или условиями использования отдельных сервисов, и не
                может судить о ее достоверности, а также о том, обладаете ли вы достаточной правоспособностью для
                предоставления Вашей Персональной информации. Тем не менее, Портал сходит из того, что Вы предоставляете
                достоверную и достаточную Персональную информацию, а также своевременно обновляете ее.
            </p>

            <p>
                Портал может собирать следующие категории Персональной информации о Вас во время использования Вами
                Сайтов и Сервисов:
            </p>

            <ul>
                <li>
                    Персональная информация, предоставленная Вами при регистрации (создании учетной записи, аккаунта,
                    персональной страницы) на Сайтах, такая как Ваши фамилия, имя, отчество, адрес регистрации по месту
                    жительства, контактные данные (контактный телефон, адрес электронной почты), паспортные данные,
                    индивидуальный номер налогоплательщика (ИНН), страховой номер индивидуального лицевого счета
                    (СНИЛС), изображение (фотография), возраст;
                </li>
                <li>
                    дополнительная Персональная информация, предусмотренная формами для оформления онлайн-заказов,
                    заявок, а также обратной связи, размещенными на Сайтах;
                </li>
                <li>
                    информация о технических средствах (устройствах) и способах технологического взаимодействия с
                    Сайтами и/или Сервисами аффилированных лиц и/или партнёров (в т. ч. электронные данные, такие как
                    HTTP-заголовки, IP-адрес хоста, файлы cookie, веб-маяки/пиксельные теги, вид операционной системы,
                    тип браузера, данные об идентификаторе браузера, данные о провайдере, информация об аппаратном и
                    программном обеспечении и иное), а также иные данные, получаемые указанными способами;
                </li>
                <li>дата и время осуществления доступа к Сайтам и/или Сервисам;</li>
                <li>
                    информация о Вашей активности во время использования Сайтов и/или Сервисов (например, стадия
                    заполнения заявления в электронном документе; содержание прикладываемых к заявлению документов и
                    вложений, а также файлы, хранящиеся в системах Портала);
                </li>
                <li>информация о геолокации;</li>
                <li>
                    иная информация о Вас, необходимая для обработки в соответствии с условиями, регулирующими
                    использование конкретных Сайтов или Сервисов Портала;
                </li>
                <li>
                    информация о Вас, которую мы получаем от наших Партнеров в соответствии с условиями соглашений,
                    заключенных между Вами и соответствующим Партнером, и соглашений, заключенных между Порталом и
                    Партнером.
                </li>
            </ul>

            <p>
                Также Портал использует файлы cookie и веб-маяки (включая пиксельные теги) для сбора Персональной
                информации и связывания такой личной информации с Вашим устройством и веб-браузером (см. раздел 11
                настоящей Политики и Политику использования файлов cookie).
            </p>

            <p>
                Портал не собирает целенаправленно чувствительную персональную информацию (такую как расовое
                происхождение, национальная принадлежность, политические взгляды, религиозные или философские убеждения,
                информация о здоровье, интимной жизни и биометрические данные), за исключением случаев, предусмотренных
                пользовательскими соглашениями отдельных Сервисов. Тем не менее, Вы можете самостоятельно предоставить
                их Порталу, и в таком случае Портал будет обрабатывать их в рамках предоставления Вам Сервисов. При этом
                Вы должны принимать во внимание, что Портал не может запросить Ваше согласие на такую обработку,
                поскольку не осведомлен заранее о потенциально чувствительном характере Персональной информации, которую
                Вы можете предоставить Порталу.
            </p>

            <p>
                Портал не собирает данные с целью составления «портрета» пользователя в той степени, при которой это
                может существенно повлиять на Ваши права и свободы в соответствии с применимым законодательством.
            </p>

            <h2>Какова правовая основа и цели обработки Вашей Персональной информации</h2>

            <p>
                Портал не вправе обрабатывать Вашу Персональную информацию без достаточных на то правовых оснований.
                Поэтому Портал обрабатывает Вашу Персональную информацию только в том случае, если:
            </p>

            <ul>
                <li>
                    обработка необходима для выполнения договорных обязательств Портала перед Вами, включая обеспечение
                    работы Сайтов и Сервисов (например, подача процессуальных документов в суды РФ);
                </li>
                <li>обработка необходима для соблюдения установленных законодательством обязательств;</li>
                <li>
                    когда это предусмотрено применимым законодательством, обработка необходима для обеспечения законных
                    интересов Портала в случае, если такая обработка не оказывает существенного влияния на Ваши
                    интересы, Ваши фундаментальные права и свободы. Обратите внимание, что при обработке Вашей
                    персональной информации на указанном основании, Портал всегда будет стремиться поддерживать баланс
                    между своими законными интересами и защитой Вашей конфиденциальности.
                </li>
            </ul>

            <p>
                Портал обрабатывает Вашу Персональную информацию для обеспечения своих законных интересов, например, в
                следующих случаях:
            </p>

            <ul>
                <li>чтобы лучше понимать, как Вы взаимодействуете с нашими Сайтами и/или Сервисами;</li>
                <li>
                    чтобы совершенствовать, менять, персонализировать или иным образом улучшать Сайты и Сервисы в
                    интересах всех пользователей;
                </li>
                <li>
                    чтобы предлагать Вам другие продукты и сервисы Портала или других компаний, которые, по нашему
                    мнению, могут Вас заинтересовать (т. е. показывать Вам рекламу, учитывающую Ваши интересы); для
                    конкретных целей либо в соответствии с требованием применимого законодательства мы можем запросить
                    Ваше отдельное согласие на обработку Вашей Персональной информации. Для юрисдикций, где согласие
                    признается отдельным правовым основанием, начало использования вами Сайтов и/или Сервисов Портала
                    выражает Ваше согласие с такой обработкой. Настоящим мы информируем Вас, а Вы подтверждаете, что у
                    Вас нет никаких обязательств по предоставлению нам какой-либо Персональной информации при
                    использовании Вами Сайтов или Услуг и основано исключительно на Вашей свободной воле. Вместе с тем
                    Вы осознаете, что без предоставления Персональной информации мы не сможем предоставить Вам
                    возможность воспользоваться Сервисами, а использование Вами Сайтов будет ограничено.
                </li>
            </ul>

            <p>
                Портал всегда обрабатывает Вашу Персональную информацию в определенных целях и только ту Персональную
                информацию, которая имеет отношение к достижению таких целей. В частности, мы обрабатываем Вашу
                Персональную информацию для следующих целей:
            </p>

            <ul>
                <li>
                    предоставление Вам доступа к Сайтам и/или Сервисам (в т. ч. предоставление доступа к формам
                    заявлений в электронном виде, прилагаемым к заявлению документам, иной Персональной информации о
                    Вас, доступной Порталу); предоставление доступа к Вашей учетной записи (аккаунту, персональной
                    странице);
                </li>
                <li>
                    осуществление связи с Вами для направления Вам уведомлений, запросов и информации, относящейся к
                    работе Сайтов и Сервисов, выполнения соглашений с Вами и обработки Ваших запросов и заявок,
                    посредством направления почтовой корреспонденции на указанный адрес регистрации по месту жительства,
                    осуществления обращения по указанному контактному номеру телефона, осуществление отправки
                    SMS-сообщений, голосовых сообщений по указанному контактному номеру телефона; персонализация рекламы
                    и предложений с учетом Ваших предпочтений и другой Персональной информации о Вас, доступной Порталу;
                </li>
                <li>
                    повышение удобства использования Сайтов и Сервисов, в том числе для предоставления более
                    персонализированных Сайтов и Сервисов, а также для улучшения других продуктов, приложений и Сервисов
                    Портала; создание новых продуктов, утилит, проектов и предложений Портала, развитие,
                    совершенствование, оптимизация и внедрение нового функционала;
                </li>
                <li>защита Ваших прав и прав Портала;</li>
                <li>сбор, обработка и представление статистических данных, больших данных и других исследований.</li>
            </ul>

            <h2>Как Портал защищает Вашу Персональную информацию</h2>

            <p>
                В большинстве случаев Персональная информация обрабатывается автоматически без доступа к ней кого-либо
                из сотрудников Портала. В случае если такой доступ понадобится, то он может быть предоставлен только тем
                сотрудникам Портала, которые нуждаются в этом для выполнения своих задач. Для защиты и обеспечения
                конфиденциальности данных все сотрудники должны соблюдать внутренние правила и процедуры в отношении
                обработки Персональной информации. Они также должны следовать всем техническим и организационным мерам
                безопасности, действующим для защиты Вашей Персональной информации.
            </p>

            <p>
                Портал также внедрил достаточные технические и организационные меры для защиты Персональной информации
                от несанкционированного, случайного или незаконного уничтожения, потери, изменения, недобросовестного
                использования, раскрытия или доступа, а также иных незаконных форм обработки. Данные меры безопасности
                были реализованы с учетом современного уровня техники, стоимости их реализации, рисков, связанных с
                обработкой и характером Персональной информации.
            </p>

            <p>
                Эти меры могут включать способы контроля доступа или другие меры физической безопасности, технологии и
                политики информационной безопасности, процедуры, помогающие обеспечить надлежащее удаление информации, и
                программы обучения.
            </p>

            <p>
                Вот несколько примеров мер безопасности, которые мы используем для защиты Вашей Персональной информации:
            </p>

            <ul>
                <li>аутентификация по номеру телефона с одноразовым кодом;</li>
                <li>
                    пароль, необходимый для доступа к Вашей учетной записи (аккаунту, персональной странице).
                    Пожалуйста, держите этот пароль в секрете;
                </li>
                <li>
                    технология шифрования, называемая Secure Sockets Layer (SSL), помогает защитить Персональную
                    информацию в определенных областях наших Сайтов во время передачи через Интернет. На наличие
                    шифрования SSL может указывать https в URL браузера или изображение закрытого замка или сплошного
                    ключа в окне браузера. Эти указания могут отсутствовать в мобильных сервисах, использующих SSL.
                </li>
            </ul>

            <h2>Кто еще имеет доступ к Вашей Персональной информации и кому она может быть передана</h2>

            <h3>В рамках Группы Портала</h3>

            <p>
                Портал может передавать Вашу Персональную информацию своим сотрудникам (в пределах, указанных в разделе
                6 Политики). Портал также может передавать Вашу Персональную информацию своим аффилированным лицам, в
                том числе другим компаниям из группы лиц, к которой принадлежит Портал («Группа Портала»). Обратите
                внимание, что уровень защиты Персональной информации в некоторых странах может не совпадать с уровнем,
                установленном в Вашей юрисдикции, и, используя Сервисы Портала, Вы соглашаетесь на такую передачу.
            </p>
            <p>
                Во всех случаях Персональная информация также будет обрабатываться только для целей, изложенных в
                разделе 5 Политики, если иное не установлено условиями использования Сайтов и/или Сервисов,
                предоставляемых компаниями Группы Портала.
            </p>

            <h3>Вне Группы Портала</h3>

            <p>
                Портал также может передавать Персональную информацию третьим лицам, не входящим в Группу Портала, для
                достижения целей, указанных в разделе 5 Политики. Обратите внимание, что уровень защиты Персональной
                информации в некоторых странах может не совпадать с уровнем, установленном в Вашей юрисдикции, и,
                используя Сервисы Портала, Вы соглашаетесь на такую передачу.
            </p>

            <p>К таким третьим лицам могут относиться:</p>

            <ul>
                <li>
                    Партнеры, такие как владельцы сайтов и приложений, рекламные сети и другие партнеры, предоставляющие
                    Порталу услуги, связанные с размещением и отображением рекламы на сайтах, в программах, продуктах
                    или сервисах, которые принадлежат таким партнерам или контролируются ими;
                </li>
                <li>
                    рекламодатели или другие Партнеры, которые отображают для Вас рекламу на Сайтах и/или на Сервисах
                    Портала, а также такие Партнеры как поставщики информационных сервисов или консультанты.
                </li>
            </ul>

            <p>Портал также может передавать Персональную информацию третьим лицам, не входящим в Группу Портала:</p>

            <ul>
                <li>
                    третьим лицам, в отношении которых произведена уступка прав или обязанностей, или новация по
                    соответствующему соглашению;
                </li>
                <li>
                    любому национальному и/или международному регулирующему органу, правоохранительным органам,
                    центральным или местным исполнительным органам власти, другим официальным или государственным
                    органам или судам, в отношении которых Портал обязан предоставлять информацию в соответствии с
                    применимым законодательством по соответствующему запросу;
                </li>
                <li>
                    третьим лицам, в случае если Вы выразили согласие на передачу Вашей Персональной информации либо
                    передача Персональной информации требуется для предоставления Вам соответствующего Сервиса или
                    выполнения определенного соглашения или договора, заключенного с Вами;
                </li>
                <li>
                    любому третьему лицу в целях обеспечения правовой защиты Портала или третьих лиц при нарушении Вами
                    лицензионного соглашения ,настоящей Политики или условий, регулирующих использование отдельных
                    Сервисов, либо в ситуации, когда существует угроза такого нарушения.
                </li>
            </ul>

            <h2>Где хранится и обрабатывается Ваша Персональная информация</h2>

            <p>Ваша Персональная информация будет храниться в Российской Федерации.</p>

            <p>
                Для российских пользователей: Портал осуществляет запись, систематизацию, накопление, хранение,
                уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с
                использованием баз данных, находящихся на территории Российской Федерации.
            </p>

            <p>
                Для пользователей из ЕЭЗ, Швейцарии или Израиля: Россия является юрисдикцией за пределами Европейской
                экономической зоны, которая не была признана Европейской комиссией как обеспечивающая адекватный уровень
                защиты персональных данных. Поэтому Портал принял соответствующие меры для обеспечения того, чтобы такая
                передача осуществлялась в соответствии с действующими правилами защиты данных ЕС.
            </p>
            <p>
                В частности, Портал руководствуется Стандартными договорными условиями, утвержденными Европейской
                комиссией, для обеспечения адекватного уровня защиты Вашей Персональной информации, передаваемой в
                Россию. Копию этих Стандартных договорных условий можно получить по запросу.
            </p>
            <p>
                Если Вы находитесь на территории, где для передачи Вашей Личной информации в другую юрисдикцию требуется
                Ваше согласие, то используя Сайты или Сервисы, Вы даете Порталу свое явное и однозначное согласие на
                такую передачу или хранение, и/или обработку информации в других указанных юрисдикциях, включая Россию.
            </p>

            <h2>Как долго мы храним Вашу Персональную информацию</h2>

            <p>
                Портал будет хранить Вашу Персональную информацию столько времени, сколько это необходимо для достижения
                цели, для которой она была собрана, или для соблюдения требований законодательства и нормативных актов.
            </p>

            <p>
                Если иное не требуется по закону или соглашению с Вами, электронные письма и документы, которые Вы
                храните в системах Портала как часть Сервиса, будут храниться до тех пор, пока у Вас есть учетная запись
                (аккаунт, персональная страница), но они могут быть удалены Вами в любое время.
            </p>

            <p>
                Если Вы хотите, чтобы какая-либо Ваша Персональная информация была удалена из баз данных Портала, Вы
                можете самостоятельно удалить необходимую Персональную информацию с использованием Вашей учетной записи
                (аккаунта, персональной страницы) или через интерфейс Сайтов и/или Сервисов (где это применимо).
            </p>

            <h2>Ваши права</h2>

            <h3>Какими правами Вы обладаете</h3>

            <p>
                В случае если это предусмотрено применимым законодательством, Вы имеете право на доступ к Вашей
                Персональной информации, обрабатываемой Порталом в соответствии с настоящей Политикой.
            </p>

            <p>
                Если Вы считаете, что какая-либо информация, которую Портал хранит о Вас, некорректная или неполная, Вы
                можете войти в свою учетную запись и исправить Вашу Персональную информацию самостоятельно.
            </p>

            <p>Если это предусмотрено применяемым законодательством, Вы имеете право:</p>

            <ul>
                <li>
                    требовать удаления Вашей Персональной информации или ее части, а также отзывать согласие на
                    обработку Вашей персональной информации;
                </li>
                <li>требовать ограничений на обработку Вашей Персональной информации;</li>
                <li>
                    запросить копию Стандартных договорных условий, разрешающих передачу Вашей Персональной информации в
                    Россию и указанных в разделе 8 настоящей Политики;
                </li>
                <li>
                    возражать против обработки Вашей Персональной информации, если это предусмотрено применимым
                    законодательством.
                </li>
            </ul>

            <p>Портал будет выполнять указанные запросы в соответствии с применимым законодательством.</p>
            <p>
                В случаях, предусмотренных применимым законодательством, Вы можете также обладать другими правами, не
                указанными выше.
            </p>

            <h3>Как Вы можете реализовать свои права</h3>

            <p>
                Для осуществления вышеуказанных прав, пожалуйста, войдите в свою учетную запись (аккаунт, персональную
                страницу), а в случае отсутствия специальной функции в интерфейсе свяжитесь с Порталом (см. раздел 13
                настоящей Политики).
            </p>
            <p>
                Если Вы не удовлетворены тем, как Портал обрабатывает Вашу Персональную информацию, пожалуйста, сообщите
                нам, и мы рассмотрим Вашу претензию. Если Вы не удовлетворены ответом Портала, Вы имеете право подать
                жалобу в компетентный орган.
            </p>

            <h2>
                Как мы используем файлы cookie и другие подобные технологии на Сайтах или при использовании Вами
                Сервисов
            </h2>

            <h3>Что такое файлы cookie и для чего их использует Портал</h3>

            <p>
                Файлы cookie — это небольшие текстовые файлы, размещенные на устройстве, которое Вы используете для
                доступа к Сайтам. Они содержат информацию, которая собирается с Вашего устройства и отправляется обратно
                на Сайты при каждом последующем их посещении для того, чтобы помнить Ваши действия и предпочтения по
                истечении времени.
            </p>

            <p>На Сайтах используются следующие типы файлов cookie:</p>

            <ul>
                <li>
                    строго необходимые файлы cookie / технические файлы cookie: эти файлы cookie необходимы для работы
                    Сайтов и предоставления Вам Сервисов; кроме всего прочего, они позволяют Порталу идентифицировать
                    Ваше аппаратное и программное обеспечение, включая тип Вашего браузера;
                </li>
                <li>
                    статистические / аналитические файлы cookie: эти файлы cookie позволяют распознавать пользователей,
                    подсчитывать их количество и собирать информацию, такую как произведенные Вами операции на Сайтах и
                    в Сервисах, включая информацию о посещенных Вами веб-страницах и контенте, который Вы получаете;
                </li>
                <li>
                    технические файлы cookie: эти файлы cookie собирают информацию о том, как пользователи
                    взаимодействуют с Сайтами и/или Сервисами, что позволяет выявлять ошибки и тестировать новые функции
                    для повышения производительности Сайтов и Сервисов;
                </li>
                <li>
                    функциональные файлы cookie: эти файлы cookie позволяют предоставлять определенные функции, чтобы
                    облегчить использование Вами Сайтов, например, сохраняя Ваши предпочтения (такие как язык и
                    местоположение);
                </li>
                <li>
                    (сторонние) файлы отслеживания / рекламные файлы cookie: эти файлы cookie собирают информацию о
                    пользователях, источниках трафика, посещенных страницах и рекламе, отображенной для Вас, а также
                    той, по которой Вы перешли на рекламируемую страницу. Они позволяют отображать рекламу, которая
                    может Вас заинтересовать, на основе анализа Персональной информации, собранной о Вас. Они также
                    используются в статистических и исследовательских целях.
                </li>
            </ul>

            <h3>Как долго файлы cookie хранятся на Вашем устройстве</h3>

            <p>
                Портал использует информацию, содержащуюся в файлах cookie только в указанных выше целях, после чего
                собранные данные будут храниться на Вашем устройстве в течение периода, который может зависеть от
                соответствующего типа файлов cookie, но не превышая срока, необходимого для достижения их цели, после
                чего они будут автоматически удалены из Вашей системы.
            </p>

            <h3>Кто еще имеет доступ к информации, содержащейся в файлах cookie</h3>

            <p>
                Персональная информация, собранная с помощью файлов cookie, размещенных на Вашем устройстве, может быть
                передана и доступна Порталу и/или третьим лицам, указанным в разделе 7 настоящей Политики. Использование
                Персональной информации вне Сайтов в рекламных целях, если таковые имеются, может быть предметом
                отдельных пользовательских соглашений, доступных на веб-сайтах третьих лиц. Портал и/или третьи лица
                могут также предоставить Вам возможность отказаться от персонализации рекламы, которая может быть
                предметом регулирования законодательства и правил, применимых к таким продуктам и предложениям.
            </p>

            <p>
                При первом посещении Сайтов может быть запрошено Ваше согласие на использование файлов cookie. Если
                после того, как Вы одобрили использование файлов cookie, Вы захотите изменить свое решение, Вы сможете
                сделать это, удалив файлы cookie, хранящиеся в Вашем браузере (обычно это можно сделать в настройках
                браузера — пожалуйста, обратитесь к руководству по работе с браузером или сайту его разработчика). После
                этого может быть снова отображено всплывающее окно, запрашивающее Ваше согласие, и Вы сможете сделать
                иной выбор. Если Вы отказываетесь от использования файлов cookie, это может привести к тому, что
                некоторые функции Сайтов будут Вам недоступны, и повлияет на возможность использования Вами Cайтов. Вы
                также можете изменить настройки Вашего браузера, чтобы принимать или отклонять по умолчанию все файлы
                cookie или файлы cookie с определенных сайтов, включая Сайты Портала. Если Вы одобрили использование
                файлов cookie на одном из Сайтов Портала, мы будем считать, что использование файлов cookie на всех
                Cайтах было одобрено Вами.
            </p>

            <p>
                Портал также может использовать веб-маяки (пиксельные теги) для доступа к файлам cookie, ранее
                размещенным на Вашем устройстве, для следующих целей:
            </p>

            <ul>
                <li>
                    определение Ваших действий на Сайтах и в процессе использования Сервисов путем доступа и
                    использования файлов cookie, хранящихся на Вашем устройстве;
                </li>
                <li>
                    сбор статистической информации, связанной с работой Сайтов, Сервисов или продуктов, утилит и
                    предложений Портала.
                </li>
            </ul>

            <h2>Обновление настоящей Политики</h2>

            <p>
                В настоящую Политику могут быть внесены изменения. Портал имеет право вносить изменения по своему
                усмотрению, в том числе, но не ограничиваясь, в случаях, когда соответствующие изменения связаны с
                изменениями в применимом законодательстве, а также когда соответствующие изменения связаны с изменениями
                в работе Сайтов и Сервисов.
            </p>
            <p>
                Портал обязуется не вносить существенных изменений, не налагать дополнительных обременений или
                ограничений Ваших прав, установленных настоящей Политикой без Вашего уведомления. Вы будете уведомлены о
                таких изменениях. Соответствующие уведомления могут быть отображены на Сайте или в Сервисах (например,
                через всплывающее окно или баннер) до того, как такие изменения вступят в силу, или могут быть
                отправлены Вам по другим каналам связи (например, по электронной почте, если Вы предоставили нам Ваши
                контактные данные).
            </p>

            <h2>Вопросы и предложения</h2>

            <p>
                Портал приветствует Ваши вопросы и предложения, касающиеся исполнения или изменения настоящей Политики.
                Пожалуйста, воспользуйтесь нашей формой обратной связи, размещенной по адресу: https://osmolegal.ru/. Вы
                также можете воспользоваться этим адресом для направления запросов о реализации Ваших прав или жалоб
                относительно некорректности Вашей Персональной информации или незаконности ее обработки.
            </p>

            <p>
                Если Вы хотите знать, какую именно Вашу персональную информацию обрабатывает Портал, мы предоставим Вам
                эту информацию в установленный законодательством РФ срок.
            </p>

            <p>
                Для получения такой информации Вы можете обратиться к Порталу одним из следующих способов при условии
                выполнения Вами требований, предусмотренных ч. 3 ст. 14 Федерального закона РФ от 27.07.2006 № 152-ФЗ «О
                персональных данных»:
            </p>

            <ul>
                <li>
                    направив соответствующее заявление почтой по адресу: 660099, ООО «Синай», г. Красноярск, а/я 10175;
                </li>
                <li>
                    направив соответствующее заявление в форме электронного документа, подписанного электронной подписью
                    в соответствии с законодательством РФ, воспользовавшись формой обратной связи, размещенной по
                    адресу: https://osmolegal.ru/.
                </li>
            </ul>

            <p>При этом Ваш запрос должен содержать:</p>

            <ul>
                <li>
                    номер основного документа, удостоверяющего Вашу личность или личность Вашего представителя, сведения
                    о дате выдачи указанного документа и выдавшем его органе;
                </li>
                <li>
                    сведения, подтверждающие Ваше участие в отношениях с Порталом (номер договора, дата заключения
                    договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом
                    подтверждающие факт обработки Порталом Вашей Персональной информации;
                </li>
                <li>
                    Вашу подпись или подпись Вашего представителя (в случае электронного заявления – электронную
                    подпись).
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    setup() {
        return { PUBLIC_PATH: PUBLIC_PATH };
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';
</style>
