<template>
    <div class="loader"></div>
</template>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.loader {
    --size: 40px;
    --color: #{$primary};
    display: inline-block;
    width: var(--size);
    height: var(--size);
    color: inherit;
    vertical-align: middle;
    transform: rotateZ(45deg);
    perspective: 1000px;
    color: var(--color);

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        animation: 1s spin1 linear infinite;
    }

    &::before {
        transform: rotateX(70deg);
    }

    &::after {
        transform: rotateY(70deg);
        animation-delay: 0.4s;
    }
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spin1 {
    0%,
    100% {
        box-shadow: 0.2em 0px 0 0px currentColor;
    }

    12% {
        box-shadow: 0.2em 0.2em 0 0 currentColor;
    }

    25% {
        box-shadow: 0 0.2em 0 0px currentColor;
    }

    37% {
        box-shadow: -0.2em 0.2em 0 0 currentColor;
    }

    50% {
        box-shadow: -0.2em 0 0 0 currentColor;
    }

    62% {
        box-shadow: -0.2em -0.2em 0 0 currentColor;
    }

    75% {
        box-shadow: 0px -0.2em 0 0 currentColor;
    }

    87% {
        box-shadow: 0.2em -0.2em 0 0 currentColor;
    }
}
</style>
