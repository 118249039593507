<template>
    <ErrorPage :code="code" :title="title" :description="description" />
</template>

<script setup lang="ts">
import ErrorPage from '../layout/ErrorPage.vue';

const code = 404;
const title = 'Страница не найдена';
const description = 'К сожалению, данной страницы не существует';
</script>
