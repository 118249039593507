<template>
    <LoadingButton
        variant="primary"
        :disabled="paymentQuery.isFetching.value"
        :is-loading="paymentQuery.isFetching.value"
        @click="pay"
    >Оплатить</LoadingButton>
</template>

<script setup lang="ts">
import { useQuery } from 'vue-query';
import LoadingButton from '../LoadingButton/LoadingButton.vue';
import { axiosAPI } from '../../../../axios';

const props = defineProps({
    paymentType: String,
    projectId: Number,
    tariffId: String,
});

const paymentQuery = useQuery(
    ['payment', props.projectId, props.tariffId],
    () =>
        axiosAPI.get(
            `/projects/${props.projectId}/payment`,
            {
                params: {
                    uuid: props.tariffId,
                    type: props.paymentType,
                },
            },
        ),
    {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: false,
    },
);

async function pay() {
    const { data } = await paymentQuery.refetch.value();

    if (data.data.data.payment_link) {
        setTimeout(() => {
        window.open(data.data.data.payment_link, '_blank');
        }, 1);
    }
}
</script>