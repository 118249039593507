<template>
    <Clock :seconds="currentSeconds" />
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import Clock from '../Clock/Clock.vue';

const props = defineProps({
    countdownId: {
        type: String,
        required: true,
    },
    persistOnReload: {
        type: Boolean,
        default: false,
    },
    initialSeconds: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(['tick', 'complete']);
const storageCurrentSeconds = props.persistOnReload ? localStorage.getItem(props.countdownId) : null;
const parsedStorageCurrentSeconds = storageCurrentSeconds ? parseInt(storageCurrentSeconds) : 0;

const currentSeconds = ref(
    props.persistOnReload && parsedStorageCurrentSeconds > 0 ? parsedStorageCurrentSeconds : props.initialSeconds,
);
const interval = ref();

const setCurrentSecondsOnLocalStorage = () => {
    localStorage.setItem(props.countdownId, `${currentSeconds.value}`);
};

onMounted(() => {
    interval.value = setInterval(() => {
        currentSeconds.value -= 1;
        emit('tick', currentSeconds.value);

        if (currentSeconds.value === 0) {
            clearInterval(interval.value);
            localStorage.setItem(props.countdownId, `0`);
            emit('complete');
        }
    }, 1000);

    if (props.persistOnReload) {
        window.addEventListener('unload', setCurrentSecondsOnLocalStorage);
    }
});

onUnmounted(() => {
    clearInterval(interval.value);

    if (props.persistOnReload) {
        setCurrentSecondsOnLocalStorage();
        window.removeEventListener('unload', setCurrentSecondsOnLocalStorage);
    }
});
</script>
