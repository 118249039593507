<template>
    <Loader v-if="projectsStore.isFetching" class="tasks-loader" />
    <div v-else class="wrapper">
        <h1 class="page-title entry-page-title">Поиск по проектам</h1>
        <div class="help-section">
            <x-autocomplete-input
                ref="autocompleteInput"
                :endpoint="`/projects/search`"
                :data="query"
                @fetch-success="onSuggestionsFetchSuccess"
                @choice="onSuggestionChoice"
            >
                <SearchIcon class="autocomplete-input-icon" />
                <input
                    id="help-input"
                    type="text"
                    class="form-control autocomplete-input"
                    placeholder="Введите интересующий проект"
                    autocomplete="off"
                    @input="onAutocompleteInputChange"
                />
            </x-autocomplete-input>
        </div>
        <div class="help-section help">
            <div class="help__left">
                <div class="help-block">
                    <div class="help-block__title">Проекты</div>
                    <div class="help-block__content">
                        <ul v-if="projectsStore.projects.length > 0" class="list-unstyled help-block-list">
                            <li
                                v-for="project in projectsStore.projects"
                                :key="project.id"
                                class="help-block-list__item"
                            >
                                <router-link
                                    :to="`${project.url ? project.url : `/${project.slug}/tasks`}`"
                                    class="help-block-list__button"
                                    :class="{ 'help-block-list__button--disabled': !project.active }"
                                >
                                    <span class="help-block-list__button-text">{{ project.name }}</span>
                                    <ArrIcon class="help-block-list__button-icon" />
                                </router-link>
                            </li>
                        </ul>
                        <div v-else>Проекты не найдены</div>
                    </div>
                </div>
            </div>
            <div class="help__right">
                <!-- <div class="help-block">
                    <div class="help-block__title">Категории вопросов</div>
                    <div class="help-block__content">
                        <ul class="list-unstyled help-block-categories-list">
                            <li v-for="item in categories" :key="item.title" class="help-block-categories-list__item">
                                <button class="help-block-category">
                                    <span class="help-block-category__icon" v-html="item.icon"></span>
                                    <span class="help-block-category__text">{{ item.title }}</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <div v-if="jivoAPI" class="help-block">
                    <div class="help-block__title">Хотите связаться с нами?</div>
                    <div class="help-block__content">
                        <Button variant="light" class="btn-with-icon help-block__contact-btn" @click="openJivosite">
                            <span class="btn-icon" aria-hidden="true">
                                <MessageDotsIcon />
                            </span>
                            <span class="btn-text">Связаться</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import ArrIcon from '../svg/Arr.vue';
import SearchIcon from '../svg/Search.vue';
import Button from '../components/Button/Button.vue';
import Loader from '../components/Loader/Loader.vue';
import MessageDotsIcon from '../svg/MessageDots.vue';
import { useProjectsStore } from '../stores/projects';
import { useUserStore } from '../stores/user';
import { AutocompleteInput } from '../../../custom-elements/AutocompleteInput/AutocompleteInput';
import { axiosAPI } from '../../../axios';

const createTemplate = (str: string) => `{ "query": "${str}" }`;

const router = useRouter();
const autocompleteInput = ref<AutocompleteInput>();
const projectsStore = useProjectsStore();
const userStore = useUserStore();
const query = ref(createTemplate(''));
const firstBriefUrls = ref<string[]>([]);
const categories = ref([
    {
        title: 'Оплата',
        icon: `
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.8 8.8V3.667c0-.81-.657-1.467-1.467-1.467H2.2c-.81 0-1.467.657-1.467 1.467v14.667c0 .81.657 1.466 1.467 1.466h16.133c.81 0 1.467-.657 1.467-1.466V13.2m1.365-5.133h-7.232a2.933 2.933 0 000 5.867h7.232a.102.102 0 00.102-.102V8.169a.102.102 0 00-.102-.102z" stroke="#E85624"/>
            </svg>
        `,
    },
    {
        title: 'Задания',
        icon: `
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.978 9.49l.495.07-.495-.07zm-.015.102l-.495-.07.495.07zm10.06-.103l.494-.07-.495.07zm.014.103l-.495.071.495-.07zm-2.104 8.741v.5a.5.5 0 00.5-.5h-.5zm-5.866 0h-.5a.5.5 0 00.5.5v-.5zm-1.092-4.909l.395-.306-.395.306zm8.05 0l-.395-.306.395.306zM8.8 21.767h4.4v-1H8.8v1zM5.483 9.418l-.015.104.99.141.015-.103-.99-.142zM11 4.633a5.573 5.573 0 00-5.517 4.785l.99.142A4.573 4.573 0 0111 5.633v-1zm5.517 4.785A5.573 5.573 0 0011 4.633v1a4.573 4.573 0 014.527 3.927l.99-.142zm.015.104l-.015-.104-.99.142.015.103.99-.141zM15.42 13.73a5.562 5.562 0 001.11-4.208l-.989.141a4.562 4.562 0 01-.912 3.455l.79.612zm-1.988 2.259v2.344h1V15.99h-1zm.5 1.844H8.067v1h5.866v-1zm-5.366.5V15.99h-1v2.344h1zM5.468 9.522a5.562 5.562 0 001.111 4.208l.791-.612a4.562 4.562 0 01-.912-3.455l-.99-.141zm3.099 6.467c0-1.127-.6-2.1-1.197-2.87l-.79.611c.568.735.987 1.481.987 2.259h1zm6.063-2.87c-.597.77-1.197 1.743-1.197 2.87h1c0-.778.419-1.524.988-2.259l-.791-.612zM10.5 0v2.933h1V0h-1zM0 11.5h2.933v-1H0v1zm19.067 0H22v-1h-2.933v1zM4.754 5.513l-2.2-2.2-.708.707 2.2 2.2.708-.707zm13.2.707l2.2-2.2-.708-.707-2.2 2.2.708.707z" fill="#E85624"/>
            </svg>
        `,
    },
    {
        title: 'Технические вопросы',
        icon: `
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path clip-rule="evenodd" d="M8.718.733l-.126.64-.483 2.345a8.097 8.097 0 00-2.103 1.207l-2.358-.788-.634-.197-.328.566-1.624 2.715-.329.565.481.421L3.04 9.76c-.066.406-.151.808-.151 1.234 0 .427.085.829.151 1.234L1.214 13.78l-.48.42.328.566 1.624 2.715.328.567.634-.198 2.358-.789A8.097 8.097 0 008.11 18.27l.483 2.344.126.64h4.563l.127-.64.481-2.344a8.096 8.096 0 002.104-1.208l2.358.789.634.198.33-.567 1.622-2.715.33-.566-.483-.42-1.824-1.553c.067-.405.15-.807.15-1.234 0-.426-.083-.828-.15-1.234l1.824-1.552.483-.42-.33-.566-1.622-2.715-.33-.566-.634.197-2.358.788a8.096 8.096 0 00-2.104-1.207l-.48-2.344-.128-.64H8.718z" stroke="#E85624" stroke-linecap="square" stroke-linejoin="round"/>
                <path clip-rule="evenodd" d="M13.933 10.993a2.934 2.934 0 01-5.867 0 2.933 2.933 0 015.867 0z" stroke="#E85624" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
        `,
    },
    {
        title: 'Вопросы о сервисе',
        icon: `
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.133 16.856h.5v-.5h-.5v.5zm0 4.397h-.5a.5.5 0 00.8.4l-.3-.4zM11 16.856v-.5h-.166l-.134.1.3.4zM6.6 6.096h-.5v1h.5v-1zm8.8 1h.5v-1h-.5v1zm-8.8 3.397h-.5v1h.5v-1zm5.867 1h.5v-1h-.5v1zm-7.834 5.363v4.397h1v-4.397h-1zm.8 4.797l5.867-4.397-.6-.8-5.866 4.397.6.8zM11 17.356h8.8v-1H11v1zm8.8 0a1.965 1.965 0 001.967-1.966h-1a.965.965 0 01-.967.966v1zm1.967-1.966V2.2h-1v13.19h1zm0-13.19c0-1.088-.88-1.967-1.967-1.967v1c.535 0 .967.432.967.966h1zM19.8.232H2.2v1h17.6v-1zm-17.6 0A1.965 1.965 0 00.233 2.2h1c0-.534.432-.966.967-.966v-1zM.233 2.2V15.39h1V2.2h-1zm0 13.191c0 1.087.88 1.966 1.967 1.966v-1a.965.965 0 01-.967-.966h-1zM2.2 17.357h2.933v-1H2.2v1zm4.4-10.26h8.8v-1H6.6v1zm0 4.397h5.867v-1H6.6v1z" fill="#E85624"/>
            </svg>
        `,
    },
]);
const jivoAPI = ref<any>((window as any).jivo_api);

(window as any).jivo_onLoadCallback = () => {
    jivoAPI.value = (window as any).jivo_api;
};

function onAutocompleteInputChange(event: any) {
    query.value = createTemplate(event.target.value);
}

function openJivosite() {
    jivoAPI.value?.open?.();
}

function onSuggestionsFetchSuccess(event: any) {
    if (autocompleteInput.value) {
        autocompleteInput.value._suggestions = event.detail.projects.map((project, i) => {
            let brief_id = '';
            axiosAPI.get(`/projects/${project.id}/start`).then(({ data }) => {
                brief_id = data.data.brief_id;
            });

            const shouldRedirectToBrief =
                userStore.user && userStore.user.completed_briefs
                    ? !userStore.user.completed_briefs.includes(brief_id)
                    : false;
            const index = projectsStore.projects.findIndex((p) => project.id === p.id);

            return {
                value:
                    shouldRedirectToBrief && firstBriefUrls.value[index]
                        ? firstBriefUrls.value[index]
                        : `/${project.slug}/tasks`,
                text: project.name,
            };
        });
    }
}

function onSuggestionChoice(event: any) {
    router.push(event.detail.value);
}

Promise.all(
    projectsStore.projects.map((project, i) =>
        axiosAPI.get(`/projects/${project.id}/start`).then(({ data }) => {
            let briefId = data.data.brief_id;
            firstBriefUrls.value[i] = `/${project.slug}/briefs/${briefId}/${data.data.question_id}`;

            const shouldRedirectToBrief =
                userStore.user && userStore.user.completed_briefs
                    ? !userStore.user.completed_briefs.includes(briefId)
                    : false;
            const index = projectsStore.projects.findIndex((p) => project.id === p.id);
            project.url =
                shouldRedirectToBrief && firstBriefUrls.value[index]
                    ? firstBriefUrls.value[index]
                    : `/${project.slug}/tasks`;
        }),
    ),
);
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

.entry-page-title {
    text-transform: uppercase;
}
</style>
