<template>
    <div class="wrapper survey">
        <h1 class="forgot-password-title">Восстановить пароль</h1>

        <phone-sms-validation-form
            v-if="!afterValidatedText"
            send-code-endpoint="/forgot"
            :send-code-data="{}"
            :validate-code="false"
            @sms-success="onPhoneSmsValidationSuccess"
            :input-autofocus="true"
        ></phone-sms-validation-form>
        <div v-else>
            <div class="round-block">
                <p class="forgot-password-message">{{ afterValidatedText }}</p>
                <router-link to="/login" class="btn btn-primary">Войти</router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* global PUBLIC_PATH */
import { ref } from 'vue';
import PhoneSmsValidationForm from '../components/PhoneSmsValidation/PhoneSmsValidationForm.vue';
import IconArr from '../svg/Arr.vue';
import { usePageClass } from '../hooks/usePageClass';

export default {
    components: { IconArr, PhoneSmsValidationForm },

    setup() {
        const afterValidatedText = ref('');
        const phoneValidated = ref(false);
        usePageClass({ 'forgot-password-page': true });

        function onPhoneSmsValidationSuccess() {
            phoneValidated.value = true;
            afterValidatedText.value = 'На ваш номер телефона отправлен новый пароль';
        }

        return { PUBLIC_PATH: PUBLIC_PATH, phoneValidated, onPhoneSmsValidationSuccess, afterValidatedText };
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

html.forgot-password-page {
    .survey {
        padding-bottom: 65px;
    }
}

.forgot-password-title {
    margin-bottom: 48px;

    @media screen and (max-width: 767px) {
        margin-bottom: 30px;
    }
}

.forgot-password-message {
    margin-bottom: 30px;
}
</style>
