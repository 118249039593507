<template>
    <select ref="select" class="slim-select">
        <option v-if="placeholder" disabled value>{{ placeholder }}</option>
        <slot></slot>
    </select>
</template>

<script lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import SlimSelect from 'slim-select';

export default {
    props: {
        placeholder: {
            type: String,
        },
    },

    setup() {
        const select = ref<HTMLSelectElement>();
        const instance = ref<SlimSelect>();

        function onResize() {
            if (window.matchMedia('(min-width: 1025px)').matches) {
                if (!instance.value) {
                    instance.value = new SlimSelect({ select: select.value, showSearch: false });
                }
            } else if (instance.value) {
                instance.value.destroy();
                instance.value = null;
            }
        }

        onMounted(() => {
            onResize();
            window.addEventListener('resize', onResize);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', onResize);

            if (instance.value) {
                instance.value.destroy();
                instance.value = null;
            }
        });

        return { select };
    },
};
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

$ss-height: auto !default;
$ss-white: #fff !default;
$font-color: $body-color !default;
$font-placeholder-color: #8a8a8a !default;
$font-disabled-color: #dedede !default;
$primary-color: $primary !default;
$ss-border-color: transparent !default;
$search-highlight-color: #fffb8c !default;
$border-radius: 0 !default;
$ss-spacing-l: 8px !default;
$ss-spacing-m: 5px !default;
$ss-spacing-s: 4px !default;

.slim-select {
    display: flex;
    cursor: pointer;
    width: 100%;
    height: $ss-height;
    padding: $ss-spacing-m;
    border: 1px solid $ss-border-color;
    border-radius: $border-radius;
    background-color: transparent;
    outline: 0;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    padding: 0;
}

.ss-main {
    position: relative;
    /* display: inline-block; */
    user-select: none;
    /* color: $font-color;
    width: 100%; */

    .ss-single-selected {
        /* display: flex;
        cursor: pointer;
        width: 100%;
        height: $ss-height;
        padding: 0;
        line-height: 1.2;
        border: 1px solid $ss-border-color;
        border-radius: $border-radius;
        background-color: transparent;
        outline: 0;
        box-sizing: border-box; */
        @include form-control();
        width: 100%;
        border-radius: $border-radius;
        /* transition: background-color 0.3s ease; */
        position: relative;
        z-index: 2;

        &.ss-disabled {
            background-color: $ss-border-color;
            cursor: not-allowed;
        }

        &.ss-open-above {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
        &.ss-open-below {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .placeholder {
            display: flex;
            flex: 1 1 100%;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            width: calc(100% - 30px);
            line-height: 1.2;
            user-select: none;
            /* padding-bottom: 1px; */

            * {
                display: flex;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: auto;
            }

            .ss-disabled {
                color: $font-disabled-color;
            }
        }

        .ss-deselect {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 0 1 auto;
            margin: 0 $ss-spacing-m 0 $ss-spacing-m;
            font-weight: bold;

            &.ss-hide {
                display: none;
            }
        }

        .ss-arrow {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 0 1 auto;
            margin: 0 $ss-spacing-m 0 15px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-50%, -50%);

            span {
                display: inline-block;
                transition: transform 0.3s ease;
                width: 13px;
                height: 7px;
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.69.19L9 6.868 1.31.19C.947-.124.434-.04.163.379c-.271.419-.198 1.012.163 1.326L8.51 8.811a.73.73 0 00.982 0l8.182-7.106c.214-.186.327-.47.327-.758 0-.198-.053-.398-.164-.568-.27-.419-.784-.503-1.145-.19z' fill='%233E3E3E'/%3E%3C/svg%3E%0A");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

                &.arrow-up {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .ss-multi-selected {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        min-height: $ss-height;
        width: 100%;
        padding: 0 0 0 3px;
        border: 1px solid $ss-border-color;
        border-radius: $border-radius;
        background-color: $ss-white;
        outline: 0;
        box-sizing: border-box;
        transition: background-color 0.3s ease;

        &.ss-disabled {
            background-color: $ss-border-color;
            cursor: not-allowed;

            .ss-values {
                .ss-disabled {
                    color: $font-color;
                }

                .ss-value {
                    .ss-value-delete {
                        cursor: not-allowed;
                    }
                }
            }
        }

        &.ss-open-above {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
        &.ss-open-below {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .ss-values {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            flex: 1 1 100%;
            width: calc(100% - 30px);

            .ss-disabled {
                display: flex;
                padding: 4px 5px;
                margin: 2px 0px;
                line-height: 1em;
                align-items: center;
                width: 100%;
                color: $font-disabled-color;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            @keyframes scaleIn {
                0% {
                    transform: scale(0);
                    opacity: 0;
                }
                100% {
                    transform: scale(1);
                    opacity: 1;
                }
            }
            @keyframes scaleOut {
                0% {
                    transform: scale(1);
                    opacity: 1;
                }
                100% {
                    transform: scale(0);
                    opacity: 0;
                }
            }

            .ss-value {
                display: flex;
                user-select: none;
                align-items: center;
                font-size: 12px;
                padding: 3px 5px;
                margin: 3px 5px 3px 0px;
                color: $ss-white;
                background-color: $primary-color;
                border-radius: $border-radius;
                animation-name: scaleIn;
                animation-duration: 0.3s;
                animation-timing-function: ease-out;
                animation-fill-mode: both;

                &.ss-out {
                    animation-name: scaleOut;
                    animation-duration: 0.3s;
                    animation-timing-function: ease-out;
                }

                .ss-value-delete {
                    margin: 0 0 0 5px;
                    cursor: pointer;
                }
            }
        }

        .ss-add {
            display: flex;
            flex: 0 1 3px;
            margin: 9px 12px 0 5px;

            .ss-plus {
                display: flex;
                justify-content: center;
                align-items: center;
                background: $font-color;
                position: relative;
                height: 10px;
                width: 2px;
                transition: transform 0.3s;

                &:after {
                    background: $font-color;
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 10px;
                    left: -4px;
                    top: 4px;
                }

                &.ss-cross {
                    transform: rotate(45deg);
                }
            }
        }
    }
}
.ss-content {
    /* position: absolute;
    width: 100%;
    min-width: 232px;
    margin: -1px 0 0 0;
    box-sizing: border-box;
    border: solid 1px $ss-border-color;
    z-index: 1010;
    background-color: #fff;
    transform-origin: center top;
    transition: transform 0.3s easeOutCubic, opacity 0.3s easeOutCubic, visibility 0.3s easeOutCubic;
    transform: translate3d(0, -10px, 0);
    border-radius: 16px;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 450px;
    -webkit-overflow-scrolling: touch; */
    @include dropdown();
    opacity: 0;
    visibility: hidden;
    margin: 0 !important;

    &.ss-open {
        display: block;
        opacity: 1;
        visibility: visible;
        transform: none;
    }

    /* .ss-open-above ~ & {
        padding-top: 0;
        padding-bottom: 60px;
        transform: translate(0, 60px);
    } */

    .ss-search {
        display: flex;
        flex-direction: row;
        padding: $ss-spacing-l $ss-spacing-l $ss-spacing-m $ss-spacing-l;

        &.ss-hide {
            height: 0px;
            opacity: 0;
            padding: 0px 0px 0px 0px;
            margin: 0px 0px 0px 0px;

            input {
                height: 0px;
                opacity: 0;
                padding: 0px 0px 0px 0px;
                margin: 0px 0px 0px 0px;
            }
        }

        input {
            display: inline-flex;
            font-size: inherit;
            line-height: inherit;
            flex: 1 1 auto;
            width: 100%;
            min-width: 0px;
            height: 30px;
            padding: $ss-spacing-m $ss-spacing-l;
            margin: 0;
            border: 1px solid $ss-border-color;
            border-radius: $border-radius;
            background-color: $ss-white;
            outline: 0;
            text-align: left;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -webkit-appearance: textfield;

            &::placeholder {
                color: $font-placeholder-color;
                vertical-align: middle;
            }

            &:focus {
                box-shadow: 0 0 5px $primary-color;
            }
        }

        .ss-addable {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 22px;
            font-weight: bold;
            flex: 0 0 30px;
            height: 30px;
            margin: 0 0 0 8px;
            border: 1px solid $ss-border-color;
            border-radius: $border-radius;
            box-sizing: border-box;
        }
    }

    /* .ss-addable {
        padding-top: 0px;
    } */

    .ss-list {
        @include scrollbar();
        max-height: 200px;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;

        .ss-optgroup {
            .ss-optgroup-label {
                padding: 6px 10px 6px 10px;
                font-weight: bold;
            }

            .ss-option {
                padding: 6px 6px 6px 25px;
            }
        }

        .ss-optgroup-label-selectable {
            cursor: pointer;

            &:hover {
                color: currentColor;
                background-color: $primary-color;
            }
        }

        .ss-option {
            padding: 8px 20px;
            cursor: pointer;
            user-select: none;
            transition: color 0.2s ease, background-color 0.2s ease;

            * {
                display: inline-block;
            }

            &:hover,
            &.ss-highlighted {
                color: $primary-color;
                // background-color: $primary-color;
            }

            &.ss-disabled {
                cursor: not-allowed;
                color: $font-disabled-color;
                background-color: $ss-white;
            }

            &.ss-option-selected {
                display: none;
            }

            // &:not(.ss-disabled).ss-option-selected {
            //     color: $font-color;
            //     background-color: rgba($primary-color, 0.1);
            // }

            &.ss-hide {
                display: none;
            }

            .ss-search-highlight {
                background-color: $search-highlight-color;
            }
        }
    }
}
</style>
