<template>
    <div class="wrapper">
        <div class="text-wrapper wysiwyg">
            <h1>ТАРИФЫ ДЛЯ ИСПОЛЬЗОВАНИЯ ПОРТАЛА OSMOLEGAL.RU</h1>

            <h2>Проект №1 — БАКНРОТСТВО ФИЗИЧЕСКИХ ЛИЦ</h2>

            <p>
                Месячный тариф: 4490 рублей в месяц. Срок использования лицензии по указанному тарифу — 1 месяц. По
                истечении указанного срока для продолжения использования Проекта Лицензиату необходимо повторно
                совершить покупку Лицензии по условиям настоящих Тарифов.
            </p>

            <p>
                Годовой тариф: 39690 рублей в год. Срок использования лицензии по указанному тарифу — 1 год. По
                истечении указанного срока для продолжения использования Проекта Лицензиату необходимо повторно
                совершить покупку Лицензии по условиям настоящих Тарифов.
            </p>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    setup() {
        return { PUBLIC_PATH: PUBLIC_PATH };
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';
</style>
