<template>
    <div class="wrapper search-wrapper">
        <h1 class="search-page-title">Поиск</h1>

        <form @submit.prevent="onSubmit">
            <div class="search-input-wrapper">
                <FormInput id="search-input" :show-helping-message="false">
                    <input
                        type="text"
                        id="search-input"
                        class="form-control search-input"
                        autocomplete="off"
                        placeholder="Введите услугу"
                        v-model="query"
                    />
                    <template v-slot:icon>
                        <button
                            class="square-btn square-btn--lg square-btn-primary search-input__submit-btn"
                            aria-label="Submit"
                        >
                            <SearchIcon />
                        </button>
                    </template>
                </FormInput>
            </div>
        </form>

        <div class="search-content">
            <div v-if="tariffsQuery.isFetching.value" class="texted-loader search-loader">
                <Loader />
                <div class="texted-loader__value">Загружаем результаты...</div>
            </div>
            <div v-else-if="tariffsQuery.isError.value" class="texted-loader search-loader">
                <div class="texted-loader__value">Произошла ошибка при запросе</div>
            </div>
            <div v-else-if="tariffsQuery.data.value.length > 0">
                <div class="search-content__title">Результаты поиска</div>

                <ul class="list-unstyled search-results-list">
                    <li v-for="result in tariffsQuery.data.value" class="search-results-list__item">
                        <router-link :to="`/projects/${result.id}`" class="search-result">
                            <div class="search-result__left">
                                <div class="search-result__title">{{ result.title }}</div>
                                <div class="search-result__secondary">{{ result.secondary }}</div>
                            </div>
                            <div class="search-result__right">
                                <div
                                    class="square-btn square-btn--xl square-btn-primary search-result__icon"
                                    aria-label="Submit"
                                >
                                    <ArrIcon />
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div v-else class="search-content-tip">Введите поисковой запрос</div>
        </div>
    </div>
</template>

<script lang="ts">
/* global PUBLIC_PATH */
import { defineComponent, ref } from 'vue';
import { useQuery } from 'vue-query';
import FormInput from '../components/FormInput/FormInput.vue';
import SearchIcon from '../svg/Search.vue';
import ArrIcon from '../svg/Arr.vue';
import { axiosAPI } from 'axios';
import Loader from '../components/Loader/Loader.vue';

export default defineComponent({
    components: {
        SearchIcon,
        ArrIcon,
        FormInput,
        Loader,
    },

    setup() {
        const queryParams = new URLSearchParams(window.location.search);
        const query = ref(queryParams.get('q') || '');

        // const tariffsQuery = useQuery(['search-projects', query.value], () => axiosAPI.get('/projects', { params: { q: query.value } }), {
        //     refetchOnWindowFocus: false,
        //     refetchOnMount: false,
        // });
        const tariffsQuery = {
            isLoading: { value: false },
            isFetching: { value: false },
            isError: { value: false },
            data: {
                value: [
                    {
                        id: 1,
                        title: 'Банкротство',
                        secondary: 'Юридические услуги по банкротству физических лиц',
                    },
                    {
                        id: 2,
                        title: 'Возврат товара',
                        secondary: 'Юридические услуги по возврату товара',
                    },
                ],
            },
        };

        function onSubmit() {
            console.log(query.value);
        }

        return { PUBLIC_PATH: PUBLIC_PATH, tariffsQuery, query, onSubmit };
    },
});
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

.search-page-title {
    margin-bottom: 15px;
}

.search-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 70px + 30px;
}

.search-input-wrapper {
    .input-group-icon {
        right: 32px;
    }
}

.search-input {
    padding: 26px 32px;

    @media screen and (min-width: 1025px) {
        font-size: 17px;
    }

    @media screen and (max-width: 1024px) {
        border-radius: 16px;
        padding: 20px 16px;
    }
}

.search-input__submit-btn {
    @mixin hover() {
        background-color: #fff;
        color: $body-color;
    }

    &:focus-visible,
    &:active {
        @include hover();
    }

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        &:not(:disabled):hover {
            @include hover();
        }
    }
}

.search-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 55px;

    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
}

.search-content-tip {
    margin: auto;
    text-align: center;
    opacity: 0.5;
}

.search-content__title {
    margin-bottom: 21px;
    font-weight: 600;

    @media screen and (max-width: 1024px) {
        margin-bottom: 12px;
    }
}

.search-results-list__item {
    &:not(:last-child) {
        margin-bottom: 24px;
    }
}

.search-result {
    border-radius: 16px;
    padding: 32px;
    background-color: var(--elements-bg);
    display: flex;
    align-items: center;
    width: 100%;
    transition: background-color 0.2s ease, color 0.2s ease;

    @media screen and (max-width: 1024px) {
        padding: 24px 16px;
    }

    @mixin hover() {
        color: #fff;
        background-color: $primary;

        .search-result__icon {
            background-color: currentColor;

            path {
                stroke: $body-color;
            }
        }
    }

    &:focus-visible,
    &:active {
        @include hover();
    }

    @media (any-hover: hover), (hover: hover) and (pointer: fine) {
        &:not(:disabled):hover {
            @include hover();
        }
    }
}

.search-result__left {
    padding-right: 30px;
}

.search-result__right {
    margin-left: auto;
    flex-shrink: 0;
}

.search-result__title {
    @include responsive-font-size(24px, 32px, 1280px, 1920px);
    font-weight: 600;
}

.search-result__secondary {
    margin-top: 9px;
    font-weight: 500;
    opacity: 0.5;
}

.search-loader {
    margin: auto;
}
</style>
