import { withLeadingZero } from './string';

export function dateToString(date: Date) {
    if ('Intl' in window) {
        return new Intl.DateTimeFormat('ru-RU').format(date);
    }

    const dd = withLeadingZero(date.getDate());
    const mm = withLeadingZero(date.getMonth() + 1);
    const yy = date.getFullYear().toString().slice(2);
    return `${dd}.${mm}.${yy}`;
}
