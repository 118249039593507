<template>
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1617_1725)">
            <circle cx="20.5" cy="20.5" r="20.5" fill="white" />
            <path
                d="M20.5 41C9.17785 41 0 31.8221 0 20.5C0 9.17785 9.17785 0 20.5 0C31.8221 0 41 9.17785 41 20.5C41 31.8221 31.8221 41 20.5 41ZM18.45 26.65V30.75H22.55V26.65H18.45ZM18.45 10.25V22.55H22.55V10.25H18.45Z"
                fill="#E75525"
            />
        </g>
        <defs>
            <clipPath id="clip0_1617_1725">
                <rect width="41" height="41" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
