<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            clip-rule="evenodd"
            d="M12.6 4.198A3.599 3.599 0 019 7.795 3.599 3.599 0 119 .6c1.989 0 3.6 1.61 3.6 3.598z"
            stroke="#2F2F33"
            stroke-linecap="square"
        />
        <path
            clip-rule="evenodd"
            d="M15 17.39H3v-2.398a3.6 3.6 0 013.6-3.599h4.8a3.6 3.6 0 013.6 3.6v2.396z"
            stroke="#2F2F33"
            stroke-linecap="square"
        />
    </svg>
</template>
