<template>
    <li
        v-for="transaction in transactionsQuery?.data.value?.data.data.transactions.slice().reverse()"
        class="personal-page__debt"
    >
        <div class="task-inline-btn">
            <div>
                Платеж от {{ dateToString(new Date(transaction.paid_at || transaction.created_at)) }} на сумму
                {{ toCurrency(transaction.sum) }}
            </div>
            <ClockIcon v-if="transaction.status === 'pending'" class="task-inline-btn__icon" />
        </div>
        <LoadingButton
            v-if="transaction.status === 'pending'"
            variant="primary"
            :disabled="
                (checkTransactionStatusMutation.variables.value?.id === transaction.uniq_id &&
                    checkTransactionStatusMutation.isLoading.value) ||
                transactionsQuery?.isFetching.value
            "
            :is-loading="
                (checkTransactionStatusMutation.variables.value?.id === transaction.uniq_id &&
                    checkTransactionStatusMutation.isLoading.value) ||
                transactionsQuery?.isFetching.value
            "
            class="personal-page__debt-check-status-btn"
            @click="() => checkTransactionStatusMutation.mutate({ id: transaction.uniq_id })"
            ><span>Обновить</span></LoadingButton
        >
    </li>
</template>

<script setup lang="ts">
import { useMutation, useQuery } from 'vue-query';
import { axiosAPI } from '../../../../axios';
import LoadingButton from '../LoadingButton/LoadingButton.vue';
import { toCurrency } from '../../../../utils/to-currency';
import { dateToString } from '../../../../utils/dates';
import ClockIcon from '../../svg/Clock.vue';

const props = defineProps({
    projectId: {
        type: Number,
        required: true,
    },
});

const transactionsQuery = useQuery(
    ['transactions', props.projectId],
    () => axiosAPI.get(`/projects/${props.projectId}/transactions`),
    {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    },
);

const checkTransactionStatusMutation = useMutation(
    ['check-transaction-status', props.projectId],
    ({ id }) => axiosAPI.post(`/projects/${props.projectId}/transactions/${id}/check`),
    {
        onSuccess: () => {
            transactionsQuery.refetch.value();
        },
    },
);
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.personal-page__debt {
    display: flex;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.personal-page__debt-check-status-btn {
    margin-left: 9px;
    flex-shrink: 0;
    padding: 12px 16px;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
</style>
