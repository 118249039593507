import { defineStore } from 'pinia';
import Cookie from 'js-cookie';
import { axiosAPI } from '../../../axios';
import { store } from '../store';
import { router } from '../../../components/vue/router';
import { AuthUserResponse, BaseResponse, User, QaChainInfo } from '../../../types';
import { QA_CHAIN_LS_NAME } from '../pages/BriefPage.vue';

const token = Cookie.get('AUTH_TOKEN');

if (token) {
    store.state.token = token;
    axiosAPI.defaults.headers = { Authorization: `Bearer ${token}` };
} else {
    delete axiosAPI.defaults.headers.Authorization;
    Cookie.remove('AUTH_TOKEN');
    Cookie.remove('IS_LOGGED_IN');
}

export const getCsrfCookie = () => axiosAPI.get<any>('/sanctum/csrf-cookie');

export const useUserStore = defineStore<
    'user',
    {
        user: any;
        fetchUserPromise: Promise<any> | null;
        isFetching: boolean;
    },
    {},
    {
        fetchUser(): Promise<void>;
        register(data?: Record<string, any>): Promise<AuthUserResponse>;
        login(phone: string, password: string, redirectUrl?: string, user?: QaChainInfo): Promise<AuthUserResponse>;
        logout(redirectUrl?: string): Promise<any>;
        _setUserState(data: { token: string; user: User }): void;
    }
>('user', {
    state: () => ({ user: null, fetchUserPromise: null, isFetching: false }),
    // persist: true,
    actions: {
        async fetchUser() {
            if (store.state.token) {
                this.fetchUserPromise = axiosAPI.get<BaseResponse & { user: User }>('/user');
                this.isFetching = true;

                try {
                    const { data } = await this.fetchUserPromise;
                    this._setUserState({ token: store.state.token, user: data.data.user });
                } finally {
                    this.isFetching = false;
                }
            }
        },

        async register(requestData = {}) {
            /**
             * Регистрируем нового пользователя при первом ответе
             */
            await getCsrfCookie();
            const { data } = await axiosAPI.post<AuthUserResponse>('/register', requestData);
            this._setUserState({ token: data.data.token, user: data.data.user });
            return data;
        },

        async login(phone, password, redirectUrl, user) {
            await getCsrfCookie();
            const { data } = await axiosAPI.post<AuthUserResponse>('/login', { phone, password, user });
            this._setUserState({ token: data.data.token, user: data.data.user });

            if (redirectUrl) {
                // TODO возможно код не очень, надо проверить @Pavel_Mazhuga
                /**
                 *  В сторе юзера нет доступа в стор проектов, поэтому, чтобы не делать проверку
                 * после редиректа, и там редиректить снова - получаю данные о проектах здесь
                 */
                await axiosAPI.get('/projects').then((response) => {
                    const projects = response.data.data.projects;
                    const currentProject = projects.find((project) => project.slug === redirectUrl.split('/')[1]);
                    axiosAPI.get(`/projects/${currentProject.id}/start`).then(({ data }) => {
                        const briefId = data.data.brief_id;
                        const briefUrl = `/${currentProject.slug}/briefs/${briefId}/${data.data.question_id}`;
                        const shouldRedirectToBrief =
                            this.user.completed_briefs && !this.user.completed_briefs.includes(briefId);

                        router.push(shouldRedirectToBrief ? briefUrl : redirectUrl);
                    });
                });
            }

            return data;
        },

        async logout(redirectUrl) {
            const response = await axiosAPI.post<any>('/logout');
            store.state.token = '';
            delete axiosAPI.defaults.headers.Authorization;
            Cookie.remove('AUTH_TOKEN');
            Cookie.remove('IS_LOGGED_IN');
            this.user = null;

            if (redirectUrl) {
                router.push(redirectUrl);
            }

            return response;
        },

        _setUserState({ token, user }) {
            store.state.token = token;
            axiosAPI.defaults.headers.Authorization = `Bearer ${token}`;
            const expires = 30;
            Cookie.set('AUTH_TOKEN', token, { expires });
            Cookie.set('IS_LOGGED_IN', 'true', { expires });
            this.user = user;
            localStorage.removeItem(QA_CHAIN_LS_NAME);
        },
    },
});
