<template>
    <div class="tariffs-list-wrapper">
        <Loader v-if="tariffsQuery.isLoading.value" class="tasks-loader" />
        <div v-else-if="tariffsQuery.data.value?.data.data.tariffs.length > 0">
            <div v-if="isDiscountActive" class="countdown-cta choose-tariff-countdown-cta">
                <div class="countdown-cta__left">
                    <WarningRoundIcon />
                </div>
                <div class="countdown-cta__content">
                    <div class="countdown-cta__content-text">
                        Получите скидку
                        <span class="countdown-cta__text--highlighted">–{{ discountPercent }}%</span> при&nbsp;оплате
                        любого тарифа
                        <span class="countdown-cta__text--highlighted"
                            >в&nbsp;течение {{ initialCountdownMinutes }} минут</span
                        >
                    </div>
                    <Countdown
                        :countdown-id="`choose-tariff-countdown-brief-${afterBrief.briefId}`"
                        :persist-on-reload="true"
                        :initial-seconds="60 * initialCountdownMinutes"
                        @complete="onCountdownComplete"
                    />
                </div>
            </div>
            <ul class="list-unstyled tariffs-list">
                <li
                    v-for="tariff in tariffsQuery.data.value.data.data.tariffs"
                    :key="tariff.id"
                    class="tariffs-list__item"
                >
                    <Tariff
                        :id="tariff.id"
                        :uuid="tariff.uuid"
                        :name="tariff.name"
                        :text="tariff.description"
                        :price="tariff.price"
                        :duration="tariff.duration"
                        :code="tariff.code"
                        :popular="tariff.discount_pretty"
                        :popular-caption="tariff.discount_caption"
                        :discount-percent="isDiscountActive ? discountPercent : 0"
                        :discount-button-text="tariff.discount_button_text"
                        :arrow="true"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { axiosAPI } from '../../../../axios';
import { defineComponent, ref } from 'vue';
import { useQuery } from 'vue-query';
import SurveyTop from '../../components/survey/SurveyTop/SurveyTop.vue';
import Tariff from '../../components/Tariff/Tariff.vue';
import Loader from '../../components/Loader/Loader.vue';
import Countdown from '../../components/Countdown/Countdown.vue';
import ArrIcon from '../../svg/Arr.vue';
import WarningRoundIcon from '../../svg/WarningRound.vue';
import { useRouter } from 'vue-router';
import { getProjectPropBy } from '../../../../utils/projects';
import { useUserStore } from '../../stores/user';
import { useProjectsStore } from '../../stores/projects';
import { useAfterBriefStore } from '../../stores/after-brief';

export default defineComponent({
    components: { SurveyTop, ArrIcon, WarningRoundIcon, Loader, Tariff, Countdown },

    setup() {
        const router = useRouter();
        const userStore = useUserStore();
        const projectsStore = useProjectsStore();
        const projectId = getProjectPropBy<number>(
            'slug',
            projectsStore.projects,
            router.currentRoute.value.params.projectSlug,
            'id',
        );
        const afterBrief = useAfterBriefStore();

        const initialCountdownMinutes = 20;

        const isDiscountActive = ref(
            userStore.user.available_project_discounts !== null &&
                typeof userStore.user.available_project_discounts[projectId] === 'number' &&
                afterBrief &&
                typeof afterBrief.briefId === 'number',
        );

        const onCountdownComplete = () => {
            isDiscountActive.value = false;
        };

        const tariffsQuery = useQuery(['tariffs', projectId], () => axiosAPI.get(`/projects/${projectId}/tariffs`), {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: !!projectId,
        });

        return {
            tariffsQuery,
            router,
            initialCountdownMinutes,
            onCountdownComplete,
            isDiscountActive,
            afterBrief,
            discountPercent:
                userStore.user.available_project_discounts !== null &&
                typeof userStore.user.available_project_discounts[projectId] === 'number'
                    ? userStore.user.available_project_discounts[projectId]
                    : 0,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.tariffs-list-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.tariffs-list__item {
    &:not(:last-child) {
        margin-bottom: 8px;

        @media screen and (min-width: 1025px) {
            margin-bottom: 16px;
        }

        @media screen and (max-width: 1681px) {
            margin-bottom: 24px;
        }
    }
}

.countdown-cta {
    text-align: center;
    border-radius: 20px;
    padding: 26px;
    display: flex;
    align-items: flex-start;
    border: 1px solid $primary;

    @media screen and (max-width: 767px) {
        padding: 20px;
        border-radius: 16px;
    }

    @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: center;
    }
}

.choose-tariff-countdown-cta {
    margin-bottom: 38px;

    @media screen and (max-width: 1024px) {
        background-color: var(--elements-bg);
    }

    @media screen and (max-width: 480px) {
        margin-bottom: 28px;
    }
}

.countdown-cta__left {
    width: 41px;

    @media screen and (max-width: 480px) {
        margin-bottom: 15px;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

.countdown-cta__content {
    padding-left: 39px;
    padding-right: 39px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;

    @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (max-width: 480px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.countdown-cta__content-text {
    margin-bottom: 20px;

    @media screen and (max-width: 480px) {
        margin-bottom: 15px;
    }
}

.countdown-cta__text--highlighted {
    color: $primary;
    display: inline-block;
}
</style>
