<template>
    <div class="content">
        <v-header></v-header>

        <div v-if="!projectsStore.isFetching" class="page">
            <router-view></router-view>
        </div>
        <Loader v-else class="tasks-loader" />

        <v-footer></v-footer>
    </div>
</template>

<script lang="ts">
import { onBeforeMount } from 'vue';
import Cookie from 'js-cookie';
import Header from './layout/Header.vue';
import Footer from './layout/Footer.vue';
import { useUserStore } from './stores/user';
import { useProjectsStore } from './stores/projects';
import Loader from './components/Loader/Loader.vue';
import { store } from './store';

export default {
    components: { 'v-header': Header, 'v-footer': Footer, Loader },

    setup() {
        const userStore = useUserStore();
        const projectsStore = useProjectsStore();

        onBeforeMount(() => {
            if (store.state.token) {
                userStore.fetchUser();
            }

            projectsStore.fetchProjects();
        });

        return {
            userStore,
            projectsStore,
        };
    },
};
</script>

<style lang="scss">
@import '../../../css/base/variables';
@import '../../../css/utils/mixins';

.content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}
</style>
