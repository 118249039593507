<template>
    <ErrorPage :code="code" :title="title" :description="description" />
</template>

<script setup lang="ts">
import ErrorPage from '../layout/ErrorPage.vue';

const code = 403;
const title = 'Доступ запрещен';
const description = 'Доступ к этой странице запрещен';
</script>
