<template>
    <NotFoundPage v-if="notFound" />
    <div v-else class="wrapper tasks-page-wrapper">
        <h1 class="tasks-title">Задания</h1>

        <Loader v-if="tasks.length === 0 && loading" class="tasks-loader" />

        <template v-else>
            <div v-if="userStore.user && !isUserSubscribedToCurrentProject" class="tasks-message">
                <MessageBox type="warning" text="Вы не можете завершать задачи, пока не оплатите подписку">
                    <router-link
                        :to="`/${router.currentRoute.value.params.projectSlug}/choose-tariff`"
                        class="btn btn-primary"
                        >Оплатить</router-link
                    >
                </MessageBox>
            </div>

            <div v-if="tasks.length > 0" ref="tasksList" class="tasks-list-wrapper">
                <Loader v-if="loading" class="tasks-loader" />
                <ul v-else class="list-unstyled tasks-list">
                    <li v-for="task in tasks" :key="task.id" class="tasks-list__item">
                        <button
                            class="tasks-item"
                            :class="{ 'is-active': task.active }"
                            @click="() => showTask(task.id)"
                        >
                            <span class="tasks-item__text">{{ task.title }}</span>
                            <span class="tasks-item__icon" aria-hidden="true">
                                <icon-arr></icon-arr>
                            </span>
                        </button>
                        <v-task
                            v-if="isMobile"
                            :task-id="task.id"
                            :title="task.title"
                            :description="task.description"
                            :actions="task.actions"
                            :active="task.active"
                            :type="task.type"
                            :tip="task.tip"
                            :is-fetching-tasks="loading"
                            :last-entity="task.last_entity"
                            :user-subscribed="isUserSubscribedToCurrentProject"
                            :payment="task.payment"
                            :disabled="task.disabled"
                            @hide="() => hideTask(task.id)"
                            @completed="refresh"
                            @refresh="refresh"
                        ></v-task>
                    </li>
                </ul>
                <div v-if="!isMobile && tasks.length > 0" class="tasks-desktop">
                    <div v-for="task in tasks" :key="task.id" class="task-desktop">
                        <v-task
                            :task-id="task.id"
                            :title="task.title"
                            :description="task.description"
                            :actions="task.actions"
                            :active="task.active"
                            :type="task.type"
                            :tip="task.tip"
                            :is-fetching-tasks="loading"
                            :last-entity="task.last_entity"
                            :user-subscribed="isUserSubscribedToCurrentProject"
                            :payment="task.payment"
                            :disabled="task.disabled"
                            @hide="() => hideTask(task.id)"
                            @completed="refresh"
                            @refresh="refresh"
                        ></v-task>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import ArrIcon from '../svg/Arr.vue';
import Task from '../components/Task/Task.vue';
import Loader from '../components/Loader/Loader.vue';
import { getOffsetTop } from '../../../utils/dom';
import { axiosAPI } from '../../../axios';
import { BaseResponse, Task as TaskType } from '../../../types';
import MessageBox from '../components/MessageBox/MessageBox.vue';
import { useUserStore } from '../stores/user';
import { getProjectPropBy } from '../../../utils/projects';
import { useProjectsStore } from '../stores/projects';
import { useRouter } from 'vue-router';
import NotFoundPage from '../pages/NotFoundPage.vue';

type TasksResponse = BaseResponse & {
    data: {
        tasks: TaskType[];
    };
};

export default defineComponent({
    components: { NotFoundPage, 'icon-arr': ArrIcon, 'v-task': Task, Loader, MessageBox },

    setup() {
        const router = useRouter();
        const notFound = ref(false);
        const isMobile = ref<boolean>(false);
        const tasksList = ref<HTMLElement>();
        const errorMessage = ref('');
        const loading = ref(false);
        const _tasks = ref<any[]>([]);
        const tasks = ref<any[]>(_tasks.value.map((task) => ({ ...JSON.parse(JSON.stringify(task)), active: false })));

        const userStore = useUserStore();
        const projectsStore = useProjectsStore();

        const projectId = getProjectPropBy(
            'slug',
            projectsStore.projects,
            router.currentRoute.value.params.projectSlug,
            'id',
        );

        const isUserSubscribedToCurrentProject = computed(() => {
            return userStore.user ? userStore.user.subscribed_projects.includes(projectId) : false;
        });

        function lockScroll() {
            document.body.classList.add('no-scroll');
        }

        function unlockScroll() {
            document.body.classList.remove('no-scroll');
        }

        function showTask(id: number) {
            const task = tasks.value.find((obj) => obj.id === id);
            tasks.value.forEach((task) => hideTask(task.id));
            if (task) {
                task.active = true;

                if (window.matchMedia('(max-width: 1024px)').matches) {
                    lockScroll();
                } else if (tasksList.value) {
                    window.scrollTo({ top: getOffsetTop(tasksList.value, { offsetY: -40 }), behavior: 'smooth' });
                }
            }
        }

        function hideTask(id: number) {
            const task = tasks.value.find((obj) => obj.id === id);
            if (task) {
                task.active = false;

                if (window.matchMedia('(max-width: 1024px)').matches) {
                    unlockScroll();
                }
            }
        }

        async function fetchTasks() {
            loading.value = true;

            try {
                const { data } = await axiosAPI.get<TasksResponse>(`/projects/${projectId}/tasks`);
                _tasks.value = data.data.tasks;
                return data.data.tasks;
            } catch (err: any) {
                if (err.response.status === 404) {
                    notFound.value = true;
                }

                errorMessage.value = err.response.data.message || err.message;
                throw err;
            } finally {
                loading.value = false;
            }
        }

        function refresh() {
            fetchTasks().then((tasks) => {
                if (tasks[0]) {
                    showTask(tasks[0].id);
                }
            });
        }

        function onResize() {
            isMobile.value = matchMedia('(max-width: 1024px)').matches;
        }

        watch(_tasks, (val, prevVal) => {
            tasks.value = val.map((task) => ({ ...JSON.parse(JSON.stringify(task)), active: false }));

            if (prevVal.length === 0 && !window.matchMedia('(max-width: 1024px)').matches) {
                if (val[0]) {
                    showTask(val[0].id);
                }
            }
        });

        onMounted(() => {
            fetchTasks();
        });

        onMounted(() => {
            onResize();
            window.addEventListener('resize', onResize);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onResize);
        });

        return {
            notFound,
            userStore,
            loading,
            tasksList,
            tasks,
            showTask,
            hideTask,
            refresh,
            isMobile,
            router,
            isUserSubscribedToCurrentProject,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

.page-title {
    font-size: 26px;
    margin-bottom: 24px;
    text-transform: none;
    font-weight: 600;
}

.tasks-title {
    margin-bottom: 22px;
}

.tasks-list {
    position: relative;

    @media screen and (min-width: 1025px) {
        width: 38.3%;
        padding-right: 92px;
    }
}

.tasks-list__item {
    // @media screen and (min-width: 1025px) {
    //     width: 38.3%;
    //     padding-right: 92px;
    // }

    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.tasks-list-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1025px) {
        flex-direction: row;
    }
}

.tasks-desktop {
    width: 61.7%;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    .task-container {
        position: static;
        z-index: auto;
        right: auto;
        left: auto;
        top: auto;
        bottom: auto;
        height: auto;
    }
}

.tasks-item {
    width: 100%;
    border-radius: 16px;
    background-color: #fff;
    padding: 16px;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    line-height: 1.25;
    text-align: left;

    @media screen and (min-width: 1025px) {
        transition: color 0.2s easeOutCubic, background-color 0.2s easeOutCubic;
        color: rgba($body-color, 0.5);
    }

    &.is-active {
        @media screen and (min-width: 1025px) {
            background-color: $primary;
            color: #fff;
        }

        .tasks-item__icon {
            @media screen and (min-width: 1025px) {
                path {
                    stroke: currentColor;
                }
            }
        }
    }

    @mixin hover() {
        color: $primary;
    }

    &:focus-visible {
        @include hover();
    }

    @media (pointer: fine) {
        &:hover:not(.is-active) {
            @include hover();
        }
    }
}

.tasks-item__icon {
    margin-left: 20px;
    border-radius: 10px;
    background-color: rgba($body-color, 0.1);
    width: 34px;
    height: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    svg {
        [stroke] {
            transition: stroke 0.2s easeOutCubic;
            stroke: $primary;
        }
    }
}

.tasks-loader {
    --size: 100px;
    margin: auto;
}

.tasks-page-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.tasks-message {
    margin-bottom: 30px;
}
</style>
