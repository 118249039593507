import { onMounted, onUnmounted } from 'vue';

export function usePageClass(classObj: Record<string, boolean>) {
    onMounted(() => {
        Object.keys(classObj).forEach((className) => {
            if (classObj[className]) {
                document.documentElement.classList.add(className);
            }
        });
    });

    onUnmounted(() => {
        Object.keys(classObj).forEach((className) => {
            document.documentElement.classList.remove(className);
        });
    });
}
