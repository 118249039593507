<template>
    <label class="checkbox">
        <input ref="input" v-bind="$props" :type="type" />
        <span class="checkbox__element"></span>
        <span class="checkbox__text"><slot></slot></span>
    </label>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
    props: {
        type: {
            type: String,
            default: 'checkbox',
        },
        text: {
            type: String,
        },
    },

    setup(props, { emit, expose }) {
        const input = ref<HTMLElement>();

        onMounted(() => {
            emit('mounted', { ref: input });
        });

        expose({ el: input });

        return { input };
    },
});
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.checkbox {
    padding-left: 40px;
    position: relative;
    cursor: pointer;
    letter-spacing: 0;

    input[type='checkbox'],
    input[type='radio'] {
        @include visually-hidden();

        &:checked {
            ~ .checkbox__element {
                &::before {
                    opacity: 1;
                }
            }
        }

        &:disabled {
            ~ .checkbox__element,
            ~ .checkbox__text {
                opacity: $btn-disabled-opacity;
            }
        }

        &:focus-visible {
            ~ .checkbox__element {
                border-color: $primary;
            }
        }

        &.is-error {
            ~ .checkbox__element {
                border-color: $danger;
            }
        }
    }

    @media (pointer: fine) {
        &:hover {
            input:not(:disabled) {
                ~ .checkbox__element {
                    border-color: rgba($body-color, 0.5);
                }

                // ~ .checkbox__text {
                //     color: $primary;
                // }
            }
        }
    }
}

.checkbox__element {
    position: absolute;
    left: 0;
    top: -0.25em;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    transition: background-color 0.1s ease, border-color 0.1s ease;
    background-color: var(--elements-bg);
    border: 1px solid transparent;

    @media screen and (min-width: 1025px) {
        // background-color: $primary;
        top: 0;
    }

    input[type='radio'] ~ & {
        border-radius: 50%;

        &::before {
            width: 13px;
            height: 13px;
            background-image: none;
            background-color: $primary;
            background-image: none;
            border-radius: inherit;
            background-color: $primary;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 11px;
        height: 8px;
        border-radius: inherit;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.916 4.693l4.714 4 6.286-8' stroke='%232F2F33'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0;
        transition: opacity 0.1s ease;

        // @media screen and (min-width: 1025px) {
        //     background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.916 4.693l4.714 4 6.286-8' stroke='%23fff'/%3E%3C/svg%3E%0A");
        // }
    }
}

.checkbox__text {
    transition: color 0.1s ease;
}
</style>
