<template>
    <div class="task-inline">
        <template v-if="isMobile">
            <button type="button" class="task-inline-btn" :class="{ 'is-active': active }" @click="showTask">
                <span class="task-inline__title">{{ title }}</span>
                <span class="task-inline__icon" aria-hidden="true">
                    <icon-arr></icon-arr>
                </span>
            </button>
            <v-task
                :task-id="taskId"
                :title="title"
                :description="description"
                :active="active"
                :actions="actions"
                tip="Подсказка"
                :user-subscribed="userSubscribed"
                submit-text="Готово"
                :disabled="disabled"
                inline
                @hide="hideTask"
            ></v-task>
        </template>
        <template v-else>
            <button type="button" class="task-inline-btn" :data-lit-popup-open="`inline-task-${taskId}`">
                <span class="task-inline__title">{{ title }}</span>
                <span class="task-inline__icon" aria-hidden="true">
                    <icon-arr></icon-arr>
                </span>
            </button>
            <v-modal class="inline-task-popup" :name="`inline-task-${taskId}`">
                <button
                    type="button"
                    class="popup-close-btn"
                    :data-lit-popup-close="`inline-task-${taskId}`"
                    aria-label="Закрыть"
                >
                    <icon-cross></icon-cross>
                </button>
                <v-task
                    class="task-container--in-popup"
                    :task-id="taskId"
                    :title="title"
                    :description="description"
                    :active="active"
                    :actions="actions"
                    :user-subscribed="userSubscribed"
                    tip="Подсказка"
                    submit-text="Готово"
                    :disabled="disabled"
                    inline
                ></v-task>
            </v-modal>
        </template>
    </div>
</template>

<script lang="ts">
import { ref, onBeforeUnmount, onMounted } from 'vue';
import Modal from '../Modal/Modal.vue';
import ArrIcon from '../../svg/Arr.vue';
import CrossIcon from '../../svg/Cross.vue';
import Task from './Task.vue';

export default {
    components: { 'icon-arr': ArrIcon, 'icon-cross': CrossIcon, 'v-modal': Modal, 'v-task': Task },
    props: {
        taskId: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        userSubscribed: {
            type: Boolean,
            default: false,
        },
        tip: {
            type: String,
            default: '',
        },
    },

    setup(props, { emit }) {
        const isMobile = ref(false);
        const active = ref(false);

        function showTask() {
            emit('show', { id: props.id });
            active.value = true;
        }

        function hideTask() {
            emit('hide', { id: props.id });
            active.value = false;
        }

        function onResize() {
            isMobile.value = matchMedia('(max-width: 1024px)').matches;
        }

        onMounted(() => {
            isMobile.value = matchMedia('(max-width: 1024px)').matches;
            window.addEventListener('resize', onResize);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', onResize);
        });

        return { showTask, hideTask, isMobile, active };
    },
};
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.task-inline-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--body-bg);
    border-radius: 9px;
    // padding: 8px 24px;
    padding: 12px 20px;
    text-align: left;

    @media screen and (max-width: 1024px) {
        background-color: #f6f7f9;
        padding: 12px 15px;
    }

    @media screen and (max-width: 767px) {
        padding-left: 11px;
        padding-right: 11px;
    }
}

a.task-inline-btn,
button.task-inline-btn {
    transition: background-color 0.2s ease, color 0.2s ease;

    @mixin hover() {
        background-color: $primary;
        color: #fff;

        .task-inline__icon {
            path {
                stroke: currentColor;
            }
        }
    }

    &:focus-visible,
    &:active {
        @include hover();
    }

    @media (pointer: fine) {
        &:not(:disabled):hover {
            @include hover();
        }
    }
}

.task-inline-btn__icon {
    flex-shrink: 0;
    margin-left: 10px;
}

.task-inline__title {
    padding-right: 15px;
}

.task-inline__icon {
    display: inline-flex;
    align-items: center;
    height: 32px;

    @media screen and (max-width: 1024px) {
        height: auto;
    }

    path {
        stroke: $primary;
    }
}

.inline-task-popup {
    &[data-lit-popup-preset='slide'] {
        .lit-popup-container {
            max-width: 850px;
        }
    }

    .task {
        background-color: #fff;

        .btn-info {
            background-color: var(--elements-bg);
        }
    }
}

.task-container--in-popup {
    @media screen and (min-width: 1025px) {
        position: static;
        width: 100%;
        height: auto;
        display: block;
        visibility: unset;
    }
}
</style>
