<template>
    <div class="wrapper survey">
        <SurveyTop
            v-if="router.currentRoute.value.params.projectSlug && briefId && afterBriefStore.lastQuestionId"
            :back-url="`/${router.currentRoute.value.params.projectSlug}/briefs/${briefId}/${afterBriefStore.lastQuestionId}/`"
        />

        <h1 class="survey-title" :class="{ 'survey-title--hidden': briefQuery.isLoading.value }">
            {{ currentAction?.title || 'Ответы проанализированы системой' }}
        </h1>

        <div class="brief-complete-combined-block">
            <BriefCompleteText />
        </div>

        <TariffsList class="brief-complete-combined-block" />

        <div
            v-if="faqQuery.data.value && faqQuery.data.value.data.data.faqs.length > 0"
            class="brief-complete-combined-block"
        >
            <x-collapse
                v-for="faq in faqQuery.data.value.data.data.faqs"
                class="personal-form-fieldset brief-complete-combined-block__fieldset"
                :collapsed="true"
            >
                <div class="personal-form-fieldset-top">
                    <div class="personal-form-fieldset__title">{{ faq.question }}</div>
                    <button
                        data-collapse-toggler
                        class="square-btn square-btn-dark personal-form-fieldset__collapse-btn"
                        aria-label="Toggle"
                    >
                        <ArrIcon />
                    </button>
                </div>

                <div class="collapse__content" data-collapse-content>
                    <div class="personal-form-fieldset__content" v-html="faq.answer"></div>
                </div>
            </x-collapse>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import Loader from '../components/Loader/Loader.vue';
import SurveyTop from '../components/survey/SurveyTop/SurveyTop.vue';
import SurveyCompleteBottom from '../components/survey/SurveyCompleteBottom/SurveyCompleteBottom.vue';
import BriefCompleteText from '../components/BriefCompleteText/BriefCompleteText.vue';
import TariffsList from '../components/TariffsList/TariffsList.vue';
import Button from '../components/Button/Button.vue';
import ArrIcon from '../svg/Arr.vue';
import { useAfterBriefStore } from '../stores/after-brief';
import { useAfterBriefActionsStore } from '../stores/after-brief-actions';
import { useRouter } from 'vue-router';
import { usePageClass } from '../hooks/usePageClass';
import { useQuery } from 'vue-query';
import { axiosAPI } from '../../../axios';
import { useProjectsStore } from '../stores/projects';
import { getProjectPropBy } from '../../../utils/projects';
import { store } from '../store';
import { useBriefComplete } from '../hooks/useBriefComplete';

export default defineComponent({
    components: {
        ArrIcon,
        'v-loader': Loader,
        SurveyTop,
        SurveyCompleteBottom,
        BriefCompleteText,
        TariffsList,
        Button,
    },

    setup() {
        usePageClass({ 'hidden-header': true, 'brief-complete-combined-page': true });
        const router = useRouter();
        const projectsStore = useProjectsStore();
        const afterBrief = useAfterBriefStore();
        const afterBriefActions = useAfterBriefActionsStore();
        const currentAction = computed(() => afterBriefActions.pendingActions[0]);
        const projectId = getProjectPropBy(
            'slug',
            projectsStore.projects,
            router.currentRoute.value.params.projectSlug,
            'id',
        ) as string;
        const isUserAvailable = !!store.state.token;
        const briefId = (router.currentRoute.value.query.briefId || afterBrief.briefId || '') as number | string;

        useBriefComplete({ briefId, projectId });

        const faqQuery = useQuery(
            ['faq', projectId, isUserAvailable, briefId],
            () => axiosAPI.get(`/projects/${projectId}${isUserAvailable ? '/first' : ''}/briefs/${briefId}/faq`),
            {
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                retry: 0,
                enabled: !!projectId && !!briefId,
            },
        );

        const briefQuery = useQuery(
            ['brief', projectId, briefId],
            () => axiosAPI.get(`/projects/${projectId}${isUserAvailable ? '/first' : ''}/briefs/${briefId}`),
            {
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                retry: 0,
                enabled: !!projectId && !!briefId,
            },
        );

        function completeAction() {
            if (currentAction) {
                currentAction.completed = true;
            }
        }

        return {
            briefId,
            afterBriefStore: afterBrief,
            actionsStore: afterBriefActions,
            completeAction,
            currentAction,
            router,
            faqQuery,
            briefQuery,
        };
    },
});
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

html.brief-complete-combined-page {
    .survey {
        padding-bottom: 60px;
    }
}

.brief-complete-combined-block__fieldset {
    & + & {
        margin-top: 50px;
    }
}

.brief-complete-combined-block {
    margin-bottom: 40px;
}
</style>
