<template>
    <div class="clock">
        <div class="clock-part">
            <div class="clock-part__value">{{ hours }}</div>
            <div class="clock-part__label">часов</div>
        </div>
        <div class="clock__divider">:</div>
        <div class="clock-part">
            <div class="clock-part__value">{{ minutes }}</div>
            <div class="clock-part__label">минут</div>
        </div>
        <div class="clock__divider">:</div>
        <div class="clock-part">
            <div class="clock-part__value">{{ remainingSeconds }}</div>
            <div class="clock-part__label">секунд</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { withLeadingZero } from '../../../../utils/string';

const props = defineProps({
    seconds: {
        type: Number,
        default: 0,
    },
});

const hours = computed(() => withLeadingZero(Math.floor(props.seconds / 3600)));
const minutes = computed(() => withLeadingZero(Math.floor((props.seconds % 3600) / 60)));
const remainingSeconds = computed(() => withLeadingZero(props.seconds % 60));
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.clock {
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 40px;
    line-height: 1;
    letter-spacing: 0;

    @media screen and (max-width: 1024px) {
        font-size: 30px;
    }
}

.clock__divider {
    margin-left: 0.25em;
    margin-right: 0.25em;
    line-height: 0.85;
}

.clock-part {
    flex-shrink: 0;
    text-align: center;
    min-width: 2.1ch;
}

.clock-part__label {
    font-size: 11px;
    opacity: 0.5;
}
</style>
