<template>
    <form novalidate class="phone-sms-form" @submit.prevent="onSubmit">
        <div v-if="beforeTitle" class="h2 phone-sms-validation-form__title">{{ beforeTitle }}</div>
        <div v-if="title" class="h2 phone-sms-validation-form__title">{{ title }}</div>
        <form-input
            id="input-tel"
            :is-error="!!vv.phone.$errors[0]"
            :helping-message="validationMessage(vv.phone)"
            :show-helping-message="!isCodeFormShown"
        >
            <input
                ref="phoneInput"
                id="input-tel"
                type="tel"
                name="phone"
                class="form-control"
                v-model="vv.phone.$model"
                v-maska="{ mask: '+7 (ZZZ) ZZZ-ZZ-ZZ', tokens: { Z: { pattern: /[0-9]/ } } }"
                required
                autocomplete="off"
                placeholder="Введите номер телефона"
                :disabled="inputDisabled"
                :autofocus="inputAutofocus"
                @input="onInputChange"
            />
        </form-input>
        <div v-if="isCodeFormShown" class="phone-sms-validation-tip-container">
            <button type="button" class="phone-sms-validation-tip" @click="changePhone">Изменить номер</button>
        </div>
        <div v-if="errorMessage" class="phone-sms-validation-form__error">{{ errorMessage }}</div>
        <loading-button
            v-if="errorMessage === 'Номер телефона уже используется' && router.currentRoute.value.params.projectSlug"
            class="btn btn-primary phone-sms-validation-form__error--auth-btn"
            :disabled="isLoggingOut"
            :is-loading="isLoggingOut"
            @click="logoutAndRedirectToLogin"
            >Авторизация</loading-button
        >
        <div v-if="!isCodeFormShown" class="phone-sms-validation__actions">
            <loading-button
                type="submit"
                variant="primary"
                :disabled="isSubmitting"
                :is-loading="isSubmitting"
                class="btn-with-icon phone-sms-validation__action"
            >
                <span class="btn-text">Получить код</span>
                <span class="btn-icon" aria-hidden="true">
                    <icon-arr></icon-arr>
                </span>
            </loading-button>
        </div>
    </form>
</template>

<script lang="ts">
import { onUnmounted, reactive, ref, toRef } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { isPhone } from '../../../../../modules/validator';
import { validationMessage } from '../../../../utils/forms';
import FormInput from '../FormInput/FormInput.vue';
import LoadingButton from '../LoadingButton/LoadingButton.vue';
import ArrIcon from '../../svg/Arr.vue';
import { useRouter } from 'vue-router';
import { YM_ID } from '../../router';
import { getUrlByActionType } from '../../../../utils/get-url-by-action-type';
import { useUserStore } from '../../stores/user';
import { store } from '../../store';
import { getProjectPropBy } from '../../../../utils/projects';
import { useProjectsStore } from '../../stores/projects';
import { axiosAPI } from '../../../../axios';
import { QA_CHAIN_LS_NAME } from '../../pages/BriefPage.vue';

const rules = {
    phone: { required, phone: isPhone },
};

export default {
    components: { FormInput, LoadingButton, 'icon-arr': ArrIcon },

    props: {
        beforeTitle: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        endpoint: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        sendSms: {
            type: Function,
            required: true,
        },
        inputDisabled: {
            type: Boolean,
            default: false,
        },
        inputAutofocus: {
            type: Boolean,
            default: false,
        },
        isCodeFormShown: {
            type: Boolean,
            default: false,
        },
        nextAction: {
            type: Object,
        },
    },

    setup({ sendSms, endpoint, nextAction }, { emit }) {
        const phoneInput = ref<HTMLInputElement>();
        const isSubmitting = ref(false);
        const isLoggingOut = ref(false);
        const errorMessage = ref('');
        const errorTimeout = ref<NodeJS.Timeout>();
        const formData = reactive({ phone: '' });
        const router = useRouter();
        const userStore = useUserStore();
        const redirectPath = nextAction
            ? getUrlByActionType(nextAction.type)
            : `/${router.currentRoute.value.params.projectSlug}/tasks`;
        const projectsStore = useProjectsStore();

        const vv = useVuelidate(rules, { phone: toRef(formData, 'phone') });

        function onInputChange(event: any) {
            emit('input-change', event.target.value);
            errorMessage.value = '';
        }

        async function logoutAndRedirectToLogin() {
            emit('redirect-to-login');
            isLoggingOut.value = true;

            try {
                if (!!store.state.token) {
                    await userStore.logout();
                }

                router.push(`/login?redirect=${redirectPath}`);
            } finally {
                isLoggingOut.value = false;
            }
        }

        async function onSubmit() {
            clearTimeout(errorTimeout.value);
            errorMessage.value = '';
            vv.value.$touch();

            const savedQaChain = sessionStorage.getItem(QA_CHAIN_LS_NAME);

            let data = {};

            if (typeof savedQaChain === 'string') {
                let briefId;

                const projectId = getProjectPropBy(
                    'slug',
                    projectsStore.projects,
                    router.currentRoute.value.params.projectSlug,
                    'id',
                );

                await axiosAPI.get(`/projects/${projectId}/start`).then(({ data }) => {
                    briefId = data.data.brief_id;
                });

                data = {
                    user: {
                        phone: formData.phone,
                        brief: {
                            id: briefId,
                            questions: JSON.parse(savedQaChain),
                        },
                    },
                };
            }

            if (vv.value.$invalid) return;

            isSubmitting.value = true;
            (window as any).ym?.(YM_ID, 'reachGoal', 'nomer');
            try {
                const response = await sendSms(endpoint, { ...data, ...formData });
                emit('phone-sms-form-success', { ...response.data.data, phone: formData.phone });
            } catch (err: any) {
                errorMessage.value = err.response?.data.message || err.message;
                emit('phone-sms-form-error', err);

                // errorTimeout.value = setTimeout(() => {
                //     errorMessage.value = '';
                // }, 4000);

                throw err;
            } finally {
                isSubmitting.value = false;
            }
        }

        function changePhone() {
            emit('phone-change');
            vv.value.$reset();
            formData.phone = '';
            phoneInput.value?.focus();
        }

        onUnmounted(() => {
            clearTimeout(errorTimeout.value);
        });

        return {
            onSubmit,
            changePhone,
            phoneInput,
            isSubmitting,
            errorMessage,
            vv,
            formData,
            validationMessage,
            router,
            onInputChange,
            redirectPath,
            logoutAndRedirectToLogin,
            isLoggingOut,
        };
    },
};
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.phone-sms-form {
    padding-bottom: 79px;
}

.phone-sms-validation__actions {
    display: flex;
    justify-content: flex-end;
}

.phone-sms-validation__action {
    @media screen and (max-width: 420px) {
        width: 100%;
        margin-top: 10px;

        .btn-icon {
            display: none;
        }
    }
}

.phone-sms-validation-form__error--auth-btn {
    margin-bottom: 20px;
}
</style>
