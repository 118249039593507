import { onMounted } from 'vue';
import { axiosAPI } from '../../../axios';
import { useAfterBriefActionsStore } from '../stores/after-brief-actions';

export function useBriefComplete({ projectId, briefId }: { projectId: number | string; briefId: number | string }) {
    const afterBriefActions = useAfterBriefActionsStore();

    onMounted(() => {
        // if (
        //     !afterBriefActions.actions ||
        //     (Array.isArray(afterBriefActions.actions) && afterBriefActions.actions.length === 0)
        // ) {
        axiosAPI.get(`/projects/${projectId}/briefs/${briefId}/actions`).then((response) => {
            afterBriefActions.actions = response.data.data.actions;
        });
        // }
    });

    onMounted(() => {
        const { href } = window.location;
        const url = `${href}${href.includes('?') ? '&' : '?'}briefId=${briefId}`;

        /**
         * Посылаем запрос на бэк, чтобы начать отсчет до момента отправки СМС "Оплати проект" пользователю
         */
        axiosAPI.post(`/projects/${projectId}/briefs/${briefId}/notify-on-completion`, { url });
    });
}
