<template>
    <div class="wrapper">
        <div class="text-wrapper wysiwyg">
            <h1>Лицензионное соглашение</h1>

            <p>
                Внимание! Если вы создаёте персональную страницу, заходите на свою персональную страницу или страницу
                любого другого лицензиата автоматизированного программного комплекса «osmolegal.ru» (далее портал), либо
                загружаете или копируете любой размещённый на ней контент, либо иным способом используете портал, то
                этим вы акцептуете настоящее лицензионное соглашение и подтверждаете своё согласие со всеми его
                условиями без каких-либо ограничений.
            </p>

            <p>
                Настоящее лицензионное соглашение (далее «Соглашение») является лицензионным договором о предоставлении
                права использования программного комплекса (программы для ЭВМ) (ст.1235, 1286 Гражданского кодекса РФ) и
                регулирует отношения между Правообладателем на использование Автоматизированного программного комплекса
                «osmolegal.ru», именуемое в дальнейшем «Лицензиар» и Вами, Лицензиатом Портала (далее «Лицензиат»), в
                отношении использования Портала.
            </p>

            <h2>Термины, используемые в настоящем Соглашении</h2>

            <p>
                Портал — Автоматизированный программный комплекс, известный под именем «osmolegal.ru», размещенный на
                сайте в сети Интернет по адресу: osmolegal.ru и доступный Лицензиату через сайт, мобильную версию сайта,
                приложения и иные ресурсы, представляющая собой результат интеллектуальной деятельности в форме
                программы для ЭВМ (ст.1261 Гражданского кодекса Российской Федерации). Портал представлен в объективной
                форме совокупностью данных и команд, и порождаемых аудиовизуальных отображений (включая входящие в ее
                состав графические изображения и пользовательский интерфейс), (далее – данные и команды),
                предназначенных для функционирования ЭВМ и мобильных устройств в целях получения определённого
                результата в виде организации функционала Портала. Совокупность данных и команд состоит из
                активированных и неактивированных данных и команд. Портал позволяет Лицензиатам при наличии доступа во
                всемирную сеть Интернет создавать персональные страницы и знакомиться с персональными страницами других
                Лицензиатов, готовить процессуальные документы для совершения юридически значимых действий, подавать
                процессуальные документы в суды Российской Федерации, осуществлять поиск информации о других
                Лицензиатах, обмениваться личными сообщениями с другими Лицензиатами, размещать, копировать и загружать
                фотографии и иной контент, пользоваться иными функциональными возможностями Портала. Права использования
                данных и команд (активированных и неактивированных) передаются Лицензиаром Лицензиату на условиях,
                определенных настоящим Соглашением, а также ст.1280 Гражданского кодекса РФ. Оперирование и обслуживание
                Портала, включая техническое и организационное сопровождение, осуществляется исключительно Лицензиаром и
                представляет собой его обязательство (с учетом положений статьи 5 настоящего Соглашения), обеспечивающее
                возможность предоставления прав Лицензиату. Участие Лицензиатов на Портале происходит в интерактивном
                (онлайн) режиме посредством подключения Лицензиата через всемирную сеть Интернет. Лицензиар является
                обладателем необходимого объёма прав на Портал как программу для ЭВМ и на все составляющие её части,
                взятые как в отдельности, так и в совокупности, а также порождаемые ею аудиовизуальные элементы, за
                исключением Контента, не размещённого на Портале Лицензиаром, права на элементы которого могут
                принадлежать третьим лицам. Лицензиар вправе осуществлять использование, оперирование и распространение
                Портала на соответствующих территориях, где он обеспечивает её использование, оперирование и
                распространение.
            </p>
            <p>
                Лицензиар — платформа «osmolegal.ru», которая предоставляет Лицензиату право использования Портала на
                условиях простой (неисключительной) лицензии (пп.1 п.1 ст.1236 Гражданского кодекса РФ) и осуществляет
                доведение до всеобщего сведения, распространение, оперирование, обслуживание, администрирование Портала.
                Лицензиар является Стороной настоящего Соглашения.
            </p>
            <p>
                Лицензиат — юридическое или физическое лицо, обладающее право- и дееспособностью для заключения
                настоящего Соглашения, являющееся пользователем Портала, которому в соответствии с настоящим Соглашением
                предоставляется право использовать Портал на условиях настоящего Соглашения. Лицензиат является Стороной
                настоящего Соглашения. Контент — размещённые на Портале элементы дизайна, иллюстрации, графические
                изображения, фотографии, скрипты, тексты, видео, музыка, звуки и другие объекты, являющиеся в том числе
                результатами интеллектуальной деятельности, права использования которых могут принадлежать Лицензиару,
                Лицензиатам или иным лицам.
            </p>
            <p>
                Приложения – размещённые на Портале Лицензиаром или с его согласия третьими лицами программы для ЭВМ
                (электронные сервисы и т.д.) или их элементы, которые могут использоваться Лицензиатом в рамках Портала
                дополнительно к основным функциям Портала. Участие Лицензиата в Приложениях осуществляется на условиях,
                предусмотренных отдельными соглашениями с третьими лицами.
            </p>
            <p>
                Персональная страница – раздел Портала, содержащий часть размещаемой в Аккаунте Лицензиата информации (в
                том числе, по желанию Лицензиата, фотографии, сведения о статусах и прочее), не доступный для
                ознакомления иным Лицензиатам. Персональная страница используется Лицензиатом в целях обмена Личными
                сообщениями и осуществления иных действий. Доступ к персональной странице ограничен Лицензиатом для
                других пользователей в пределах доступного функционала Портала или Лицензиаром согласно условиям
                настоящего Соглашения.
            </p>
            <p>
                Аккаунт Лицензиата (Аккаунт) – учётная запись Лицензиата, создаваемая в момент регистрации на Портале,
                позволяющая Лицензиару учитывать каждого Лицензиата и предоставлять права использования Порталом
                посредством уникального логина и пароля. Логин и пароль для доступа к Аккаунту определяются Лицензиатом
                самостоятельно при регистрации на Портале и могут быть изменены им в порядке, описанном в настоящем
                Соглашении или разделе «Помощь» на странице Портала.
            </p>
            <p>
                Вознаграждение - плата за предоставление Лицензиату прав использования неактивированных данных и команд
                в пределах, установленных настоящим Соглашением. Размер вознаграждения определяется Лицензиаром и
                зависит от объёма неактивированных данных и команд, право использования которых передается Лицензиату.
                Правилами Портала могут быть предусмотрены иные условия получения прав использования неактивированных
                данных и команд, а также ограничения по их использованию. Лицензиат может ознакомиться с указанными
                правилами в соответствующих разделах Портала.
            </p>
            <p>
                Государственная пошлина — сбор, взимаемый с лиц, указанных в статье 333.17 Налогового Кодекса, при их
                обращении в государственные органы, органы местного самоуправления, иные органы и (или) к должностным
                лицам, которые уполномочены в соответствии с законодательными актами Российской Федерации,
                законодательными актами субъектов Российской Федерации и нормативными правовыми актами органов местного
                самоуправления, за совершением в отношении этих лиц юридически значимых действий, предусмотренных
                настоящей главой, за исключением действий, совершаемых консульскими учреждениями Российской Федерации.
            </p>
            <p>
                Лицензионное соглашение — текст настоящего Соглашения, заключаемого между Лицензиаром и Лицензиатом,
                содержащий все необходимые и существенные условия лицензионного договора о предоставлении прав
                использования Портала, в том числе неактивированных данных и команд. Неотъемлемой частью настоящего
                Соглашения являются также правила поведения Лицензиата или правила совершения Лицензиатом определенных
                действий, а также условия оплаты Вознаграждения и любые иные правила использования Портала, размещенные
                Лицензиаром на соответствующих страницах Портала, в том числе в разделе «Помощь», включая мобильную
                версию сайта и Приложения Лицензиара.
            </p>

            <h2>Условия присоединения к настоящему Соглашению</h2>

            <p>
                Перед тем как начать использование Портала, Лицензиат обязан ознакомиться с настоящим Соглашением, а
                также со всеми применимыми на Портале правилами и иными документами, которые размещены в соответствующих
                разделах Портала.
            </p>
            <p>
                После заполнения обязательных полей и ознакомления с настоящим Соглашением Лицензиат присоединяется
                (принимает) настоящее Соглашение, путем нажатия кнопки «Условия Лицензионного соглашения приняты» или
                аналогичной, что по смыслу ст.ст. 435 и 438 Гражданского кодекса РФ является принятием (акцептом) оферты
                Лицензиара, а равно заключением договора, порождающего у Лицензиата обязанности соблюдать условия
                Соглашения, в том числе применимых на Портале правил.
            </p>
            <p>
                Фактическое использование Портала без регистрации Аккаунта, в форме и в объёме, доступном без
                регистрации, также является акцептом настоящего Соглашения.
            </p>

            <h2>Предмет Соглашения</h2>

            <p>
                По настоящему Соглашению Лицензиар предоставляет Лицензиату на условиях простой (неисключительной)
                лицензии (пп.1 п.1 ст.1236 Гражданского кодекса РФ) право использования Портала, включая права
                использования активированных и неактивированных данных и команд в пределах, определённых настоящим
                Соглашением.
            </p>
            <p>Право использования активированных данных и команд предоставляется Лицензиату бесплатно.</p>
            <p>
                Право использования неактивированных данных и команд предоставляется Лицензиату за вознаграждение, если
                Лицензиаром не предусмотрен иной способ его получения.
            </p>

            <h2>Пределы использования Портала</h2>

            <p>Лицензиат вправе использовать Портал следующими способами:</p>
            <ul>
                <li>
                    использовать функционал Портала, в том числе, участвовать в формировании Портала путём создания
                    Аккаунта и Персональной страницы и изменять содержание Портала и персональные настройки Аккаунта в
                    ходе такого участия посредством размещения и обработки информации и Контента, взаимодействия с
                    другими Лицензиатами с соблюдением предусмотренных настоящим Соглашением правил;
                </li>
                <li>
                    воспроизводить элементы Портала в виде размещённой на ней информации и Контента для личного
                    использования посредством копирования в память своего персонального компьютера и/ или мобильного
                    устройства (скачивания). В том случае, если элементы Контента являются объектом авторских прав или
                    личными изображениями (фотографиями) других Лицензиатов или третьих лиц, Лицензиат при таком их
                    воспроизведении обязан дополнительно получать согласие таких лиц;
                </li>
            </ul>

            <p>Лицензиату запрещено:</p>
            <ul>
                <li>
                    воспроизводить, распространять, перерабатывать в коммерческих или некоммерческих целях элементы
                    Портала, являющиеся объектом авторских прав Лицензиара, других Лицензиатов или третьих лиц, при
                    отсутствии разрешения соответствующих правообладателей на совершение данных действий;
                </li>
                <li>
                    воспроизводить элементы дизайна или пользовательского интерфейса Портала при создании сайтов или
                    ведении любой коммерческой деятельности в сети Интернет или вне её;
                </li>
                <li>
                    распространять на/вне Портале(а) в коммерческих или некоммерческих целях аудиовизуальные отображения
                    и учётные данные других Лицензиатов, присутствующие на Портале, без согласия этих Лицензиатов;
                </li>
                <li>
                    передавать предоставленные Лицензиату права использования Портала, другим Лицензиатам или третьим
                    лицам посредством заключения сублицензионного договора или иным способом;
                </li>
                <li>нарушать закреплённые в настоящем Соглашении (раздел 5) правила использования Портала.</li>
            </ul>

            <h2>Правила использования Портала</h2>

            <p>
                Для использования Портала Лицензиат создает Аккаунт. Для входа на Портал Лицензиатом формируются
                уникальные авторизационные данные – логин и пароль, которые являются конфиденциальной информацией и не
                подлежат разглашению, за исключением случаев, предусмотренных действующим законодательством и/или
                настоящим Соглашением. Риск осуществления мошеннических и иных неправомерных действий с Аккаунтом
                Лицензиата в связи с утратой пароля несёт Лицензиат. Лицензиату рекомендуется выбирать пароль
                достаточной сложности, чтобы избежать возможность его подбора третьими лицами.
            </p>
            <p>
                Учётные данные и иные данные Лицензиата обрабатываются Лицензиаром в целях надлежащего исполнения
                настоящего Соглашения.
            </p>
            <p>
                В целях исполнения Лицензионного соглашения и предоставления Лицензиату доступа к использованию
                функционала Портала, Лицензиар развивает, совершенствует, оптимизирует и внедряет новый функционал
                Портала (включая сервисы и продукты информационного, коммуникационного, рекламного, образовательного,
                развлекательного и иного характера), в том числе с участием аффилированных лиц и/или партнёров. Для
                обеспечения реализации указанных целей Лицензиат соглашается и поручает Лицензиару осуществлять с
                соблюдением применимого законодательства обработку (включая сбор, запись, систематизацию, накопление,
                хранение, уточнение (обновление, изменение), сопоставление, извлечение, использование, обезличивание,
                блокирование, удаление и уничтожение) учётных и иных данных Лицензиата, включая результаты
                автоматизированной обработки таких данных, в том числе в виде целочисленных и/или текстовых значений и
                идентификаторов, их передачу аффилированным лицам и/или партнёрам во исполнение такого поручения на
                обработку, а также осуществлять сбор (получение) его учётных и иных данных от аффилированных лиц и/или
                партнёров.
            </p>
            <p>
                Под учётными данными понимаются сведения, которые Лицензиат предоставляет самостоятельно на этапе
                регистрации на Портале путем заполнения регистрационной формы для создания Аккаунта и Персональной
                страницы Лицензиата и некоторые из предоставляемых в процессе её использования. Под иными данными
                понимаются связанные с Лицензиатом данные, которые становятся доступными Лицензиару в процессе
                использования Лицензиатом Портала и/или сервисов аффилированных лиц и/или партнёров. Такие данные могут
                включать в себя в том числе информацию о технических средствах (устройствах) и способах технологического
                взаимодействия с Порталом и/или сервисами аффилированных лиц и/или партнёров (в т. ч. IP-адрес хоста,
                вид операционной системы Лицензиата, тип браузера, географическое положение, данные о провайдере и
                иное), об активности Лицензиата, а также иные данные, получаемые указанными способами. Лицензиару может
                быть доступна иная информация, относящаяся к Лицензиату и оставленная последним по своему усмотрению в
                процессе использования Портала, которая не обрабатывается Лицензиаром, в том числе для достижения
                указанных в настоящем соглашении целей. Обработка учётных и иных данных Лицензиата осуществляется в
                течение всего периода времени с момента регистрации Аккаунта Лицензиата и до момента его удаления, если
                иное не предусмотрено действующим законодательством. В целях надлежащего исполнения условий
                Лицензионного соглашения, Лицензиар принимает меры по обеспечению безопасности Портала. Для реализации
                указанных целей Лицензиат соглашается, что учётные и иные данные могут быть переданы третьим лицам, в
                том числе в случаях, предусмотренных применимым законодательством, в объёме, необходимом для выявления,
                расследования и пресечения противоправных действий. Лицензиат соглашается на отражение его учётных и
                иных данных, а также иной информации на Персональной странице Лицензиата в рамках функционала Портала, а
                также на то, что такие данные будут считаться общедоступными, если иной режим доступа к ним не был
                выбран Лицензиатом в пределах функционала Портала. Лицензиат соглашается, что информация, включая
                учётные и иные данные, может быть доступна другим пользователям сети Интернет с учётом существующего
                функционала Портала (который может изменяться время от времени Лицензиаром). Лицензиар может
                ограничивать использование третьими лицами информации из Портала, в том числе в коммерческих целях.
                Использование информации Лицензиаром определяется настоящим Соглашением.
            </p>
            <p>
                После регистрации Аккаунта Лицензиат вправе осуществлять наполнение Аккаунта, Персональной страницы и
                других элементов Портала Контентом, добавлять фотографии и иные материалы в соответствии с
                предоставленным функционалом и пользоваться иными функциями, предоставленными Лицензиаром при
                использовании Портала при условии соблюдения настоящего Соглашения.
            </p>
            <p>
                Вход на Портал Лицензиатом, ранее зарегистрировавшим Аккаунт, осуществляется каждый раз путём
                прохождения процедуры авторизации – введения логина и пароля Лицензиата, перехода по гиперссылке,
                полученной по электронной почте, в результате автоматической авторизации с использованием технологии
                cookies и иными доступными и разрешенными Лицензиаром способами.
            </p>
            <p>
                Лицо, авторизовавшееся на Портале, считается надлежащим пользователем Аккаунта, доступ к использованию и
                управлению которого были получены в результате такой авторизации, если отсутствуют сведения,
                подтверждающие иное.
            </p>
            <p>
                При использовании Лицензиатом Портала может применяться технология cookies в целях автоматической
                авторизации Лицензиата на Портале, а также для сбора статистических данных, в частности о посещаемости
                Портала.
            </p>
            <p>
                Лицензиат вправе ограничить или запретить использование технологии cookies путем применения
                соответствующих настроек браузера.
            </p>
            <p>
                При невозможности совершения авторизации в связи с утратой пароля, блокировкой Аккаунта, и по иным
                причинам, Лицензиат вправе обратиться в службу поддержки Лицензиара, либо следовать инструкциям,
                размещенным в разделе «Помощь» и иных разделах Портала. Способы восстановления доступа к Аккаунту,
                авторизации Лицензиата могут быть изменены, отменены или дополнены Лицензиаром в одностороннем порядке.
            </p>
            <p>
                Лицензиар предпринимает коммерчески обоснованные усилия для обеспечения функционирования Портала в
                круглосуточном режиме, однако не гарантирует отсутствие перерывов, связанных с техническими
                неисправностями, проведением профилактических работ, а также не гарантирует полную или частичную
                работоспособность Приложений. Лицензиар не гарантирует, что Портал или любые ее элементы будут
                функционировать в любое конкретное время в будущем или что они не прекратят работу.
            </p>
            <p>
                Портал, включая все скрипты, Приложения, Контент и её оформление, мобильной версии, предоставляются «как
                есть». Лицензиар не предоставляет никаких гарантий того, что Портал или её элементы могут подходить для
                конкретных целей использования. Лицензиар не может гарантировать и не обещает никаких специфических
                результатов от использования Портала или её элементов. Лицензиар не гарантирует соответствие размещённых
                на Портале Контента и Приложений, в том числе другими Лицензиатами, индивидуальным представлениям
                Лицензиата о морали и нравственности.
            </p>
            <p>
                При использовании Портала Лицензиат обязан соблюдать меры предосторожности в отношении Контента,
                особенно Контента, размещённого другими Лицензиатами, иных материалов и информации; при переходе по
                размещенным на Портале гиперссылкам, при использовании любых файлов, в том числе программного
                обеспечения, во избежание негативного воздействия на компьютер Лицензиата вредоносного программного
                обеспечения, недобросовестного доступа к Аккаунту, подборки пароля и прочих негативных для Лицензиата
                последствий.
            </p>
            <p>
                Лицензиат, размещая на Портале Контент, предоставляет Лицензиару право использовать Контент
                исключительно для целей функционирования Портала необходимыми для этого способами.
            </p>
            <p>
                Лицензиат не вправе загружать или иным образом доводить до всеобщего сведения (размещать на Портале,
                публиковать в рамках Портала) Контент и прочие результаты интеллектуальной деятельности Лицензиатов,
                Лицензиара и иных лиц-правообладателей, при отсутствии явным образом выраженного согласия
                правообладателя и/или необходимого объёма прав на такие действия.
            </p>
            <p>
                Воспроизведение, копирование, сбор, систематизация, хранение, передача информации с Портала в
                коммерческих целях и/или в целях извлечения базы данных Портала в коммерческих или некоммерческих целях,
                или её использования полностью или в любой части любым способом, не допускается без согласия Лицензиара.
                Запрещено использование автоматизированных скриптов (программ, ботов, краулеров и иных средств
                автоматического сбора информации) для сбора информации и (или) взаимодействия с Порталом без согласия
                Лицензиара.
            </p>

            <h2>Права и обязанности Лицензиара</h2>

            <p>
                Лицензиар осуществляет текущее управление Порталом, определяет её структуру, внешний вид, разрешает или
                ограничивает доступ Лицензиатов к Порталу при нарушении положений настоящего Соглашения, осуществляет
                иные принадлежащие ему права. Лицензиат соглашается с тем, что Лицензиар вправе использовать
                функциональные и технические возможности программных средств, обеспечивающих отображение размещенного в
                рамках Портала Контента, по своему усмотрению, в том числе для целей отображения рекламы.
            </p>
            <p>
                В части предоставления возможности взаимодействия между Лицензиатами, в том числе предоставления
                Лицензиатам возможности самостоятельно совершать те или иные действия в рамках Портала, Лицензиар
                выступает исключительно как лицо, предоставившее технологическую возможность такого взаимодействия с
                использованием Портала. Связанные с таким взаимодействием передача, хранение и обеспечение доступа
                посредством сети Интернет и программного обеспечения Портала к предоставляемой Лицензиатами информации,
                графическим изображениям и иным материалам, осуществляются без изменения таких материалов или влияния на
                их содержание со стороны Лицензиара.
            </p>
            <p>
                Лицензиар самостоятельно решает вопросы о порядке размещения на Портале рекламы, участия в партнёрских
                программах и другое.
            </p>
            <p>Лицензиар имеет право:</p>
            <ul>
                <li>
                    в любое время изменять оформление и пользовательский интерфейс Портала, её содержание, содержание
                    предоставляемых функций Портала, изменять или дополнять используемые скрипты, программное
                    обеспечение, Контент Лицензиара и другие объекты, используемые или хранящиеся на Портале, любые
                    Приложения и серверные приложения, с уведомлением Лицензиата или без такового;
                </li>
                <li>
                    удалять без каких-либо причин и без предупреждения любой Контент, в том числе Контент, который по
                    усмотрению Лицензиара нарушает и/или может нарушать законодательство Российской Федерации
                    (применимое законодательство), положения настоящего Соглашения, права других Лицензиатов или третьих
                    лиц, причинять им вред или создавать угрозу причинения вреда и/или безопасности;
                </li>
                <li>
                    по своему усмотрению удалять любую информацию (включая Личные сообщения Лицензиата, комментарии к
                    фотографиям Лицензиата, прочую информацию и иные материалы), в том числе размещаемую Лицензиатом на
                    Портале в нарушение законодательства Российской Федерации (применимого законодательства) и/или
                    положений настоящего Соглашения;
                </li>
                <li>
                    приостанавливать, ограничивать или прекращать доступ Лицензиата ко всем или к любому из разделов
                    Портала и/или элементов Портала, персональной странице, группам Портала, функциям Портала, включая
                    активированные и неактивированные данные и команды, удалять создаваемые Лицензиатом группы, в любое
                    время без объяснения причин, с предварительным уведомлением или без такового, если иное прямо не
                    предусмотрено применимым законодательством;
                </li>
                <li>
                    удалить Аккаунт Лицензиата по своему усмотрению, в том числе в случае совершения Лицензиатом
                    действий, нарушающих законодательство Российской Федерации (применимое законодательство) или
                    положения настоящего Соглашения;
                </li>
                <li>
                    предоставлять Лицензиату за плату право использования неактивированных данных и команд на условиях,
                    предусмотренных настоящим Соглашением и соответствующими разделами Портала и/или элементами Портала,
                    включая Приложения;
                </li>
                <li>
                    осуществлять рассылку Лицензиатам от Лицензиара и/или от партнёров Лицензиара, при условии наличия у
                    последнего согласия Лицензиата, сообщений рекламно-информационного или новостного характера, в том
                    числе посредством электронной почты, sms-рассылки, рассылки с использованием функционала Портала или
                    иным доступным способом.
                </li>
                <li>
                    в целях сбора статистических данных и идентификации Лицензиата устанавливать и сохранять информацию
                    об IP-адресах доступа Лицензиата к Порталу, использовать файлы технической информации (cookies),
                    размещаемые на персональном компьютере Лицензиата;
                </li>
                <li>
                    при использовании Портала делать замечания Лицензиатам, предупреждать, уведомлять, информировать их
                    о несоблюдении Лицензиатами настоящего Соглашения. Указания Лицензиара, данные Лицензиату во время
                    процесса использования Портала, обязательны для исполнения Лицензиатом;
                </li>
                <li>
                    предпринимать не запрещённые законом меры для защиты собственных интеллектуальных прав в отношении
                    Портала;
                </li>
                <li>
                    Лицензиар не занимается рассмотрением и разрешением споров и конфликтных ситуаций, возникающих между
                    Лицензиатами или третьими лицами, разместившими на Портале Приложения с согласия Лицензиара, при
                    использовании Лицензиатами таких Приложений, однако по своему усмотрению может содействовать в
                    разрешении возникших конфликтов. Лицензиар вправе приостановить, ограничить или прекратить доступ
                    Лицензиата к Порталу (действие права использования Портала) в случае получения от другого(-их)
                    Лицензиата(-ов) мотивированных жалоб на некорректное или противоправное поведение данного Лицензиата
                    на Портале.
                </li>
            </ul>
            <p>Лицензиар принимает на себя следующие обязательства:</p>
            <ul>
                <li>
                    на условиях, изложенных в настоящем Соглашении, предоставить Лицензиату права использования Портала,
                    включая права использования неактивированных данных и команд в пределах, установленных настоящим
                    Соглашением;
                </li>
                <li>
                    уведомлять Лицензиата путём опубликования информации или рассылки сообщений на Портале или иным
                    доступным Лицензиару способом об изменениях условий настоящего Соглашения.
                </li>
            </ul>

            <h2>Права и обязанности Лицензиата</h2>

            <p>Лицензиат вправе:</p>
            <ul>
                <li>
                    производить настройки Аккаунта и Персональной страницы, менять логин и пароль для доступа к
                    Аккаунту;
                </li>
                <li>размещать в Аккаунте Лицензиата информацию о себе, добавлять фотографии и иной контент;</li>
                <li>
                    использовать функции Портала и Приложения и размещать в них материалы согласно требованиям
                    размещения материалов, которые содержатся в соответствующем разделе Портала;
                </li>
                <li>
                    приобретать права использования неактивированных данных и команд и осуществлять реализацию этих прав
                    в соответствии с настоящим Соглашением;
                </li>
                <li>
                    осуществлять иные, не запрещённые законодательством Российской Федерации (применимым
                    законодательством) или настоящим Соглашением действия, связанные с использованием Портала.
                </li>
            </ul>
            <p>Лицензиат обязан:</p>
            <ul>
                <li>соблюдать условия настоящего Соглашения без каких-либо ограничений;</li>
                <li>в момент регистрации на Портале и создании Аккаунта указывать достоверную информацию;</li>
                <li>не превышать пределов использования Портала, установленных в разделе 4 настоящего Соглашения;</li>
                <li>
                    не нарушать иным образом права интеллектуальной собственности Лицензиара в отношении Портала или
                    каких-либо её элементов, в частности, Лицензиат не имеет права копировать, транслировать, рассылать,
                    публиковать, и иным образом распространять и воспроизводить размещенные Лицензиаром на Портале
                    материалы (текстовые, графические, аудио-видео) без письменного согласия Лицензиара;
                </li>
                <li>
                    самостоятельно предпринимать должные меры, обеспечивающие безопасность его Аккаунта и Персональной
                    страницы и предотвращающие несанкционированный доступ к ним третьих лиц (в частности, следить за
                    тем, чтобы пароль не сохранялся в браузере, в том числе при использовании технологии cookies, при
                    возможном использовании компьютерного устройства Лицензиата третьими лицами);
                </li>
                <li>
                    выполнять указания Лицензиара, касающиеся использования Портала, в частности, данные Лицензиаром
                    Лицензиату или группе Лицензиатов на Портале, в центре поддержке пользователей (Лицензиатов), в
                    новостном разделе Портала, на форуме Лицензиара. В случае невыполнения Лицензиатом таких указаний
                    Лицензиар имеет право приостановить, ограничить, прекратить предоставление Лицензиату прав
                    использования Портала, включая права использования неактивированных данных и команд;
                </li>
                <li>
                    по требованию Лицензиара в связи с заключением и исполнением настоящего Соглашения подтвердить свои
                    учётные данные, включающие фамилию, имя, отчество, иные данные;
                </li>
                <li>
                    не размещать фотографии, на которых помимо Лицензиата запечатлены другие лица, без их
                    предварительного согласия, за исключением случаев, когда в соответствии со статьей 152.1. ГК РФ или
                    иной нормой применимого законодательства, такое согласие не требуется;
                </li>
                <li>
                    уведомлять Лицензиара обо всех случаях совершения на Портале в отношении Лицензиата действий,
                    которые могут быть расценены как оскорбительные, унижающие, дискредитирующие и т.п.;
                </li>
                <li>
                    периодически знакомиться с содержанием настоящего Соглашения в сети Интернет по адресу
                    https://osmolegal.ru/helpme/agreement и следить за внесёнными в него изменениями. соблюдать иные
                    требования и выполнять иные обязательства, предусмотренные настоящим Соглашением и/или размещённые в
                    соответствующих разделах Портала.
                </li>
            </ul>
            <p>
                Лицензиат гарантирует, что обладает всеми необходимыми полномочиями и дееспособностью для заключения
                настоящего Соглашения.
            </p>
            <p>Лицензиату запрещается:</p>
            <ul>
                <li>
                    использовать любые автоматические или автоматизированные средства для сбора информации, размещенной
                    на Портале;
                </li>
                <li>
                    осуществлять пропаганду или агитацию, возбуждающую социальную, расовую, национальную или религиозную
                    ненависть и вражду, пропаганду войны, социального, расового, национального, религиозного или
                    языкового превосходства, распространять иную информацию, запрещенную к распространению применимым
                    законодательством;
                </li>
                <li>
                    размещать на Портале информацию ограниченного доступа (конфиденциальную информацию) третьих лиц,
                    если Лицензиат не обладает достаточными правами в силу закона или договора на раскрытие данной
                    информации;
                </li>
                <li>
                    размещать, воспроизводить, перерабатывать, распространять, опубликовывать на Портале, доводить до
                    всеобщего сведения, передавать, продавать или иным способом использовать целиком или по частям
                    Контент Лицензиара, Лицензиатов и третьих лиц, являющийся объектом авторских и иных исключительных
                    прав, без их предварительного разрешения, кроме случаев, установленных настоящим Соглашением,
                    действующим законодательством Российской Федерации (применимым законодательством), а также случаев,
                    когда правообладатель явным образом выразил своё согласие на свободное использование собственного
                    Контента любым лицом. Загружая Контент на Портал, а также размещая Контент на Портале иным образом,
                    Лицензиат подтверждает, что обладает всеми необходимыми для этого правами, включая право на
                    доведение до всеобщего сведения, что такое размещение не нарушает и не будет нарушать права и
                    законные интересы правообладателей и третьих лиц, и что все необходимые разрешения и согласия со
                    стороны соответствующих правообладателей и третьих лиц были в надлежащем порядке и явным образом для
                    этого получены. На Портале запрещается размещать Контент, если Лицензиат не имеет для этого
                    необходимых прав и/или согласия правообладателя.
                </li>
                <li>
                    размещать на Портале в открытом доступе текстовые сообщения, графические изображения или иные
                    материалы, содержание которых является оскорбительным для других Лицензиатов или иных лиц или может
                    быть расценено в качестве такового, а также сообщения, изображения и иные материалы, которые
                    дискредитируют Лицензиатов или иных лиц, содержат угрозы, призывы к насилию, совершению
                    противоправных деяний, антиобщественных, аморальных поступков, а также совершению любых иных
                    действий, противоречащих основам правопорядка и нравственности;
                </li>
                <li>
                    размещать на Портале сообщения, графические изображения или другие материалы (в том числе не
                    соответствующие действительности), размещение которых наносит или может нанести ущерб чести,
                    достоинству и деловой репутации гражданина или деловой репутации организации;
                </li>
                <li>размещать на Портале сообщения, содержащие нецензурные слова и выражения;</li>
                <li>
                    размещать на Портале материалы эротического и порнографического характера или гипертекстовые ссылки
                    на Интернет-сайты, содержащие такие материалы;
                </li>
                <li>
                    размещать на Портале информацию, которая пропагандирует суицид, содержит описание способов суицида и
                    любое подстрекательство к его совершению;
                </li>
                <li>
                    размещать на Портале информацию, связанную с оккультной тематикой и деятельностью (магия, гадания,
                    сатанизм, привороты, заговоры, колдовство, ритуалы, астрология, гороскопы и т.п.);
                </li>
                <li>
                    размещать на Портале рекламу и иную информацию о наркотических и психотропных веществах, в том
                    числе, информацию о распространении наркотиков, рецепты их изготовления и советы по употреблению, а
                    также размещать информацию экстремистской направленности;
                </li>
                <li>размещать на Портале информацию, которая нарушает права несовершеннолетних лиц;</li>
                <li>размещать на Портале любую информацию противоправного характера;</li>
                <li>
                    размещать на Портале персональные данные, в том числе контактные данные, других Лицензиатов или иных
                    лиц без их предварительного согласия;
                </li>
                <li>
                    указывать при регистрации Аккаунта или вводить впоследствии заведомо ложную информацию или чужие
                    данные;
                </li>
                <li>
                    размещать на Портале в качестве собственной фотографии изображения других лиц без согласия таких лиц
                    или вымышленных персонажей, изображения животных, предметов, абстрактные изображения, а также любые
                    иные графические изображения, не являющиеся изображениями Лицензиата, размещающего данные
                    изображения, без соответствующего согласия правообладателей (авторов) таких изображений;
                </li>
                <li>
                    регистрировать Аккаунт Лицензиата в целях использования группой лиц или организацией, за исключением
                    случаев, когда Аккаунт регистрируется исключительно с целью размещения материалов и иных текстовых
                    сообщений, согласованных с Лицензиаром;
                </li>
                <li>регистрировать более одного Аккаунта Лицензиата одним и тем же лицом;</li>
                <li>
                    осуществлять действия, направленные на дестабилизацию функционирования Портала, осуществлять попытки
                    несанкционированного доступа к управлению Порталом или её закрытым разделам (в том числе к разделам,
                    доступ к которым разрешён только Лицензиару), а также осуществлять любые иные аналогичные действия;
                </li>
                <li>
                    осуществлять несанкционированный доступ к Аккаунтам иных Лицензиатов путём подборки или введения
                    пароля, а также предпринимать попытки такого доступа;
                </li>
                <li>
                    осуществлять рассылку спама – массовую рассылку коммерческой, политической, рекламной и иной
                    информации (в том числе гиперссылок, ведущих на интернет-сайты с такой информацией и/или на
                    интернет-сайты, содержащие вредоносное программное обеспечение) в Личных сообщениях, комментариях,
                    сообщениях на форумах, Персональных страницах Лицензиатов или осуществляя иные действия,
                    направленные на распространение такой информации, если Лицензиаты-получатели не выражали своего
                    согласия на получение такого рода информации;
                </li>
                <li>использовать Портал с целью поиска должников или для иных подобных целей;</li>
                <li>
                    размещать на Портале информацию, способствующую продвижению, рекламе и популяризации табака и
                    табачных изделий, лекарственных препаратов, наркотических веществ, крепких и слабоалкогольных
                    напитков;
                </li>
                <li>
                    размещать на Портале иную информацию, которая, по мнению Лицензиара, не соответствует политике и
                    целям создания Портала.
                </li>
            </ul>

            <h2>Гарантии и ответственность</h2>

            <p>
                Лицензиат гарантирует, что размещение им на Портале Контента, иных охраняемых законом результатов
                интеллектуальной деятельности, их частей или копий, а также иных материалов не нарушает чьих-либо прав и
                законных интересов. В случае поступления претензий третьих лиц в связи с нарушением условий настоящего
                Соглашения в части размещения информации и/или Контента третьих лиц, Лицензиар вправе осуществить
                передачу в рамках, допустимых законом, доступной ему контактной информации Лицензиата лицам,
                обратившимся с претензиями, в целях урегулирования возникших разногласий.
            </p>
            <p>
                Лицензиат гарантирует, что им будут приняты надлежащие меры для обеспечения конфиденциальности учётных
                данных (логина и пароля), используемых им для авторизации на Портале, и предотвращения возможности
                авторизации другими лицами.
            </p>
            <p>
                Лицензиар соблюдает тайну переписки, осуществляемой Лицензиатами с сотрудниками отдела технической
                поддержки Лицензиара посредством сообщений в специальном чате технической поддержки.
            </p>
            <p>
                Лицензиар гарантирует использование со стороны Лицензиара указанных в Аккаунте Лицензиата адреса
                электронной почты и номера мобильного телефона для связи Лицензиара с Лицензиатом, отправки Лицензиату
                уведомлений, сообщений и sms-сообщений, а также для предоставления Лицензиату функций Портала с согласия
                Лицензиата, которое может быть выражено при помощи функциональных возможностей Портала, если иное не
                предусмотрено настоящим Соглашением или действующим законодательством.
            </p>
            <p>
                Лицензиар не участвует в формировании содержания Персональной страницы Лицензиата и загрузке им
                Контента, не контролирует действия Лицензиата, не осуществляет и не имеет технической возможности
                осуществлять автоматическую цензуру информации в открытых разделах Сайта и Портала, в Персональных
                страницах Лицензиата, цензуру Личных сообщений и не несёт ответственности за действия или бездействие
                Лицензиата.
            </p>
            <p>
                В силу особенностей функционирования Портала, Лицензиар не осуществляет и не имеет технической
                возможности осуществлять предварительную модерацию информации и Контента, размещаемого Лицензиатом, и не
                несёт ответственности за его содержание.
            </p>
            <p>
                Лицензиар не несёт ответственности за возможные сбои и перерывы в работе Портала и вызванные ими потерю
                информации. Лицензиар не несёт ответственности за любой ущерб компьютеру Лицензиата, мобильным
                устройствам, любому другому оборудованию или программному обеспечению, вызванный или связанный с
                использованием Сайта или сайтов, доступных по гиперссылкам, размещенным на Сайте.
            </p>
            <p>
                Лицензиар не несёт ответственности за подбор пароля доступа к Аккаунту Лицензиата третьими лицами и
                любые действия, совершенные ими с использованием Аккаунта Лицензиата.
            </p>
            <p>
                Лицензиар не несёт ответственности за любой ущерб, включая упущенную выгоду, или вред, вызванные в связи
                с использованием Портала, размещенного в ней Контента или иных материалов, к которым Лицензиат или иные
                лица получили доступ при помощи Портала, даже если Лицензиар предупреждал или указывал на возможность
                причинения такого ущерба или вреда.
            </p>
            <p>
                Лицензиат несёт ответственность за противоправные действия, осуществляемые с использованием его
                Аккаунта, а также в связи с размещением Контента с использованием его Аккаунта на Портале, на
                Персональной странице Лицензиата или других Лицензиатов и в иных разделах Портала и/или Сайта.
            </p>
            <p>
                Лицензиат несёт персональную ответственность за любой Контент или иную информацию, которые он размещает
                на Портале или иным образом доводит до всеобщего сведения на Портале или с её помощью. Лицензиат
                обязуется самостоятельно разрешать претензии третьих лиц, связанные с неправомерным размещением Контента
                и информации на Портале.
            </p>
            <p>
                Гиперссылки на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого
                характера, размещённые на Портале, пересылаемые в Личных сообщениях Лицензиатами, размещаемые на
                Персональных страницах Лицензиатами и т.п. не являются одобрением или рекомендацией данных продуктов
                (услуг) со стороны Лицензиара. Лицензиар не несёт ответственности за ущерб, причиненный Лицензиату в
                результате перехода по таким гиперссылкам.
            </p>
            <p>
                Лицензиар не несёт ответственности за возможные противоправные действия Лицензиата или третьих лиц при
                использовании Портала.
            </p>
            <p>
                Лицензиар не несёт ответственности за высказывания Лицензиата, опубликованные на Портале. Лицензиар не
                несёт ответственности за поведение Лицензиата на Портале, неуважительное отношение к другим Лицензиатам.
            </p>
            <p>
                Лицензиар не несёт ответственности за утерю Лицензиатом возможности доступа к своему Аккаунту на Портале
                (утрату логина, пароля, иной информации, необходимой для использования Аккаунта или Персональной
                страницы).
            </p>
            <p>
                Лицензиар не несёт ответственности за неполное, неточное, некорректное указание Лицензиатом своих данных
                при создании Аккаунта и Персональной страницы.
            </p>
            <p>
                Лицензиар не несёт ответственности за отсутствие у Лицензиата доступа в Интернет, за качество услуг
                провайдеров связи сети Интернет, с которыми Лицензиатом заключены соглашения о предоставлении услуг по
                доступу к сети Интернет.
            </p>
            <p>
                Лицензиар не осуществляет обмен на наличные или безналичные деньги, или иные ценности Портала,
                полученных Лицензиатом в ходе её использования, в том числе виртуальных ценностей, однако такая
                возможность может быть предусмотрена отдельными правилами разделов Портала.
            </p>
            <p>
                Лицензиар не возмещает Лицензиату расходы, связанные с приобретением Лицензиатом прав использования,
                неактивированных данных и команд, в том числе в случае приостановления или прекращения настоящего
                Соглашения по любым основаниям, если иное прямо не предусмотрено применимым законодательством.
            </p>
            <p>
                Лицензиар не несёт ответственности за возникновение прямого или косвенного ущерба и упущенной выгоды
                Лицензиата либо иных третьих лиц, причинённого в результате:
            </p>
            <ul>
                <li>использования либо невозможности использования Портала;</li>
                <li>
                    несанкционированного доступа любых третьих лиц к личной информации Лицензиата, включая Аккаунт и
                    Персональную страницу Лицензиата;
                </li>
                <li>заявления или поведения любого третьего лица на Портале;</li>
                <li>
                    удаления Аккаунта, Контента или прекращением функционирования определенного элемента Портала или
                    Портала в целом.
                </li>
            </ul>
            <p>
                При любых обстоятельствах ответственность Лицензиара перед Лицензиатом ограничена суммой в 100 (Сто)
                рублей, при этом упущенная выгода возмещению не подлежит, если иное прямо не предусмотрено применимым
                законодательством.
            </p>
            <p>
                Лицензиар не обязан предоставлять Лицензиату какие-либо доказательства, документы и прочее,
                свидетельствующие о нарушении Лицензиатом условий Соглашения, в результате которого Лицензиату было
                отказано в предоставлении прав использования Портала или её отдельных функций/разделов/страниц, а также
                прав на неактивированные данные и команды. Претензии Лицензиата, направляемые Лицензиару, принимаются и
                рассматриваются при условии возможности установить факт принадлежности Аккаунта конкретному лицу.
                Принимая во внимание возможное наличие Аккаунтов со схожими учётными данными, Лицензиар вправе требовать
                предоставления дополнительных сведений и информации, в том числе в отношении Аккаунта Лицензиата,
                позволяющих определить, в связи с каким Аккаунтом поступила претензия, или установить принадлежность
                Аккаунта лицу, обратившемуся с претензией.
            </p>
            <p>
                Лицензиар не несёт ответственность за возможный вред здоровью Лицензиата, который может быть причинен
                Лицензиату в связи с использованием Приложений третьих лиц. Лицензиат настоящим уведомлен, что
                Приложения могут предусматривать различные звуковые и/или видео эффекты, которые, при определенных
                обстоятельствах, могут вызывать у лиц, склонных к эпилептическим или иным расстройствам нервного
                характера, обострение указанных состояний, и Лицензиат гарантирует, что указанными расстройствами он не
                страдает, или же обязуется не использовать Приложения. Лицензиат настоящим уведомлен, что регулярное
                длительное (непрерывное) нахождение у персонального компьютера может вызывать различные осложнения
                физического состояния, в том числе ослабление зрения, сколиоз, различные формы неврозов и прочие
                негативные воздействия на организм. Лицензиат гарантирует, что он будет использовать Приложения
                исключительно на протяжении разумного времени, с перерывами на отдых или иные мероприятия по
                профилактике физического состояния, если таковые Лицензиату рекомендованы или предписаны.
            </p>

            <h2>Права использования неактивированных данных и команд</h2>

            <p>
                Настоящий раздел Соглашения регламентирует порядок и условия предоставления Лицензиаром Лицензиату прав
                использования неактивированных данных и команд.
            </p>
            <p>
                По желанию Лицензиата Лицензиар предоставляет Лицензиату за вознаграждение, если иное не предусмотрено
                Лицензиаром, права использования неактивированных данных и команд, позволяющие увеличить на Портале
                количество виртуальных ценностей, используемых в рамках функциональных возможностей Портала. Размер и
                условия внесения Вознаграждения определяются Лицензиаром самостоятельно и зависят от объёма данных и
                команд, необходимых Лицензиату для получения определенного результата, обусловленного функциональными
                возможностями Портала. Указанные условия содержатся в настоящем Соглашении и/или в соответствующих
                разделах Портала. Права использования неактивированных данных и команд передаются Лицензиаром с момента
                отражения виртуальных ценностей в виде «ос» в Аккаунте Лицензиата. С этого момента Лицензиат получает
                права использования неактивированных данных и команд в объёме, соответствующем количеству виртуальных
                ценностей в виде «ос», на условиях данного Соглашения. Виртуальные ценности в виде «ос» отражаются в
                Аккаунте Лицензиата после получения Лицензиаром от третьих лиц (платежных систем, операторов,
                агрегаторов платежей) информации о совершении (подтверждении) платежа. Соответствие размера
                вознаграждения количеству виртуальных ценностей в виде «ос» определяется Лицензиаром. Право
                использования неактивированных данных и команд предоставляется Лицензиату на срок действия настоящего
                Соглашения, если такое право не прекращено в соответствии с правилами Портала. Внесение Вознаграждения
                осуществляется Лицензиатом денежными средствами в валюте соответствующей территории, согласно
                установленному Порталом порядку конвертации. Размер вознаграждения определяется Лицензиаром исходя
                объёма неактивированных данных и команд, право использования которых передается Лицензиату. С момента
                отражения виртуальных ценностей в виде «ос» в Аккаунте Лицензиата Лицензиат не вправе требовать от
                Лицензиара возврата Вознаграждения, уплаченного за такие права, если иное прямо не предусмотрено
                применимым законодательством. Внесение Вознаграждения осуществляется Лицензиатом путём перечисления
                денежных средств через поддерживаемые способы оплаты. Список доступных для оплаты способов приведён в
                соответствующих разделах Портала. Способы и условия оплаты Вознаграждения через платежные системы
                публикуются Лицензиаром на Портале. При оплате Вознаграждения Лицензиат обязуется следовать платёжным
                инструкциям по порядку и способам оплаты, в том числе и по правилам ввода сообщения и номера коротких
                текстовых сообщений (SMS), включая порядок ввода заглавных и строчных букв, цифр и языка ввода.
                Предоставление Лицензиату права использования неактивированных данных и команд обеспечивается при
                выполнении прилагаемых инструкций и условий оплаты. Лицензиар не несёт ответственности за правильность
                выполнения Лицензиатом условий проведения оплаты. По вопросам правил и порядка использования платежных
                систем для осуществления оплаты прав использования неактивированных данных и команд Лицензиату надлежит
                обращаться к держателям таких платёжных систем или операторам/агрегаторам платежей. Лицензиар не даёт
                Лицензиату разъяснений по вопросам, связанным с правилами и порядком использования таких платёжных
                систем, а также не возмещает Лицензиату денежные средства, уплаченные для приобретения таких прав
                посредством платёжных систем, операторов, агрегаторов платежей, если такие платежи были осуществлены с
                нарушениями правил, установленных такими лицами, в результате чего денежные средства не поступили
                Лицензиару. Предоставление Лицензиату права использования неактивированных данных и команд
                осуществляется только при условии полной оплаты таких прав Лицензиатом в соответствии с условиями
                настоящего Соглашения, если иной порядок не содержится в Соглашении или не определен Лицензиаром
                отдельно. При этом до получения подтверждения об оплате прав использования Лицензиар вправе не
                предоставлять их Лицензиату, либо предоставлять их в ограниченном объёме. В том случае, если в
                результате технической ошибки, или сбоя Портала или любого её элемента, или сознательных действий
                Лицензиата им был получен доступ к неактивированным данным и командам без приобретения права
                использования в установленном настоящим Соглашением порядке, Лицензиат обязуется сообщить об этом факте
                Лицензиару и оплатить ему Вознаграждение, либо устранить все последствия неправомерного использования
                полученных прав. Лицензиар вправе самостоятельно без уведомления Лицензиата устранить такие последствия.
                Лицензиат обязан сохранять документы, подтверждающие оплату им Вознаграждения в течение всего времени
                использования Портала, и по запросу Лицензиара предоставить ему такие документы, а также информацию об
                обстоятельствах совершения такого платежа Лицензиатом. Исключительное право на распространение
                неактивированных данных команд принадлежит Лицензиару, в связи с чем никакие предложения третьих лиц о
                предоставлении прав использования таких данных и команд не могут быть расценены Лицензиатом как
                предложения, исходящие от Лицензиара. В случае возникновения спорных, неясных ситуаций, либо направления
                Лицензиату каких-либо предложений третьих лиц, связанных с оплатой прав использования неактивированных
                данных и команд, либо размещения таких объявлений и предложений в сети Интернет, за исключением
                размещенных от имени Лицензиара на Сайте, Лицензиат обязан незамедлительно уведомить об этом Лицензиара.
                В случае если Лицензиат в нарушение настоящего положения, произвел оплату по указанному объявлению с
                использованием реквизитов, указанных в таком объявлении, претензии Лицензиата Лицензиару по поводу
                отсутствия у Лицензиата неактивированных данных и команд не принимаются, и Лицензиар не компенсирует
                Лицензиату денежные средства, потраченные Лицензиатом при таких обстоятельствах. В случае если
                Лицензиаром будет установлено, что Лицензиат получает права использования неактивированных данных и
                команд от третьих лиц, Лицензиар вправе по своему усмотрению приостановить, ограничить, либо прекратить
                в отношении Лицензиата права использования Портала. Приобретение Лицензиатом прав использования
                неактивированных данных и команд не освобождает его от соблюдения настоящего Соглашения и применения в
                отношении него любых мер, указанных в настоящем Соглашении, включая приостановление или прекращение прав
                использования Портала и/или удаление Лицензиаром Аккаунта Лицензиата, если иное прямо не предусмотрено
                применимым законодательством. Лицензиат гарантирует Лицензиару, что он имеет право использовать
                выбранные им средства для оплаты Вознаграждения, не нарушая при этом законодательства РФ и/или
                законодательства иной страны, гражданином которой является Лицензиат, и прав третьих лиц. Лицензиар не
                несёт ответственности за дополнительные комиссии, взимаемые операторами средств оплаты и возможный ущерб
                Лицензиату и/или третьим лицам, причиненный в результате использования Лицензиатом не принадлежащих ему
                средств оплаты. Лицензиар не несёт ответственности за возможные противоправные действия Лицензиата при
                выплате Вознаграждения. Лицензиар оставляет за собой право в одностороннем порядке приостановить или
                прекратить в отношении Лицензиата права использования неактивированных данных и команд, если существует
                подозрение на совершение Лицензиатом противозаконных действий вплоть до выяснения обстоятельств, если
                иное прямо не предусмотрено применимым законодательством. В случае если Лицензиар имеет основания
                полагать, что Лицензиат совершает противозаконные действия, связанные с оплатой Вознаграждения,
                Лицензиар имеет право передать соответствующую информацию в правоохранительные органы для проведения
                проверки по данному факту.
            </p>
            <p>Особенности оплаты Лицензиатом Вознаграждения с помощью банковских карт:</p>
            <ul>
                <li>операции по банковским картам совершаются держателем карты либо уполномоченным им лицом;</li>
                <li>
                    авторизация операций по банковским картам осуществляется банком. Если у банка есть основания
                    полагать, что операция носит мошеннический характер, то банк вправе отказать в осуществлении данной
                    операции. Мошеннические операции с банковскими картами являются уголовным преступлением. во
                    избежание случаев мошенничества при оплате банковскими картами платежи, оплаченные банковской
                    картой, могут проверяться Лицензиаром в рамках имеющихся возможностей. Лицензиат-держатель карты,
                    оформивший такой платеж, обязан по запросу, поступившему от Лицензиара, предоставить копию
                    необходимых Лицензиару документов для подтверждения правомерного использования банковской карты. В
                    случае непредставления запрошенных документов Лицензиатом в течение 14 дней с даты оформления
                    платежа или наличия сомнений в их подлинности, если иной срок не указан Лицензиаром, последний
                    вправе приостановить предоставление Лицензиату прав использования Портала, любой ее части/раздела
                    и/или неактивированных данных и команд до выяснения обстоятельств. Лицензиат соглашается, понимает и
                    принимает то обстоятельство, что Портал и Приложения не являются азартной игрой, игрой на деньги,
                    конкурсом, пари.
                </li>
            </ul>
            <p>
                Приобретение прав использования неактивированных данных и команд является реализацией собственного
                волеизъявления и желания Лицензиата и не является необходимым или обязательным условием для
                использования Портала и ее основного функционала.
            </p>

            <h2>Территория и срок действия Соглашения</h2>

            <p>
                Лицензиат вправе использовать Портал способами, описанными в настоящем Соглашении, на всей территории
                Российской Федерации, а также иных территориях, на которых она доступна с использованием стандартных
                компьютерных средств, программ и устройств. Настоящее Соглашение действует с момента принятия его
                условий Лицензиатом и действует в течение 1 (одного) календарного года.
            </p>
            <p>
                Действие настоящего Соглашения автоматически продлевается ещё на каждый последующий 1 (один) год, если
                до истечения указанного срока действия Соглашения:
            </p>
            <ul></ul>
            <li>
                Лицензиар не примет решение об изменении положений настоящего Соглашения, о необходимости заключения с
                Лицензиатами нового соглашения, прекращении администрирования и обслуживания Портала и прекращении к ней
                доступа, прекращении настоящего Соглашения в отношении Лицензиата, или прекращения доступа к
                использованию Портала в отношении Лицензиата.
            </li>
            <li>Лицензиат не примет решение о прекращении использования Портала и не удалит свой Аккаунт.</li>
            <li>
                Лицензиар вправе в любое время без уведомления Лицензиата и без объяснения причин прекратить настоящее
                Соглашение в одностороннем внесудебном порядке с немедленным прекращением доступа и возможности
                использовать Портал и без возмещения каких-либо затрат, убытков или возврата полученного по Соглашению,
                в том числе в случаях закрытия Портала, любого, в том числе однократного, нарушения Лицензиатом условий
                настоящего Соглашения, а также любых других случаях, указанных Лицензиаром на страницах Портала, если
                иное прямо не предусмотрено применимым законодательством.
            </li>
            <li>
                Лицензиар вправе в любое время без уведомления Лицензиата и без объяснения причин приостановить доступ и
                возможность использовать Портал, удалить Аккаунт Лицензиара без возмещения каких-либо затрат, убытков
                или возврата, полученного по Соглашению, в том числе в случае любого, в том числе однократного,
                нарушения Лицензиатом условий настоящего Соглашения, если иное прямо не предусмотрено применимым
                законодательством.
            </li>
            <li>
                Лицензиат соглашается и полностью признает, что все исключительные права или необходимые лицензии на
                программное обеспечение, составляющее элемент Портала и/или используемое для ее администрирования и
                функционирования, включая входящие в его состав аудиовизуальные отображения, а также графический дизайн
                Портала, фотографии, анимации, видеоизображения, видеоклипы, звуковые записи, звуковые эффекты, музыка,
                текстовое наполнение Портала и Приложений Портала, за исключением Контента, загружаемого в Портал
                Лицензиатами или третьими лицами, принадлежат Лицензиару, если иное в явном виде не указано в
                Соглашении.
            </li>
            <p>
                Настоящее Соглашение не предусматривает уступку каких-либо исключительных прав или выдачу исключительной
                лицензии на любые составляющие Портала от Лицензиара к Лицензиату.
            </p>
            <p>
                В случае, если Лицензиату в соответствии с законами его государства запрещено пользоваться сетью
                Интернет или социальными сетями или существуют иные законодательные ограничения, включая ограничения по
                возрасту допуска к такому программному обеспечению, Лицензиат не вправе использовать Портал. В таком
                случае Лицензиат самостоятельно несёт ответственность за использование Портала на территории своего
                государства в нарушение местного законодательства.
            </p>

            <h2>Заключительные положения</h2>

            <p>
                Если иное прямо не предусмотрено применимым законодательством, настоящее Соглашение может быть изменено
                Лицензиаром с предварительного уведомления Лицензиата в его Аккаунте (Личном кабинете) не позднее чем за
                5 (пять) дней до изменений. Если в указанный срок от Лицензиата не поступит отказа от принятия
                изменений, настоящее Соглашение изменяется в дату, указанную в уведомлении. Фактическое использование
                Лицензиатом Портала после внесения изменений в условия настоящего Соглашения или правила использования,
                также означает согласие Лицензиата с новыми условиями, если иное прямо не предусмотрено применимым
                законодательством Недействительность одного или нескольких положений Соглашения, признанная в
                установленном порядке вступившим в силу решением суда, не влечёт для Сторон недействительности
                соглашения в целом. В случае признания одного или нескольких положений Соглашения в установленном
                порядке недействительными, Стороны обязуются исполнять взятые на себя по Соглашению обязательства
                максимально близким к подразумеваемым Сторонами при заключении и/или согласованном изменении Соглашения
                способом. Настоящее Соглашение и взаимоотношения Сторон в связи с исполнением настоящего Соглашения и
                использованием Портала регулируются законодательством Российской Федерации.
            </p>
            <p>
                В отношении формы и способа заключения настоящего Соглашения применяются нормы Гражданского Кодекса РФ
                («ГК РФ»), регулирующие порядок и условия заключения договора путем акцепта публичной оферты.
            </p>
            <p>
                Все споры сторон по настоящему соглашению подлежат разрешению путём переписки и переговоров с
                использованием обязательного досудебного (претензионного) порядка. В случае невозможности достичь
                согласия между сторонами путём переговоров в течение 60 (шестидесяти) календарных дней с момента
                получения другой Стороной письменной претензии, рассмотрение спора должно быть передано любой
                заинтересованной стороной в Железнодорожный районный суд г. Красноярска (с исключением подсудности дела
                любым иным судам), если иное прямо не предусмотрено применимым законодательством.
            </p>
            <p>
                Неотъемлемой частью настоящего Соглашения является расположенный под каждым соответствующим Приложением
                на странице этого Приложения на Портале Порядок рассмотрения предложений, претензий и споров, связанных
                с размещенными на Портале Приложениями, разработанными третьими лицами. В соответствии с вышеуказанным
                Порядком, все обращения, заявления, предложения, претензии и иные запросы, связанные с использованием
                Приложения, Лицензиат направляет напрямую разработчикам таких Приложений, используя для этого контактные
                данные, указанные на странице соответствующего Приложения на Портале.
            </p>
            <p>
                Редакция настоящего Соглашения доступна в сети Интернет по адресу размещения Портала, в соответствующем
                разделе, в Приложениях Лицензиара и иных местах, определенных Лицензиаром и позволяющих Лицензиату
                ознакомиться с его содержанием.
            </p>
            <p>
                Все претензии, касающиеся исполнения Лицензионного соглашения, правил использования программных
                продуктов, гарантийной политики и иных вопросов взаимоотношений с Лицензиаром оформляются в письменном
                виде, заверяются подписью и печатью (при наличии) заявителя или его представителя с приложением
                доказательств полномочий представителя, а также доказательств, подтверждающих обстоятельства, на которых
                основана претензия, и направляются исключительно по почтовому адресу Лицензиара, указанному в разделе
                «Оператор» Портала (660099, ООО «Синай», г. Красноярск, а/я 10175). Претензии, оформленные с нарушением
                установленного настоящим пунктом порядка, не имеют юридической силы и не рассматриваются.
            </p>
            <p>
                Любые обращения, вопросы и пожелания, связанные с фанукционированием Автоматизированного программного
                комплекса «osmolegal.ru», Лицензиат может направить, воспользовавшись формой обратной связи раздела
                поддержки.
            </p>
            <p>
                Регистрация на Портале бесплатная, и занимает несколько минут. Пройдя регистрацию, вы сможете
                подготовить процессуальные документы, подать документы в любой суд Российской Федерации, подобрать
                юриста или иного квалифицированного специалиста, а также воспользоваться иными возможностями Портала.
            </p>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    setup() {
        return { PUBLIC_PATH: PUBLIC_PATH };
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';
</style>
