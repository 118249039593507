<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 1L1 7.857v8C1 16.488 1.512 17 2.143 17h4.571v-3.429a2.286 2.286 0 014.572 0V17h4.571c.631 0 1.143-.512 1.143-1.143v-8L9 1z"
            fill="#2F2F33"
        />
        <path
            d="M9 1l.325-.38a.5.5 0 00-.65 0L9 1zM1 7.857l-.325-.38-.175.15v.23H1zM6.714 17v.5a.5.5 0 00.5-.5h-.5zm4.572 0h-.5a.5.5 0 00.5.5V17zM17 7.857h.5v-.23l-.175-.15-.325.38zM2.143 17.5h4.571v-1H2.143v1zM17.325 7.478l-8-6.858-.65.76 8 6.857.65-.76zM8.675.62l-8 6.858.65.759 8-6.857-.65-.76zM7.215 17v-3.429h-1V17h1zm3.57-3.429V17h1v-3.429h-1zm.5 3.929h4.572v-1h-4.571v1zm6.215-1.643v-8h-1v8h1zm-17-8v8h1v-8h-1zM9 11.786c.986 0 1.786.8 1.786 1.785h1A2.786 2.786 0 009 10.786v1zm0-1a2.786 2.786 0 00-2.786 2.785h1c0-.986.8-1.785 1.786-1.785v-1zm6.857 6.714c.908 0 1.643-.735 1.643-1.643h-1a.643.643 0 01-.643.643v1zm-13.714-1a.643.643 0 01-.643-.643h-1c0 .908.736 1.643 1.643 1.643v-1z"
            fill="#2F2F33"
        />
    </svg>
</template>
