<template>
    <div class="sensitive-message">
        <div class="sensitive-message__icon">
            <img src="/build/img/lock.png" alt="">
        </div>
        <div class="sensitive-message__text">Вся информация в анкете строго конфиденциальна и не передается 3-м лицам</div>
    </div>
</template>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.sensitive-message {
    display: flex;
    align-items: center;
    color: $primary;
}

.sensitive-message__icon {
    width: 33px;
    margin-right: 16px;
    flex-shrink: 0;

    svg,
    img {
        width: 100%;
        height: auto;
    }
}

.sensitive-message__text {
    max-width: 271px;
    font-size: 11px;
    line-height: 1.63;
}
</style>