<template>
    <div class="tariff" :class="{ 'tariff--popular': popular }">
        <div v-if="popular" class="tariff-popular">
            <div class="tariff-popular__icon">
                <ThumbUpSVG />
            </div>
            <div class="tariff-popular__content">
                <div class="tariff-popular__title">Самый популярный</div>
                <div v-if="popularCaption" class="tariff-popular__caption">{{ popularCaption }}</div>
            </div>
        </div>
        <div class="tariff-content">
            <div class="tariff-title-container">
                <div v-if="name" class="text-uppercase tariff-title">{{ name }}</div>
            </div>
            <div v-if="text" class="tariff-secondary">{{ text }}</div>
        </div>
        <div class="tariff-rate">
            <template v-if="price">
                <template v-if="discountPercent && price > 1">
                    <span v-if="discountPercent" class="tariff-price-old">{{ price }} ₽</span>
                    <span class="tariff-price">{{ Math.round(price - price * (discountPercent / 100)) }} ₽</span>
                </template>
                <span v-else class="tariff-price">{{ price }} ₽</span>
            </template>
            <span v-if="code && map[code]" class="tariff-rate-sm">/ {{ map[code] }}</span>
        </div>
        <!-- <label class="checkbox tariff-checkbox">
                <input type="checkbox" :checked="renewable" @change="toggleRenewable" />
                <span class="checkbox__element"></span>
                <span class="checkbox__text">Автопродление тарифа</span>
            </label> -->
        <Button variant="primary" class="tariff-link" @click="pay">
            <span>{{ discountPercent > 0 && discountButtonText ? discountButtonText : 'Оплатить' }}</span>
            <!-- <ArrIcon class="tariff-link__icon" /> -->
        </Button>
    </div>
    <div v-if="paymentQuery.error.value?.message" class="tariff-message">{{ paymentQuery.error.value.message }}</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useQuery } from 'vue-query';
import ArrIcon from '../../svg/Arr.vue';
import ThumbUpSVG from '../../svg/thumb-up.vue';
import Button from '../Button/Button.vue';
import { axiosAPI } from '../../../../axios';
import { useRouter } from 'vue-router';
import { getProjectPropBy } from '../../../../utils/projects';
import { useProjectsStore } from '../../stores/projects';

const props = defineProps({
    id: Number,
    uuid: String,
    name: String,
    text: String,
    price: Number,
    duration: Number,
    code: String,
    discountPercent: {
        type: Number,
        default: 0,
    },
    popular: {
        type: Boolean,
    },
    popularCaption: {
        type: String,
    },
    discountButtonText: {
        type: String,
    },
    arrow: {
        type: Boolean,
        default: false,
    },
});

const map: Record<string, string> = {
    month: 'мес',
    year: 'год',
    test: '7 дней',
};

const router = useRouter();
const projectsStore = useProjectsStore();

const renewable = ref(false);

function toggleRenewable() {
    renewable.value = !renewable.value;
}

const paymentQuery = useQuery(
    ['payment', props.uuid],
    () =>
        axiosAPI.get(
            `/projects/${getProjectPropBy(
                'slug',
                projectsStore.projects,
                router.currentRoute.value.params.projectSlug,
                'id',
            )}/payment`,
            {
                params: {
                    uuid: props.uuid,
                    discount: props.discountPercent > 0 && (typeof props.price === 'number' ? props.price > 1 : true),
                    renewable: renewable.value ? 'on' : undefined,
                },
            },
        ),
    {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: false,
    },
);

async function pay() {
    try {
        const { data } = await paymentQuery.refetch.value();

        if (data.data.data.payment_link) {
          setTimeout(() => {
            window.open(data.data.data.payment_link, '_blank');
          }, 1);
        }
    } catch (err: any) {
        throw err;
    }
}
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.tariff {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr auto;
    grid-template-areas:
        'content checkbox'
        'price link';
    padding: 24px 16px;
    border-radius: 16px;
    background-color: var(--elements-bg);
    color: $body-color;
    position: relative;

    @media screen and (min-width: 1025px) {
        padding: 32px;
    }

    @media screen and (max-width: 767px) {
        grid-template-areas:
            'content content'
            'price link';
    }

    @media screen and (max-width: 420px) {
        grid-template-areas:
            'content content'
            'price price'
            'link link';
    }

    &.tariff--popular {
        border: 1px solid $primary;

        .tariff-title-container {
            @media screen and (max-width: 767px) {
                padding-right: 107px;
            }
        }
    }
}

.tariff-popular {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 27px;
    display: flex;
    align-items: flex-start;
    border-left: 1px solid $primary;
    border-bottom: 1px solid $primary;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.2;
    max-width: 230px;
    background-color: #fff;

    @media screen and (max-width: 767px) {
        max-width: 107px;
        padding: 9px;
        padding-left: 13px;
        border-bottom-left-radius: 16px;
        border-top-right-radius: 16px;
    }
}

.tariff-popular__icon {
    width: 17px;
    margin-right: 11px;
    flex-shrink: 0;

    @media screen and (max-width: 767px) {
        margin-right: 8px;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

.tariff-popular__content {
    flex: 1 1 auto;
}

.tariff-popular__title {
    color: $primary;
    font-size: 12px;

    @media screen and (max-width: 767px) {
        font-size: 9px;
    }
}

.tariff-popular__caption {
    margin-top: 3px;
    font-size: 9px;
    color: #5e5755;

    @media screen and (max-width: 767px) {
        font-size: 6px;
    }
}

.tariff-content {
    grid-area: content;
}

.tariff-title-container {
    margin-bottom: 22px;
}

.tariff-title {
    font-size: responsive 18px 32px;
    font-range: 1025px 1920px;
    font-weight: 700;
}

.tariff-secondary {
    margin-top: 8px;
    opacity: 0.5;
    font-size: responsive 12px 20px;
    font-range: 1025px 1920px;
}

.tariff-rate {
    display: flex;
    align-items: baseline;
    margin-top: 32px;
    grid-area: price;

    @media screen and (max-width: 480px) {
        flex-wrap: wrap;
    }
}

.tariff-price {
    color: $primary;
    font-size: responsive 24px 40px;
    font-range: 1025px 1920px;
}

.tariff-price-old {
    text-decoration: line-through;
    opacity: 0.8;
    color: #333;
    margin-right: 15px;
    font-size: 24px;

    @media screen and (max-width: 480px) {
        width: 100%;
        font-size: 20px;
    }

    @media screen and (max-width: 420px) {
        width: auto;
    }
}

.tariff-rate-sm {
    margin-left: 5px;
    opacity: 0.5;

    @media screen and (min-width: 1681px) {
        font-size: 18px;
    }
}

.tariff-checkbox {
    margin-bottom: 20px;
    font-size: 10px;
    padding-left: 35px;
    grid-area: checkbox;

    .checkbox__element {
        background-color: #fff;

        @media (min-width: 1025px) {
            top: -5px;
        }
    }
}

.tariff-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    grid-area: link;

    @media screen and (max-width: 420px) {
        margin-top: 10px;
    }
}

.tariff-link__icon {
    margin-left: 18px;
}

.tariff-message {
    margin-top: 20px;
    color: $red;

    &:empty {
        display: none;
    }
}
</style>
