<template>
    <div class="error-page">
        <h1 class="error-page-title">Ваш заказ успешно оплачен!</h1>
        <div class="error-page-bottom">
            <!-- <router-link
                :to="`/${router.currentRoute.value.params.projectSlug}/tasks`"
                class="btn btn-primary error-page-back-btn"
                >Перейти к задачам</router-link
            > -->
            <a
                v-if="shouldRenderButton"
                :href="`/${router.currentRoute.value.params.projectSlug}/tasks`"
                class="btn btn-primary error-page-back-btn"
                >Перейти к задачам</a
            >
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useQuery } from 'vue-query';
import { router, YM_ID } from '../router';
import { axiosAPI } from '../../../axios';
import { getProjectPropBy } from '../../../utils/projects';
import { useProjectsStore } from '../stores/projects';

const projectsStore = useProjectsStore();

const uniqId = router.currentRoute.value.query.uniq_id;
const tariffId = router.currentRoute.value.query.tariff_id;
const projectId = getProjectPropBy('slug', projectsStore.projects, router.currentRoute.value.params.projectSlug, 'id');

const tariffsPromise = tariffId ? axiosAPI.get(`/projects/${projectId}/tariffs/${tariffId}`) : null;
const shouldRenderButton = ref(false);

const paymentCompleteQuery = useQuery(
    ['payment-complete', uniqId, projectId],
    () => axiosAPI.get(`/projects/${projectId}/payment/return_url/${uniqId}`),
    {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        onSuccess: () => {
            if (tariffsPromise) {
                tariffsPromise.then((data) => {
                    shouldRenderButton.value = true;
    
                    if (data.data.data.tariff.metrica_code) {
                        (window as any).ym?.(YM_ID, 'reachGoal', data.data.data.tariff.metrica_code);
                    }
                });
            } else {
                shouldRenderButton.value = true;
            }
        },
    },
);
</script>
