import { router } from '../components/vue/router';

export function getUrlByActionType(type: string) {
    switch (type) {
        case 'register':
            return `/${router.currentRoute.value.params.projectSlug}/register-after-brief`;
        case 'text':
            return `/${router.currentRoute.value.params.projectSlug}/brief-complete`;
        case 'text_tariff_faq':
            return `/${router.currentRoute.value.params.projectSlug}/brief-complete-combined`;
        case 'tariff':
            return `/${router.currentRoute.value.params.projectSlug}/choose-tariff`;
        case 'tasks':
            return `/${router.currentRoute.value.params.projectSlug}/tasks`;
        default:
            throw new Error('No action found.');
    }
}
