<template>
    <div>
        <phone-sms-form
            title="Введите свой номер телефона для получения СМС кода"
            :endpoint="sendCodeEndpoint"
            :data="sendCodeData"
            @phone-sms-form-success="onPhoneSmsFormSuccess"
            @phone-sms-form-error="onPhoneSmsFormError"
            @phone-change="onPhoneChange"
            :send-sms="sendSms"
            @input-change="onInputChange"
            @redirect-to-login="() => $emit('redirect-to-login')"
            :input-disabled="isPhoneInputDisabled"
            :input-autofocus="inputAutofocus"
            :is-code-form-shown="codeForm.show"
            :next-action="nextAction"
        ></phone-sms-form>
        <code-validation-form
            v-if="true || (validateCode && codeForm.show)"
            title="В течение 5 минут на ваше устройство придет смс с кодом, введите его в форму ниже."
            :endpoint="validateCodeEndpoint"
            :data="validateCodeData"
            :send-code-endpoint="sendCodeEndpoint"
            :send-code-data="sendCodeData"
            :digits="digits"
            :phone="phone"
            :sms-timeout-sec="codeForm.smsTimeoutSec"
            :message="codeForm.message"
            :send-sms="sendSms"
            @code-validation-form-success="onCodeValidationSuccess"
            @code-validation-form-error="onCodeValidationError"
        ></code-validation-form>
    </div>
</template>

<script lang="ts">
import { onBeforeUnmount, reactive, ref, watch } from 'vue';
import PhoneSmsForm from '../PhoneSmsValidation/PhoneSmsForm.vue';
import CodeValidationForm from '../PhoneSmsValidation/CodeValidationForm.vue';
import { store } from '../../store';
import { axiosAPI } from '../../../../axios';
import { getCsrfCookie } from '../../stores/user';

export default {
    components: { PhoneSmsForm, CodeValidationForm },

    props: {
        digits: {
            type: Number,
            default: 4,
        },
        sendCodeEndpoint: {
            type: String,
            required: true,
        },
        phone: {
            type: String,
            required: true,
        },
        sendCodeData: {
            type: Object,
            default: () => ({}),
        },
        validateCode: {
            type: Boolean,
            default: true,
        },
        validateCodeEndpoint: {
            type: String,
        },
        validateCodeData: {
            type: Object,
            default: () => ({}),
        },
        inputAutofocus: {
            type: Boolean,
            default: false,
        },
        nextAction: {
            type: Object,
        },
    },

    setup(props, { emit }) {
        const isPhoneInputDisabled = ref(false);
        const codeForm = reactive({
            show: false,
            message: '',
            smsTimeoutSec: 0,
        });
        const timeoutInverval = ref();

        function stopCountdown() {
            clearInterval(timeoutInverval.value);
        }

        function startCountdown() {
            timeoutInverval.value = setInterval(() => {
                codeForm.smsTimeoutSec -= 1;

                if (codeForm.smsTimeoutSec === 0) {
                    stopCountdown();
                }
            }, 1000);
        }

        async function sendSms(endpoint: string, data = {}) {
            if (!store.state.token) {
                try {
                    await getCsrfCookie();
                } catch (err) {
                    // errorMessage.value = 'Произошла ошибка при запросе';
                    throw err;
                }
            }

            try {
                const response = await axiosAPI.post(endpoint, data);

                if (response.data.success) {
                    return response;
                } else {
                    throw new Error(response.data.message);
                }
            } catch (err) {
                throw err;
            }
        }

        function onPhoneSmsFormSuccess(data: any) {
            const { message, sms_timeout_sec } = data;

            if (message) {
                codeForm.message = message;
            }

            if (sms_timeout_sec) {
                codeForm.smsTimeoutSec = sms_timeout_sec;
                startCountdown();
            }

            codeForm.show = true;
            isPhoneInputDisabled.value = true;
            emit('sms-success', data);
        }

        function onPhoneSmsFormError(err: Error) {
            emit('sms-error', err);
            isPhoneInputDisabled.value = false;
        }

        function onPhoneChange() {
            codeForm.show = false;
            isPhoneInputDisabled.value = false;
        }

        function onInputChange(phoneNumber: string) {
            emit('phone-change', phoneNumber);
        }

        function onCodeValidationSuccess() {
            emit('success');
        }

        function onCodeValidationError() {
            emit('error');
        }

        onBeforeUnmount(() => {
            stopCountdown();
        });

        return {
            sendSms,
            codeForm,
            onPhoneSmsFormSuccess,
            onPhoneSmsFormError,
            onPhoneChange,
            isPhoneInputDisabled,
            onCodeValidationSuccess,
            onCodeValidationError,
            onInputChange,
            ...props,
        };
    },
};
</script>

<style lang="scss">
@import '../../../../../css/base/variables';
@import '../../../../../css/utils/mixins';

.phone-sms-validation-form__title {
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 12px;
    }

    &.phone-sms-validation-form__title--colored {
        color: $primary;
    }
}

.phone-sms-validation-tip-container {
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 16px;
    opacity: 0.5;
    display: flex;
}

.phone-sms-validation-tip {
    box-shadow: inset 0 -1px currentColor;
}

.phone-sms-validation-form__error {
    color: $danger;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
